import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { LoginLayoutComponent } from '../app/layouts/login-layout/login-layout.component';
import { ApplicationLayoutComponent } from '../app/layouts/application-layout/application-layout.component';
import { LoginCallbackPageComponent } from 'src/app/pages/login-callback/login-callback.page';
import { LogoutCallbackPageComponent } from 'src/app/pages/logout-callback/logout-callback.page';
import { DashboardPageComponent } from 'src/app/pages/dashboard/dashboard.page';
import { LogoutLayoutComponent } from '../app/layouts/logout-layout/logout-layout.component';
import { AuthGuard } from '../core/services/AuthGuard';
import { AccessDeniedPageComponent } from 'src/app/pages/access-denied/access-denied.page';
import { AddDatabaseComponent } from '@modules/Super-admin/component/add-database/add-database.component';
import { ViewBotComponent } from '@modules/Super-admin/component/view-bot/view-bot.component';
import { SuperAdminPageComponent } from 'src/app/pages/super-admin/super-admin.page';

const routing: Route[] = [
  {
    path: 'callback.html',
    component: LoginLayoutComponent,
    children: [
      { path: '', component: LoginCallbackPageComponent },
    ],
  },
  {
    path: 'signout-callback-oidc',
    component: LogoutLayoutComponent,
    children: [
      { path: '', component: LogoutCallbackPageComponent },
    ],
  },
  {
    path: 'signout',
    component: LogoutLayoutComponent,
    children: [
      { path: '', component: LogoutCallbackPageComponent },
    ],
  },
  {
    path: 'access-denied',
    component: AccessDeniedPageComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: ApplicationLayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: DashboardPageComponent },
      { path: 'home/exceptions/:type', component: DashboardPageComponent },
      { path: 'super-admin', component: SuperAdminPageComponent,
        children: [
          { path: '', redirectTo: 'add-database', pathMatch: 'full' },
          { path: 'add-database', component: AddDatabaseComponent, },
          { path: 'view-bot/:id', component: ViewBotComponent, },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routing,
    ),
  ],
  exports: [
    RouterModule,
  ],
})
export class RouterConfigurationModule { }
