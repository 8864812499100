import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from '@core/services/Session';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private session: SessionService) { }

  async canActivate(): Promise<boolean> {
    if (await this.session.hasSession()) {
      return true;
    }

    this.session.requireLogin();
    return false;

  }

}
