import { Injectable } from '@angular/core';
import * as momentTimeZones from 'moment-timezone';
import { countryList } from './countyList';
import { orderBy } from 'lodash';

@Injectable()
export class TimezoneService {

  public country = countryList;

  constructor() {}

  getTimezone(): ITimeZone[] {
    const timeZoneList: ITimeZone[] = [];
    const countryCode = momentTimeZones.tz.countries();
    countryCode.forEach(code => {
      const zones = momentTimeZones.tz.zonesForCountry(code);
      zones.forEach(zone => {
        const obj: ITimeZone = {
           id: zone,
           displayName: zones.length > 1 ? `${zone}${this.getOffset(zone)}` : `${this.country[code]}${this.getOffset(zone)}`,
           country: this.country[code],
           countryCode: code,
         };
         timeZoneList.push(obj);
       });
    });
    return orderBy(timeZoneList, 'country' , 'asc');
  }

  getOffset(zone: string) {
    return `(GMT${momentTimeZones(new Date()).tz(zone).format('Z')})`;
  }
}

export interface ITimeZone {
  id: string;
  displayName: string;
  country?: string;
  countryCode?: string;
}
