<div class="row py-4 d-flex justify-content-md-around justify-content-start">
    <div class="d-flex kpis">
        <div>
            <span class="d-block kpi-value"><strong [title]="botPerformanceCount">{{ botPerformanceCount }} %</strong></span>
            <span class="kpi-heading">Success Rate</span>
        </div>
        <div class="ml-3">
            <img class="img-responsive kpi-image" src="/assets/images/bot_performance.svg">
        </div>
    </div>
    <div class="divider d-sm-none"></div>
    <div class="d-flex kpis">
        <div>
            <span class="d-block kpi-value"><strong [title]="botInquiriesCount">{{ botInquiriesCount }}</strong></span>
            <span class="kpi-heading">Inquiries Processed</span>
        </div>
        <div class="ml-3">
            <img class="img-responsive kpi-image" src="/assets/images/inquries.svg">
        </div>
    </div>
    <div class="divider d-sm-none"></div>
    <div class="d-flex kpis">
        <div>
            <span class="d-block kpi-value"><strong [title]="(timeSavedData) ? timeSavedData.time : 00">{{ (timeSavedData) ? timeSavedData.time : 00 }} H</strong></span>
            <span class="kpi-heading">Manual Time Saved</span>
        </div>
        <div class="ml-3">
            <img class="img-responsive kpi-image" src="/assets/images/time_saved.svg">
        </div>
    </div>
    <div class="divider d-sm-none" *ngIf="hasUtilization()"></div>
    <div class="d-flex mt-md-4 mt-lg-0 kpis" *ngIf="hasUtilization()">
        <div>
            <span class="d-block kpi-value"><strong [title]="utilization.result">{{utilization.result}} %</strong></span>
            <span class="kpi-heading">RPA Utilization</span>
        </div>
        <div class="ml-3">
            <img class="img-responsive kpi-image" src="/assets/images/employee_utilization.svg">
        </div>
    </div>
</div>    