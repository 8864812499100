// tslint:disable-next-line:variable-name
export const Constants = {
  accessTokenAlias: 'simplifai.token',
};

export const DEFAULT_CUSTOMER_NAME = 'Select customer';

export const validationRegEx = {
  timeValidationRegEx: /^(?:[0-5]\d):(?:[0-5]\d)$/,
  numberValidationRegEx: /^([0-9]*)?$/,
  numberValidationExceptZeroRegEx: /^[1-9]\d*$/,
};

export const LOCAL_STORAGE_FILTER_KEY = 'selectedFilter';
export const LOCAL_STORAGE_DEFAULT_FILTER_KEY = 'selectedDefaultFilter';
export const LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER = 'exceptionCurrentPageNumber';

export const SETTING_BOT = 'settingBot';

export const MAX_PAGINATION_SIZE = 5;

export const MULTI_SELECT_DROPDOWN_SETTING = {
  singleSelection: false,
  enableFilterSelectAll: false,
  enableCheckAll: false,
  badgeShowLimit: 3,
};

export const DEFAULT_FILTER = 'Default Filter';
