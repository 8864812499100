<div class="w-100 mt-2" *ngFor="let filter of filters; let i = index">
    <div class="pt-1" *ngIf="filter.isActive && !employee.masterEventId && employee.botId">
        <angular2-multiselect
            *ngIf="filter.displayName === 'Default Filter' && values.length" 
            [data]="values"
            [(ngModel)]="selectedDefaultValue" 
            [settings]="defaultDropdownSettings"
            (onSelect)="onDefaultFilterValueDeSelect(selectedDefaultValue)" 
            (onDeSelect)="onDefaultFilterValueDeSelect(selectedDefaultValue)"
            (onDeSelectAll)="onDefaultFilterValueDeSelect($event)" 
            (mouseover)="getFilterTitle($event)" 
            [title]="filterTokenTitle">
        </angular2-multiselect>
        <sf-filter-list
            *ngIf="filter.displayName !== 'Default Filter' && filter.values.length" 
            [values]="filter.values"
            [selectedValue]="selectedFilter[i]"
            [placeholder]="filter.displayName"
            (selectChange)="onSelectChange($event, filter, i)"  
            (mouseover)="getFilterTitle($event)"
            [title]="filterTokenTitle">
        </sf-filter-list>
    </div>
</div>      