<div class="modal-footer d-flex-inline justify-content-start px-4">
    <h6 class="pt-1">Compare with previous period?</h6>
    <div class="pl-4">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="customRadio1" name="option" [value]="true" [(ngModel)]="option" (change)="setOption(option)">
            <label class="custom-control-label" for="customRadio1">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="customRadio2" name="option" [value]="false" [(ngModel)]="option" (change)="setOption(option)">
            <label class="custom-control-label" for="customRadio2">No</label>
        </div>
    </div>
</div>