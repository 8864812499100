import { Component } from '@angular/core';
import { ExceptionType } from '@modules/Dashboard/dashboard.model';
import { AbstractDashboardExceptionDirective } from '../abstract-dashboard-exception/abstract-dashboard-exception.directive';

@Component({
  selector: 'sf-exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.scss']
})

export class ExceptionComponent extends AbstractDashboardExceptionDirective {

  public exceptionType = ExceptionType;

}
