import { Component, Input, OnChanges } from '@angular/core';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { DateHelper } from '@core/utils/DateHelper';
import { IChartResponse, IChartModel, IRequestModel } from '@modules/Dashboard/dashboard.model';

@Component({
  selector: 'sf-inquiry-status-trends',
  templateUrl: './inquiry-status-trends.component.html',
  styleUrls: ['./inquiry-status-trends.component.scss']
})
export class InquiryStatusTrendsComponent implements OnChanges {

  @Input() inquiryStatusTrendData: IChartResponse;
  public chartData: IChartModel;
  public localValues: IRequestModel;

  constructor(private dataPersistenceService: DataPersistenceService) { }

  get hasOneDaySelected() {
    if (this.localValues) {
      const diff = DateHelper.daysBetween(new Date(this.localValues.toDate), new Date(this.localValues.fromDate));
      return !(diff === 1);
    }
  }

  async ngOnChanges() {
    this.localValues = await this.dataPersistenceService.get();
    if (this.inquiryStatusTrendData && this.inquiryStatusTrendData.data.length) {
      this.setChartValue();
    }
  }

  setChartValue() {
    this.chartData = {
      chart: this.inquiryStatusTrendData.data,
    };
  }
}
