<div class="pagination">
  <pagination
    [boundaryLinks]="true"
    [totalItems]="totalRecords"
    (pageChanged)="pageChanged($event)"
    [(ngModel)]="pagination.currentPage"
    [itemsPerPage]="pagination.pageSize"
    [maxSize]="maxSize"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
  ></pagination>
</div>
