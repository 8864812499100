import { Injectable } from '@angular/core';
import { Endpoints } from '@config/endpoints';
import { DataService } from '@core/services/DataService';
import { IServerResponse } from '@core/core.interfaces';
import { Identity } from '@core/model/Identity';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public identity = new BehaviorSubject<Identity>(null);

  constructor(private dataService: DataService) { }

  async loadPermissions(): Promise<Identity> {
    const response = await this.dataService.request<IServerResponse<Identity>>(Endpoints.GetPermissions);
    this.identity.next(response.result);
    return response.result;
  }

  hasPermission(role: string) {
    return this.identity.value.permissionReadDto[role];
  }
}
