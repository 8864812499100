import { DateRangeEnum } from '../dashboard.model';
import { DateHelper } from '@core/utils/DateHelper';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardStructureService {

  constructor(private dateHelper: DateHelper) {}

  setInitialValues() {
    const dates = DateHelper.daysInPeriod(new Date(), DateRangeEnum.Last7Days);
    return {
      fromDate: this.dateHelper.toServer(dates.from),
      toDate: this.dateHelper.toServer(dates.to),
      timeSpan: 7,
      botId: null,
      customerId: null,
      customerType: null,
      masterProcessId: null,
      masterEventId: null,
      botType: false,
      pageNumber: 0,
      filterValue1: null,
      filterValue2: null,
      filterValue3: null,
      FilterName1: null,
      FilterName2: null,
      FilterName3: null,
      filterValue: null,
      rowNumber: 5,
      displayName: null,
      labelName: null,
    };
  }

  resetBotState() {
    return {
      botId: null,
      botName: 'All Modules',
      displayName: 'All Modules',
      botType: false,
      customerId: null,
      customerType: null,
      masterProcessId: null,
      masterEventId: null,
      masterJobId: null,
      botProcess: null,
      kpiValue: null,
      manualTimeSpan: null
    };
  }
}
