import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IPagination } from '@core/core.interfaces';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER } from '@config/constants';
import { IAllExceptionResponse } from '@modules/Dashboard/dashboard.model';

@Component({
  selector: 'sf-all-exceptions',
  templateUrl: './all-exceptions.component.html',
  styleUrls: ['./all-exceptions.component.scss']
})
export class AllExceptionsComponent {

  @Input() exceptionData: IAllExceptionResponse;
  @Input() pagination: IPagination;
  @Output() paginationChange = new EventEmitter<number>();

  constructor(private dataPersistenceService: DataPersistenceService) {}

  paginate(pagination: IPagination) {
    this.dataPersistenceService.set(pagination.currentPage, LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER);
    this.paginationChange.emit(pagination.currentPage);
  }

  getFirstRecordIndexInPage() {
    return this.pagination.currentPage * this.pagination.pageSize - this.pagination.pageSize + 1;
  }

  getLastRecordIndexInPage() {
    if (this.exceptionData.exceptions.length < this.pagination.pageSize) {
      return this.exceptionData.totalRecords;
    } else {
      return this.pagination.currentPage * this.pagination.pageSize;
    }
  }
}
