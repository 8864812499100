<div class="header">
    <div class="container-fluid">
        <div class="row bot-header p-2">

            <div class="col-12 logo col-sm-2 text-center text-lg-left p-2 d-flex align-items-center">
                <a routerLink="/">
                    <img src="/assets/images/logo.png" class="w-75 img-fluid" title="Simplifai Dashboard">
                </a>
            </div>

            <div class="col-sm-10 row p-0 justify-content-end align-items-center mx-auto">
                <div class="col-2 col-sm-1 text-center mx-lg-0">
                    <div class="btn-group" dropdown>
                        <a id="user" dropdownToggle aria-controls="select-de" class="dropdown-toggle">
                            <img src="/assets/images/adminUser.png" class="w-75"> <span class="caret"></span>
                        </a>
                        <ul id="user" *dropdownMenu class="dropdown-menu p-1" role="menu" aria-labelledby="user">
                            <li role="menuitem" class="user-name ml-1" title="{{ currentUser.profile.name }}">{{ currentUser.profile.name }}</li>
                            <li role="menuitem" (click)="logout()"><a class="dropdown-item logout-label text-left">Logout </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container-fluid">
    <div class="mb-5">
        <h6 class="admin-heading mt-2 ml-4">Super Admin</h6>
        <p class="bottom-line ml-4">DE dashboard</p>
    </div>
</div>