import { HttpParams } from '@angular/common/http';
import { find, reduce } from 'lodash';
import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG } from '@config/di-tokens';
import { IUrlBuilder, IKeyValue } from '@core/core.interfaces';
import { ILink } from '@core/core.interfaces';
import { StringHelper } from '@core/utils/StringHelper';

@Injectable()
export class UrlBuilder implements IUrlBuilder {

  public baseUrl: string;
  private links: ILink[];

  constructor(@Inject(APP_CONFIG) config: ApplicationConfig) {
    this.baseUrl = config.apiBaseUrl;
  }

  build(params: string|string[]) {
    let path;
    if (params instanceof Array) {
      path = params.join('/');
    } else {
      path = params;
    }

    path = this.normalizePath(path);

    return this.baseUrl + path;
  }

  getEndpoint(key: string, links: ILink[]): ILink {
    const result = find(links, (link: ILink) => link.rel === key);
    return {
      ...result,
      query: this.getQueryParams(result.href),
      href: this.build(result.href),
    };
  }

  findEndpoint(key: string): ILink {
    return this.getEndpoint(key, this.links);
  }

  setEndpoints(links: ILink[]): void {
    this.links = [ ...links ];
  }

  private getQueryParams(url: string): IKeyValue {
    const index = url.indexOf('?');
    if (index < 0) {
      return {};
    }

    const fromString = url.slice(index + 1);
    const params = new HttpParams({
      fromString,
    });
    return reduce(params.keys(), (result: IKeyValue, key: string): IKeyValue => {
      const currentKey = StringHelper.toCamelCase(key);
      result[currentKey] = params.get(key);
      return result;
    }, {});
  }

  private normalizePath(path: string): string {
    const separatorIndex = path.indexOf('?');
    if (separatorIndex < 0) {
      return path;
    } else {
      return path.slice(0, separatorIndex);
    }
  }

}
