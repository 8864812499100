<div class="modal-header">
    <h6 class="text-info">Info</h6>
</div>
<div class="modal-body" *ngIf="data">
    <h5 class="modal-title">BOT configuration is pending. Please {{data.hasSuperAdminRole ? 'complete the client set-up.' : 'contact your Administrator.'}}</h5>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col text-right m-2">
            <button type="button " class="btn save-btn" (click)="hide()">Ok</button>
        </div>
    </div>
</div>