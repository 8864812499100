import { IRequestModel, IBotProcessEvent, IBotProcess, IBot } from '../dashboard.model';

export class DashboardMapper {

  serializeBots(bots: IBotProcessEvent[], hideProcess: boolean = false, hideEvent: boolean = false) {
    const data: any = [];
    bots.forEach(bot => {
      if (bot.botId) {
        data.push({
          botId: bot.botId,
          botName: bot.botName,
          displayName: bot.botName,
          customerId: bot.customerId,
          filters: bot.filters,
          botType: bot.botType,
          masterJobId: bot.masterJobId,
          botProcess: this.serializeProcess(bot),
          children: hideProcess ? [] : this.serializeProcess(bot, hideEvent),
          labelName: bot.botName,
          masterProcessId: bot.masterProcessId,
          masterEventId: bot.masterEventId,
          manualTimeSpan: bot.manualTimeSpan,
          links: bot.links,
        });
      }
    });
    return data;
  }

  serializeProcess(bot: IBotProcessEvent, hideEvent: boolean = false ) {
    const data: any = [];
    bot.botProcess.forEach(botProcess => {
      data.push({
        botId: bot.botId,
        masterProcessId: botProcess.masterProcessId,
        displayName: botProcess.processName,
        botEvent: this.serializeEvent(botProcess, bot),
        children: hideEvent ? [] : this.serializeEvent(botProcess, bot),
        processName: botProcess.processName,
        labelName: `${bot.botName} > ${botProcess.processName}`,
        links: bot.links,
      });
    });
    return data;
  }

  serializeEvent(botProcess: IBotProcess, bot: IBot) {
    const data: any = [];
    botProcess.botEvent.forEach(botEvent => {
      data.push({
        botId: bot.botId,
        masterProcessId: botProcess.masterProcessId,
        eventName: botEvent.eventName,
        masterEventId: botEvent.masterEventId,
        displayName: botEvent.eventName,
        labelName: `${bot.botName} > ${botProcess.processName} > ${botEvent.eventName}`,
      });
    });
    return data;
  }

    serializeData(model: IRequestModel) {
      return {
        botId: model.botId ? model.botId : '',
        fromDate: model.fromDate,
        toDate: model.toDate,
        filterValue1: model.filterValue1 ? model.filterValue1 : '',
        filterValue2: model.filterValue2 ? model.filterValue2 : '',
        filterValue3: model.filterValue3 ? model.filterValue3 : '',
        filterValue: model.filterValue ? model.filterValue : '',
        masterProcessId: (model.masterProcessId) ? model.masterProcessId : '',
        masterEventId: (model.masterEventId) ? model.masterEventId : '',
      };
    }

    serializeUtilizationParams(model: IRequestModel) {
      return {
        botId : model.botId || null,
        fromDate: model.fromDate,
        toDate: model.toDate,
        filterValue1: null,
        filterValue2: null,
        filterValue3: null,
        filterValue: null,
        masterProcessId: null,
        masterEventId: null,
      };
    }
}
