import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ITimeResponse, IDoughnutChartResponse, IUtilization, IRequestModel, IFilter } from '@modules/Dashboard/dashboard.model';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { SolutionType } from '@modules/Super-admin/super-admin.model';

@Component({
  selector: 'sf-key-performance-indicator',
  templateUrl: './key-performance-indicator.component.html',
  styleUrls: ['./key-performance-indicator.component.scss']
})
export class KeyPerformanceIndicatorComponent implements OnChanges {

  @Input() inquiryStatusData: IDoughnutChartResponse[];
  @Input() timeSavedData: ITimeResponse;
  @Input() utilization: IUtilization;
  @Input() filters: IFilter[];
  public botPerformanceCount = 0;
  public botInquiriesCount = 0;
  public localValues: IRequestModel;

  constructor(private dataPersistenceService: DataPersistenceService) {}

  hasUtilization() {
    return this.utilization && (this.localValues && this.hasCustomerRpa());
  }

  hasCustomerRpa() {
    return (this.localValues.customerType === SolutionType.RPA || this.localValues.customerType === SolutionType.RPAOrNonRPA);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.localValues = this.dataPersistenceService.get();
    if (changes.inquiryStatusData && changes.inquiryStatusData.currentValue !== changes.inquiryStatusData.previousValue) {
      this.botPerformanceCount = this.getBotPerformanceTotal();
      this.botInquiriesCount = this.getBotInquiriesTotal();
    }
  }

  getBotPerformanceTotal() {
    return this.inquiryStatusData.reduce((total: number, model: IDoughnutChartResponse) => {
      if (model.status !== 'System Failure' && model.status !== 'Exception') {
        total += model.result;
      }
    return Number(total.toFixed(2));
    }, 0);
  }

  getBotInquiriesTotal() {
    return this.inquiryStatusData.reduce((total: number, model: IDoughnutChartResponse) => {
      return (total + model.resultCount);
    }, 0);
  }

}
