import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IModalData, SolutionType } from '@modules/Super-admin/super-admin.model';
import { SuperAdminService } from '@modules/Super-admin/Services/SuperAdminService';
import { DEFAULT_CUSTOMER_NAME, validationRegEx } from '@config/constants';
import { Subject } from 'rxjs';
import { ICustomers } from '@modules/Dashboard/dashboard.model';
import { cloneDeep, isEqual, some } from 'lodash';
import { ConfirmModalComponent } from 'src/components/confirm-modal/confirm-modal.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ITimeZone, TimezoneService } from '@modules/Super-admin/Services/TimezoneService';

@Component({
  selector: 'sf-modify-modal',
  templateUrl: './modify-modal.component.html',
  styleUrls: ['./modify-modal.component.scss']
})

export class ModifyModalComponent implements OnInit {
  public data: IModalData;
  public loadingFlag = false;
  public attachedCustomer: ICustomers[];
  public selectedCustomer: string;
  public DEFAULT_CUSTOMER_NAME = DEFAULT_CUSTOMER_NAME;
  public databaseName = '';
  public customerSnapshot: ICustomers;
  public customerValue: ICustomers;
  public isActive = false;
  public modalConfig = {
    class: 'modal-dialog-centered modal-sm',
    keyboard: false,
    ignoreBackdropClick: true,
  };
  public confirmModalRef: BsModalRef;
  public onClose = new Subject<boolean>();
  public numberPattern = validationRegEx.numberValidationExceptZeroRegEx;
  public solutionType = SolutionType;
  public timeZones: ITimeZone[] = [];
  public solutionTypes = [
    { label: 'RPA', value: SolutionType.RPA },
    { label: 'NON-RPA', value: SolutionType.NonRPA },
    { label: 'RPA and NON-RPA', value: SolutionType.RPAOrNonRPA },
  ];

  @ViewChild('timezone', { static: false }) timezoneInput: ElementRef;

  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private service: SuperAdminService,
    private timezoneService: TimezoneService,
  ) { }

  get hasRpaCustomer() {
    return this.customerValue.customerType === this.solutionType.RPA || this.customerValue.customerType === this.solutionType.RPAOrNonRPA;
  }

  async ngOnInit() {
    this.timeZones = this.timezoneService.getTimezone();
    this.selectedCustomer = DEFAULT_CUSTOMER_NAME;
    this.attachedCustomer = this.data.attachCustomers;
    this.customerValue = this.setCustomerValue(this.data?.customerDetail);
    this.customerSnapshot = cloneDeep(this.customerValue);
  }

  selectCustomer(attachedCustomer: ICustomers) {
    this.selectedCustomer = attachedCustomer.customerName;
    this.customerValue = this.setCustomerValue(attachedCustomer);
  }

  setCustomerValue(data: ICustomers) {
    return {
      id: data?.id || null,
      customerName: data?.customerName || '',
      databaseName: data?.databaseName || '',
      customerType: data?.customerType ?? SolutionType.NonRPA,
      isManualInquiryAtJobLevel: data?.isManualInquiryAtJobLevel || false,
      rpaAllocatedHours: data?.rpaAllocatedHours ?? 0,
      rpaConnectionString: data?.rpaConnectionString || null,
      rpaDatabaseName: data?.rpaDatabaseName || null,
      timezoneId: data?.timezoneId || null,
      timezoneDisplayName: data?.timezoneDisplayName || null,
      isActive: data?.isActive || false,
    };
  }

  onConfirmation() {
    this.confirmModalRef = this.modalService.show(ConfirmModalComponent, { ...this.modalConfig, id: 2 });
    this.confirmModalRef.content.data = { title: 'Are you sure you want to save this?' };
    this.confirmModalRef.content.onConfirm.subscribe(async (result: boolean) => {
      if (result) {
        await this.saveCustomer();
        this.confirmModalRef.hide();
      }
    });
  }

  onOptionChange(value: MatCheckboxChange) {
    this.customerValue.isActive = !value.checked;
  }

  onTimezoneSelect(e: TypeaheadMatch) {
    this.customerValue.timezoneId = e.item.id;
    this.customerValue.timezoneDisplayName = e.value;
  }

  onTimezoneReset() {
    this.customerValue.timezoneId = this.customerValue.timezoneDisplayName = null;
    this.timezoneInput.nativeElement.focus();
  }

  async saveCustomer() {
    try {
      this.loadingFlag = true;
      await this.service.update(this.customerValue);
      this.onClose.next(true);
      this.hide();
    } catch (err) { }
    finally {
      this.loadingFlag = false;
    }
  }

  isValidTimeZone() {
    if (this.timeZones) {
      return some(this.timeZones, (timezone: ITimeZone) => timezone.displayName === this.customerValue.timezoneDisplayName);
    }
  }

  isNameEmpty() {
    return this.customerValue.customerName && this.customerValue.databaseName &&
      this.customerValue.timezoneDisplayName && this.customerValue.timezoneId &&
      !(this.customerValue.rpaAllocatedHours === null);
  }

  isDetailsChanged() {
    return (isEqual(this.customerValue, this.customerSnapshot) || !this.isNameEmpty());
  }

  hide() {
    this.modalRef.hide();
  }
}
