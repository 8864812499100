<sf-loading [loadingFlag]="loadingFlag"></sf-loading>
<tabset>
    <tab heading="Active customers" customClass="customClass" id="activeCustomers">
        <div class="container-fluid table-action">
            <div class="row ml-4 mb-2 pt-2">
                <span [tooltip]="!hasCustomers ? 'No Customers Available' : ''">
                    <button class="link-btn btn" [disabled]="!hasCustomers" (click)="addModal()">Add customer database</button>
                </span>
            </div>
            <div class="container-fluid">
                <div class="admin-table table-responsive px-lg-2 mb-4" *ngIf="customers">
                    <table class="table text-center mb-4">

                        <thead>
                            <tr>
                                <th class="text-left">Customer Name</th>
                                <!-- <th class="text-left">Connection String</th> -->
                                <th class="text-center">Database Name</th>
                                <!-- <th class="text-center">Is Active</th> -->
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let data of customers; let i = index ">
                                <td class="text-left">{{data.customerName}}</td>
                                <!-- <td class="text-left">{{data.connectionString}}</td> -->
                                <td class="text-center">{{data.databaseName}}</td>
                                <!-- <td class="text-center">
                                    <input type="checkbox" class="customer-checkbox" [disabled]="!isDisabled" [(ngModel)]="data.isActive">
                                </td> -->
                                <td class="text-center">
                                    <div class="btn-group text-center">
                                        <button type="button" class="btn action-btn m-2" (click)="addModal(data, true)">Modify</button>
                                        <button type="button" class="btn action-btn m-2" [disabled]="customers.length === 1" (click)="removeModal(data,true)">Remove</button>
                                        <button type="button " class="btn action-btn m-2 " [disabled]="!data.isActive" (click)="viewBot(data)">View Bot</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </tab>
    <tab heading="Deactivated customers" customClass="customClass" id="deactiveCustomers">
        <div class="container-fluid table-action">
            <div class="admin-table table-responsive px-lg-2 mb-4" *ngIf="deletedCustomers">
                <table class="table text-center mb-4">

                    <thead>
                        <tr>
                            <th class="text-left">Customer Name</th>
                            <!-- <th class="text-left">Connection String</th> -->
                            <th class="text-left">Database Name</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let data of deletedCustomers; let i = index ">
                            <td class="text-left">{{data.customerName}}</td>
                            <!-- <td class="text-left">{{data.connectionString}}</td> -->
                            <td class="text-left">{{data.databaseName}}</td>
                            <td class="text-center">
                                <div class="btn-group text-center">
                                    <button type="button" class="btn action-btn m-2" (click)="restoreCustomer(data)">Restore</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </tab>
</tabset>