<div class="d-flex justify-content-between align-items-center">
    <h6 class="heading">Inquiry Status Trends</h6>
</div>

<ng-container *ngIf="inquiryStatusTrendData; else noRecords">
    <sf-line-chart *ngIf="chartData" [chartData]="chartData"></sf-line-chart>
    <div class="text-right mr-3" *ngIf="hasOneDaySelected">
        <h6 class="date-info"><strong>{{inquiryStatusTrendData.chartShowDate}}</strong></h6>
    </div>
</ng-container>

<ng-template #noRecords>
    <h6 class="heading text-danger h-75 d-flex align-items-center justify-content-center">No Data To Show</h6>
</ng-template>