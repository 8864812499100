import { Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

export interface IDropdownData {
  displayName: string;
  children?: IDropdownData[];
}

@Component({
  selector: 'sf-dropdown',
  templateUrl: 'dropdown-menu.component.html',
  styleUrls: ['dropdown-menu.component.scss'],
})


export class DropdownMenuComponent implements OnChanges {
  @Input() data: IDropdownData[];
  @Input() label = 'Select value';
  public newData: IDropdownData[];
  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;

  @Output() click = new EventEmitter();


  ngOnChanges() {
    this.newData = [{
      displayName: this.label,
      children: this.data,
    }];
  }

  onClick(item: IDropdownData) {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
    this.click.emit(item);
  }

}
