import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewBotComponent } from './component/view-bot/view-bot.component';
import { AddDatabaseComponent } from './component/add-database/add-database.component';
import { CoreModule } from '@core/core.module';
import { ComponentsModule } from 'src/components/components.module';
import { SuperAdminService } from './Services/SuperAdminService';
import { AdminHeaderComponent } from './component/admin-header/admin-header.component';
import { ModifyModalComponent } from './component/modify-modal/modify-modal.component';
import { AddModalComponent } from './component/add-modal/add-modal.component';
import { RemoveModalComponent } from './component/remove-modal/remove-modal.component';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { FilterModalComponent } from './component/filter-modal/filter-modal.component';
import { TokenModalComponent } from './component/token-modal/token-modal.component';
import { TimezoneService } from './Services/TimezoneService';

@NgModule({
  imports: [
    CoreModule,
    ComponentsModule,
    CommonModule,
  ],
  declarations: [
    AddDatabaseComponent,
    ViewBotComponent,
    AdminHeaderComponent,
    ModifyModalComponent,
    AddModalComponent,
    RemoveModalComponent,
    FilterModalComponent,
    TokenModalComponent,
  ],
  exports: [
    AddDatabaseComponent,
    ViewBotComponent,
    AdminHeaderComponent,
    ModifyModalComponent,
    AddModalComponent,
    RemoveModalComponent,
    FilterModalComponent,
    TokenModalComponent,
  ],
  providers: [
    SuperAdminService,
    DashboardService,
    TimezoneService,
  ],
  entryComponents: [
    ModifyModalComponent,
    AddModalComponent,
    RemoveModalComponent,
    FilterModalComponent,
    TokenModalComponent,
 ]
})
export class SuperAdminModule { }
