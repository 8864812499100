import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_CONFIG } from '@config/di-tokens';
import { config } from '@environments/environment';
import { UrlBuilder } from '@core/services/UrlBuilder';
import { DataService } from '@core/services/DataService';
import { SessionService } from '@core/services/Session';
import { AuthService } from '@core/services/AuthService';
import { AuthGuard } from '@core/services/AuthGuard';
import { DataPersistenceService } from './services/DataPersistenceService';
import { DateHelper } from './utils/DateHelper';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: APP_CONFIG, useValue: config },
    UrlBuilder,
    DataService,
    SessionService,
    AuthService,
    AuthGuard,
    DataPersistenceService,
    DateHelper,
    DatePipe,
  ],
  exports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CoreModule {}



