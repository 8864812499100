import { Injectable } from '@angular/core';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import { validationRegEx } from '@config/constants';
import { FormHelper } from '@core/services/FormHelper';
import { map } from 'lodash';
import { EmailAlertValueType, ICustomers, IEmailAlert } from '../dashboard.model';

@Injectable()
export class DashboardFormBuilder extends FormHelper<IEmailAlert[]> {

    public defaultNumberValidators = [Validators.required, Validators.min(0), Validators.pattern(validationRegEx.numberValidationRegEx)];

    createEmpty(): FormGroup {
        return this.fromModel({
            emailAlerts: [],
        });
    }

    fromModel(model: IEmailAlert[] | {emailAlerts: IEmailAlert[]}): FormGroup {
        return this.builder.group({
            emailAlerts: this.builder.array(
                map(model, (alert: IEmailAlert) => this.createAlerts(alert)),
            )
        });
    }

    createAlerts(alert: IEmailAlert) {
        return this.builder.group({
            id: alert.id || '',
            emailAlertLevel: [alert.emailAlertLevel || '', [Validators.required]],
            botId: [alert.botId || null, [Validators.required]],
            masterProcessId: [alert.masterProcessId || null],
            status: [(alert.status !== undefined) ? alert.status : '', [Validators.required]],
            valueType: [(alert.valueType !== undefined) ? alert.valueType : EmailAlertValueType.Percentage, [Validators.required]],
            value: [alert.value || 0, [
                ...this.defaultNumberValidators,
                this.setValidators(alert.valueType)
            ]],
            condition: [(alert.condition !== undefined) ? alert.condition : '', [Validators.required]],
            schedule: [(alert.schedule !== undefined) ? alert.schedule : '', [Validators.required]],
            emailAddress: [alert.emailAddress || []],
        });
    }

    setValidators(valueType: number) {
        return (control: AbstractControl) => {
            if (valueType === EmailAlertValueType.Percentage) {
                control.setValidators([
                    ...this.defaultNumberValidators,
                    Validators.max(100),
                ]);
            }
        };
    }

    setEmailValidators(customer: ICustomers) {
        if (customer.customerName.toLowerCase().includes('ClaimsLink'.toLowerCase()) || customer.customerName.toLowerCase().includes('Claims Link'.toLowerCase())) {
            return Validators.pattern('^([a-zA-Z0-9. _-]+@(claimslink\.no|simplifai\.ai),?)+?$');
        } else if (customer.customerName.toLowerCase().includes('OSM'.toLowerCase())) {
            return Validators.pattern('^([a-zA-Z0-9. _-]+@(osm\.no|simplifai\.ai),?)+?$');
        } else if (customer.customerName.toLowerCase().includes('Fretex'.toLowerCase())) {
            return Validators.pattern('^([a-zA-Z0-9. _-]+@(fretex\.no|simplifai\.ai),?)+?$');
        } else if (customer.customerName.toLowerCase().includes('Onsagers'.toLowerCase())) {
            return Validators.pattern('^([a-zA-Z0-9. _-]+@(onsagers\.no|simplifai\.ai),?)+?$');
        } else {
            return Validators.pattern('^([a-zA-Z0-9. _-]+@(simplifai\.ai),?)+?$');
        }
    }

}
