import { Injectable } from '@angular/core';
import { size, isObject, isArray, forEach } from 'lodash';
import { IKeyValue } from '@core/core.interfaces';
import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class DataPersistenceService {

    localStorageConfigKey = 'sf-de';

    getByKey(key: string) {
        return JSON.parse(window.localStorage.getItem(key));
    }

    get() {
        const value = window.localStorage.getItem(this.localStorageConfigKey);
        if (!value) {
            return null;
        }

        try {
            const data = JSON.parse(value);
            if (isEmptyObject(data)) {
                return null;
            } else {
                return deserialize(data);
            }
        } catch (err) {
            return null;
        }
    }

    set(data: any, key?: string) {
        key = key ? key : this.localStorageConfigKey;
        const value = JSON.stringify(data);
        window.localStorage.setItem(key, value);
    }

    clear(key?: string) {
        key = key ? key : this.localStorageConfigKey;
        window.localStorage.removeItem(key);
    }

}

function isEmptyObject(data: unknown) {
    if (typeof data === 'object') {
        return size(data) === 0;
    } else {
        return false;
    }
}

function deserialize(data) {
    if (isObject(data) && !isArray(data)) {
        const object = data as IKeyValue<number | string | boolean | Date>;
        forEach(object, (value, key) => {
            if (value === 'true' || value === 'false') {
                object[key] = JSON.parse(value) as boolean;
            } else if (key.toLowerCase().indexOf('date') >= 0) {
                let result = value;
                if (typeof value === 'number' || typeof value === 'string') {
                    const date = formatDate(new Date(value), 'yyyy-MM-dd', 'en');
                    result = date;
                }
                object[key] = result;
            }
        });
    }

    return data;
}
