import { Component, } from '@angular/core';
import { ExceptionType } from '@modules/Dashboard/dashboard.model';
import { AbstractDashboardExceptionDirective } from '../abstract-dashboard-exception/abstract-dashboard-exception.directive';

@Component({
  selector: 'sf-system-failure',
  templateUrl: './system-failure.component.html',
  styleUrls: ['./system-failure.component.scss']
})

export class SystemFailureComponent extends AbstractDashboardExceptionDirective {

  public exceptionType = ExceptionType;

}
