<sf-loading [loadingFlag]="loadingFlag"></sf-loading>
<div class="modal-header" *ngIf="data">
    <h6 class="modal-title">{{data.title}}
        <p class="sub-title text-center" *ngIf="!data.isBot">(All related component will also be deleted.)</p>
    </h6>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col text-right m-2" *ngIf="data">
            <button type="button" class="btn save-btn mr-2" *ngIf="data.isBot" (click)="deleteFilter()">Yes</button>
            <button type="button" class="btn save-btn mr-2" *ngIf="!data.isBot" (click)="deleteBot()">Yes</button>
            <button type="button " class="btn disable-btn" (click)="hide()">No</button>
        </div>
    </div>
</div>