<!-- For Filter -->
<div class="row" *ngIf="!isString(tokens)">
    <div class="row token" *ngFor="let token of tokens;let i = index" [class.disabled]="isDefaultFilter(token)">
        <div class="token-button" (click)="onTokenClick(token)">
            {{ token.displayName }}
        </div>
        <i class="fa fa-close token-remove" (click)="onTokenRemove(token)"></i>
    </div>
</div>

<!-- For Bot -->
<div *ngIf="isString(tokens)">
    <div class="token">
        <div class="token-button" (click)="onTokenClick({botName: tokens})">
            {{ tokens }}
        </div>
        <i class="fa fa-close token-remove" (click)="onTokenRemove(tokens)"></i>
    </div>
</div>