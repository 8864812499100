import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sf-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent implements OnInit {

  @Output() radioBtnClicked = new EventEmitter<boolean>();
  public option = false;
  constructor() { }

  ngOnInit() {
  }

  setOption(value: boolean): void {
    this.radioBtnClicked.emit(value);
  }
}
