<div class="d-flex justify-content-between align-items-center">
    <h6 class="heading">Inquiry Status Overview</h6>
    <sf-date-filter *ngIf="inquiryStatusOverviewData" [showSecondaryDateFilter]="showSecondaryDateFilter" (datesChange)="onSecondaryDateChange($event)"></sf-date-filter>
</div>

<ng-container *ngIf="!loadingFlag; else loader">
    <ng-container *ngIf="inquiryStatusOverviewData; else noRecords">
        <sf-bar-chart *ngIf="chartData" [chartData]="chartData"></sf-bar-chart>
        <div class="text-right mr-3" *ngIf="hasOneDaySelected">
            <h6 class="date-info"><strong>{{inquiryStatusOverviewData.chartShowDate}}</strong></h6>
        </div>
    </ng-container>
    <ng-template #noRecords>
        <h6 class="heading text-danger h-75 d-flex align-items-center justify-content-center">No Data To Show</h6>
    </ng-template>
</ng-container>

<ng-template #loader>
    <sf-loading [loadingFlag]="loadingFlag"></sf-loading>
</ng-template>