import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SuperAdminService } from '@modules/Super-admin/Services/SuperAdminService';
import { IModalData } from '@modules/Super-admin/super-admin.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'sf-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss']
})
export class RemoveModalComponent implements OnInit {

  public data: IModalData;
  public onClose = new Subject<boolean>();
  public loadingFlag = false;

  constructor(public modalRef: BsModalRef, private service: SuperAdminService ) { }

  ngOnInit() {}

  async deleteCustomer() {
    try {
      this.loadingFlag = true;
      await this.service.deleteCustomer(this.data.customerDetail);
      this.onClose.next(true);
      this.hide();
    } catch (err) {

    } finally {
      this.loadingFlag = false;
    }
  }

  hide() {
    this.modalRef.hide();
  }
}
