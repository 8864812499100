import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import {
    IFilter,
    IBotProcessEvent,
    IRequestModel,
    PermissionEnum,
    ICustomers,
    IDateFilter,
} from '@modules/Dashboard/dashboard.model';
import { LOCAL_STORAGE_FILTER_KEY } from '@config/constants';
import { map, find, isEqual } from 'lodash';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { PermissionService } from '@core/services/PermissionService';
import { DateHelper } from '@core/utils/DateHelper';
import { DashboardFilterFactory } from '@modules/Dashboard/Services/DashboardFilterFactory';
import { AcknowledgeModalComponent } from '../acknowledge-modal/acknowledge-modal.component';
import { DashboardStructureService } from '@modules/Dashboard/Services/DashboardStructureService';
import { EmployeeSelectionModalComponent } from '../employee-selection-modal/employee-selection-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'sf-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss']
})

export class SubHeaderComponent implements OnChanges, AfterViewChecked {

    @Input() bots: IBotProcessEvent[];
    @Input() filters: IFilter[] = [];
    @Input() selectedDate: Date[];

    @Output() filtersChange = new EventEmitter<IFilter[]>();
    @Output() emitLoadingState = new EventEmitter<boolean>();

    public customers: ICustomers[];
    public selectedCustomer: ICustomers;
    public modalRef: BsModalRef;
    public permission = PermissionEnum;
    public currentBot: IBotProcessEvent;
    public cacheValues: IRequestModel;
    public selectedFilter = [];
    public breadCrumbValue: string | Array<string>;

    constructor(
        private service: DashboardService,
        private permissionService: PermissionService,
        private dataPersistenceService: DataPersistenceService,
        private modalService: BsModalService,
        private dateHelper: DateHelper,
        private filterFactory: DashboardFilterFactory,
        private structureService: DashboardStructureService,
        private changeDetection: ChangeDetectorRef,
    ) { }

    breadCrumb() {
        this.cacheValues = this.dataPersistenceService.get();
        if (this.cacheValues && this.cacheValues.labelName) {
            if (this.cacheValues.labelName.indexOf('>') > -1) {
                this.breadCrumbValue = this.cacheValues.labelName.split('>');
                return true;
            } else {
                this.breadCrumbValue = (this.cacheValues) ? this.cacheValues.labelName : '';
                return false;
            }
        } else {
            this.breadCrumbValue = '';
            return false;
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        this.cacheValues = this.dataPersistenceService.get();
        if (changes.bots && changes.bots.currentValue !== changes.bots.previousValue) {
            this.currentBot = this.structureService.resetBotState();
            if (this.bots && this.bots.length) {
                if (this.bots.length > 1) {
                    this.bots.unshift(this.structureService.resetBotState());
                }
                this.dataPersistenceService.set(this.cacheValues);
                this.getBotName();
            } else {
                this.showAcknowledgeModal();
            }
        }
    }

    async setCacheValues(loggedInCustomerId: string) {
        const cacheValues = await this.dataPersistenceService.get();
        if (cacheValues) {
            return (cacheValues.customerId === loggedInCustomerId ||
            this.checkPermission(this.permission.SuperAdminView)) ? cacheValues : this.structureService.setInitialValues();
        } else {
            return this.structureService.setInitialValues();
        }
    }

    showAcknowledgeModal() {
        this.modalRef = this.modalService.show(AcknowledgeModalComponent,
            {
                class: 'my-modal modal-dialog-centered modal-md',
                keyboard: false, ignoreBackdropClick: true,
            });
        this.modalRef.content.data = { hasSuperAdminRole: this.checkPermission(this.permission.SuperAdminView) };
    }

    emitFilterChanges() {
        this.filtersChange.emit(this.filters);
    }

    async getBotName(botList?: IBotProcessEvent[]) {
        this.cacheValues = this.dataPersistenceService.get();
        try {
            this.emitLoadingState.emit(true);
            if (botList) {
                this.bots = botList;
                const setDates = this.structureService.setInitialValues();
                this.selectedDate = [new Date(setDates.fromDate), new Date(setDates.toDate)];
            }
            const selectedBot = this.bots.find(item => item.botId === this.cacheValues.botId);
            this.currentBot = this.cacheValues.botId ? this.setCurrentBot(selectedBot) :
            this.bots.length === 1 ? this.bots[0] : this.bots.length > 1 ? this.structureService.resetBotState() : null;
            this.cacheValues.botId = this.currentBot.botId;
            this.cacheValues.botType = this.currentBot.botType;
            this.cacheValues.filterValue = this.cacheValues.filterValue || '';
            this.cacheValues.filterValue1 = this.cacheValues.filterValue1 || '';
            this.cacheValues.filterValue2 = this.cacheValues.filterValue2 || '';
            this.cacheValues.filterValue3 = this.cacheValues.filterValue3 || '';
            this.cacheValues.FilterName1 = this.cacheValues.FilterName1 || '';
            this.cacheValues.FilterName2 = this.cacheValues.FilterName2 || '';
            this.cacheValues.FilterName3 = this.cacheValues.FilterName3 || '';
            this.cacheValues.labelName = this.cacheValues.labelName || this.bots[0].displayName;
            this.dataPersistenceService.set(this.cacheValues);
            if (this.cacheValues.botId) {
                await this.getFilterData();
            } else {
                this.filters = [];
            }
            this.emitFilterChanges();
        } catch (err) {
            this.emitLoadingState.emit(false);
        }
    }

    setCurrentBot(selectedBot: IBotProcessEvent) {
        if (selectedBot) {
            return selectedBot;
        } else {
            this.cacheValues.labelName = '';
            return (this.bots.length === 1) ? this.bots[0] : this.structureService.resetBotState();
        }
    }

    async getFilterData() {
        this.cacheValues = this.dataPersistenceService.get();
        try {
            if (this.cacheValues.botId && !this.cacheValues.masterEventId) {
                this.filters = await this.service.getFilterData(this.currentBot, this.cacheValues);
                this.selectedFilter = this.filterFactory.filterConfiguration(this.filters);
                this.updateFilterValInLocalStorage(this.cacheValues);
            } else {
                this.filters = [];
            }
            return this.filters;
        } catch (error) { }
    }

    checkPermission(role: string) {
        return this.permissionService.hasPermission(role);
    }

    onDateChange(dates: IDateFilter) {
        const diff = DateHelper.daysBetween(dates.endDate, dates.startDate);
        this.cacheValues = this.dataPersistenceService.get();
        if (!isEqual(this.cacheValues.timeSpan, diff) || dates.isDateCalenderOpen) {
            this.cacheValues.fromDate = this.dateHelper.toServer(dates.startDate);
            this.cacheValues.toDate = this.dateHelper.toServer(dates.endDate);
            this.cacheValues.timeSpan = diff;
            this.dataPersistenceService.set(this.cacheValues);
            this.getBotName();
        }
    }

    updateFilterValInLocalStorage(cacheValues: IRequestModel) {
        this.selectedFilter.forEach((ele, index: number) => {
            if (ele) {
            const filterVal = map(ele, 'filterValue').join(',');
            this.setLocalStorageValue((filterVal) ? filterVal : '', cacheValues, index + 1);
            }
        });
        this.dataPersistenceService.set(this.selectedFilter, LOCAL_STORAGE_FILTER_KEY);
    }

    setLocalStorageValue(filterString: string, localValues: IRequestModel, i: number) {
        localValues[`filterValue${i}`] = (filterString) || '';
        this.dataPersistenceService.set(localValues);
    }

    openModal() {
        this.modalRef = this.modalService.show(EmployeeSelectionModalComponent,
            {
                initialState: { data : {
                    botList: this.bots,
                    currentBot: this.currentBot,
                    filters: this.filters,
                } },
                class: 'employee-modal modal-md',
                keyboard: false, ignoreBackdropClick: true,
            });
        this.modalRef.content.onConfirm.subscribe((result: boolean) => {
            if (result) {
                this.getBotName();
            }
        });
    }

    decline(): void {
        this.modalRef.hide();
    }

    ngAfterViewChecked() {
        this.changeDetection.detectChanges();
    }
}
