import { Component, OnChanges, Input } from '@angular/core';
import { IRequestModel, IChartResponse, IDateFilter, IChartModel } from '@modules/Dashboard/dashboard.model';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { DateHelper } from '@core/utils/DateHelper';

@Component({
  selector: 'sf-inquiry-status-overview',
  templateUrl: './inquiry-status-overview.component.html',
  styleUrls: ['./inquiry-status-overview.component.scss']
})

export class InquiryStatusOverviewComponent implements OnChanges {

  @Input() inquiryStatusOverviewData: IChartResponse;
  public chartData: IChartModel;
  public localValues: IRequestModel;
  public showSecondaryDateFilter = true;
  public loadingFlag = false;

  constructor(private dataPersistenceService: DataPersistenceService, private service: DashboardService) { }

  async ngOnChanges() {
    this.localValues = await this.dataPersistenceService.get();
    if (this.inquiryStatusOverviewData && this.inquiryStatusOverviewData.data.length && this.localValues) {
      this.setChartValue();
    }
  }

  get hasOneDaySelected() {
    if (this.localValues) {
      const diff = DateHelper.daysBetween(new Date(this.localValues.toDate), new Date(this.localValues.fromDate));
      return !(diff === 1);
    }
  }

  setChartValue() {
    this.chartData = {
      chart: this.inquiryStatusOverviewData.data,
      kpi: this.inquiryStatusOverviewData.kpi,
    };
  }

  async onSecondaryDateChange(dates: IDateFilter) {
    this.localValues.fromDate = dates.startDate.toDateString();
    this.localValues.toDate = dates.endDate.toDateString();

    try {
      this.loadingFlag = true;
      const inquiryStackData = await this.service.getInquiryStatusStackChartData(this.localValues);
      this.inquiryStatusOverviewData = inquiryStackData.result;
      this.setChartValue();
    } catch (error) {
      this.loadingFlag = false;
    } finally {
      this.loadingFlag = false;
    }
  }
}
