import { Component, OnInit } from '@angular/core';
import { SessionService } from '@core/services/Session';
import { DataService } from '@core/services/DataService';
import { PermissionService } from '@core/services/PermissionService';


@Component({
  templateUrl: 'application-layout.component.html',
  styleUrls: ['application-layout.component.scss'],
})
export class ApplicationLayoutComponent implements OnInit {
  public ready = false;
  public error: any = null;

  constructor(
    private session: SessionService,
    private dataService: DataService,
    private permissionService: PermissionService,
  ) { }

  async ngOnInit() {
    try {
      this.session.startSession();
      await this.dataService.defineEndpoints();
      await this.permissionService.loadPermissions();
      this.ready = true;
    } catch (err) {
      this.error = err;
    }
  }
}
