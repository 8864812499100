import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { DashboardPageComponent } from './dashboard/dashboard.page';
import { LoginCallbackPageComponent } from './login-callback/login-callback.page';
import { ComponentsModule } from 'src/components/components.module';
import { DashboardModule } from '@modules/Dashboard/dashboard.module';
import { SuperAdminModule } from '@modules/Super-admin/super-admin.module';
import { SuperAdminPageComponent } from './super-admin/super-admin.page';

@NgModule({
  declarations: [
    LoginCallbackPageComponent,
    DashboardPageComponent,
    SuperAdminPageComponent,
  ],
  imports: [
    CoreModule,
    ComponentsModule,
    DashboardModule,
    SuperAdminModule
  ],
})
export class ApplicationPagesModule { }
