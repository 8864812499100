import { Component, Output, EventEmitter, Input, OnChanges, QueryList, Renderer2, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import {
  PermissionEnum,
  IFilter,
  ISetting,
  IRequestModel,
  ICustomers,
  IBotProcessEvent,
  IBotProcess,
  INotification,
  EmailAlertSchedule,
  EmailAlertValueType,
  EmailAlertCondition,
  EmailAlertStatus, IEmailAlert,
} from '@modules/Dashboard/dashboard.model';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { isEqual, cloneDeep, find, forEach, omit, findIndex } from 'lodash';
import { SessionService } from '@core/services/Session';
import { PermissionService } from '@core/services/PermissionService';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import {
  validationRegEx, SETTING_BOT,
  LOCAL_STORAGE_DEFAULT_FILTER_KEY,
  DEFAULT_FILTER,
  LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER,
  LOCAL_STORAGE_FILTER_KEY
} from '@config/constants';
import { DashboardMapper } from '@modules/Dashboard/Services/DashboardMapper';
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import { DashboardStructureService } from '@modules/Dashboard/Services/DashboardStructureService';
import { DEFAULT_CURRENT_PAGE } from 'src/app/pages/dashboard/dashboard.page';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { combineLatest, Subscription } from 'rxjs';
import { DashboardFormBuilder } from '@modules/Dashboard/Services/DashboardFormBuilder';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ConfirmModalComponent } from 'src/components/confirm-modal/confirm-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'sf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges {

  @Output() filtersChange = new EventEmitter<IFilter[]>();
  @Input() subHeader: QueryList<SubHeaderComponent>;
  @Input() bots: IBotProcessEvent[];
  @Input() customers: ICustomers[];
  @Input() filters: IFilter[] = [];
  @Input() hideAllException: boolean;
  @Input() cacheValues: IRequestModel;

  public modalRef: BsModalRef;
  public notificationModalRef: BsModalRef;
  public permission = PermissionEnum;
  public settingBots: IBotProcessEvent[];
  public settingFilters: ISetting;
  public settingFiltersSnapshot: ISetting;
  public manualTimeSpanRegEx = validationRegEx.timeValidationRegEx;
  public selectedSettingLabel: string;
  public selectedEmailAlertLabel: string[] = [];
  public loadingSetting: boolean;
  public loading: boolean;
  public settingCachevalues: IBotProcessEvent | IBotProcess;
  public subscriptions: Subscription[] = [];
  public emailAlerts: IEmailAlert[] = [];
  public alertSelectionErrorMsg: string[] = [];
  public alertDuplicateErrorMsg = '';
  public form: FormGroup;
  public alertBots = [];
  public confirmModalRef: BsModalRef;
  public notifications: INotification;
  public unreadNotificationCount: Number;
  public filterSelectValidationMsg: string;
  public hasDefaultFilter: boolean;
  public emailAlertLevel = [
    { label: 'Bot' }, { label: 'Process' },
  ];
  public emailAlertScheduleValue = [
    { label: 'Daily', value: EmailAlertSchedule.Daily },
    { label: 'Weekly', value: EmailAlertSchedule.Weekly },
    { label: 'Biweekly', value: EmailAlertSchedule.Biweekly },
    { label: 'Monthly ', value: EmailAlertSchedule.Monthly },
  ];
  public emailAlertValueType = [
    { label: 'Count', value: EmailAlertValueType.Count },
    { label: 'Percentage %', value: EmailAlertValueType.Percentage }
  ];
  public emailAlertCondition = [
    { label: 'Greater than (>)', value: EmailAlertCondition.GreaterThan },
    { label: 'Less than (<)', value: EmailAlertCondition.LessThan }
  ];
  public emailAlertStatus = [
    { label: 'Processed', value: EmailAlertStatus.Success },
    { label: 'Handover', value: EmailAlertStatus.HandOver },
    { label: 'Exceptions', value: EmailAlertStatus.Exceptions },
    { label: 'System Failure', value: EmailAlertStatus.SystemFailure },
  ];
  public modalConfig = {
    class: 'modal-dialog-centered modal-sm',
    keyboard: false,
    ignoreBackdropClick: true,
  };
  public notifyMsg = {};
  public isAlertDuplicate = false;
  public profileDropdownAutoClose = false;
  public selectedDefaultFilter = 'Select Default filter';
  public removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private router: Router,
    private service: DashboardService,
    private session: SessionService,
    private permissionService: PermissionService,
    private dataPersistenceService: DataPersistenceService,
    private modalService: BsModalService,
    private mapper: DashboardMapper,
    private structureService: DashboardStructureService,
    private renderer: Renderer2,
    private changeDetection: ChangeDetectorRef,
    private formBuilder: DashboardFormBuilder,
  ) { }

  get customer() {
    if (this.customers.length && this.cacheValues.customerId) {
      return this.customers.find((model: ICustomers) => this.cacheValues.customerId === model.id);
    }
  }

  get hasProcessLevel() {
    if (this.customer.isManualInquiryAtJobLevel) {
      return this.settingCachevalues && this.settingCachevalues.botId && !this.settingCachevalues.masterProcessId;
    } else {
      return this.settingCachevalues && this.settingCachevalues.masterProcessId;
    }
  }

  isCustomerSelected(customer: ICustomers) {
    return this.customers.length > 1 && (this.cacheValues && customer.id === this.cacheValues.customerId);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cacheValues = (changes.cacheValues && changes.cacheValues.currentValue) || this.dataPersistenceService.get();
    if (changes.customers && changes.customers.currentValue) {
      this.getNotificationList();
    }
  }

  async getNotificationList() {
    const pageNumber = 1;
    this.notifications = await this.service.getNotifications(this.customer, pageNumber);
    this.unreadNotificationCount = this.notifications.totalUnreadCount;
  }

  async changeCustomer(customer: ICustomers) {
    this.profileDropdownAutoClose = true;
    if (isEqual(this.cacheValues.customerId, customer.id)) {
      return;
    }
    try {
      this.loading = true;
      this.bots = await this.service.getBots(customer);
      this.cacheValues = await this.structureService.setInitialValues();
      if (this.bots && this.bots.length) {
        this.cacheValues.customerId = customer.id;
        this.cacheValues.customerType = customer.customerType;
        this.getNotificationList();
        if (this.bots.length > 1) {
          this.cacheValues.botId = null;
          this.bots.unshift(this.structureService.resetBotState());
        } else {
          this.cacheValues.botId = this.bots[0].botId;
        }
        this.dataPersistenceService.set(this.cacheValues);
        if (this.router.url.indexOf('/exceptions') > -1) {
          this.navigatToDashboard();
        }
        this.subHeader.first.getBotName(this.bots);
      } else {
        this.subHeader.first.showAcknowledgeModal();
        this.loading = false;
      }
      this.dataPersistenceService.clear(LOCAL_STORAGE_DEFAULT_FILTER_KEY);
      this.dataPersistenceService.clear(LOCAL_STORAGE_FILTER_KEY);
      this.dataPersistenceService.clear(SETTING_BOT);
    } catch (err) { }
    finally {
      this.loading = false;
      this.profileDropdownAutoClose = false;
    }
  }

  emitFilterChanges() {
    this.filtersChange.emit(this.filters);
  }

  logout() {
    this.session.logout();
  }

  openNotificationModal() {
    if (this.notifications)  {
      this.notificationModalRef = this.modalService.show(
          NotificationModalComponent, {
            initialState: { data: {
              notificationList: this.notifications,
              selectedCustomer: this.customer,
            } },
            class: 'notification-modal modal-md', animated: true
          }
        );

      this.notificationModalRef.content.onStatusChange.subscribe((result: number) => {
        this.unreadNotificationCount = result;
      });
      this.renderer.addClass(document.body, 'notification');
      const combine = combineLatest([this.modalService.onHidden]).subscribe(() => this.changeDetection.markForCheck());

      this.subscriptions.push(
        this.modalService.onHidden.subscribe(() => {
          this.notificationModalRef = null;
          this.unsubscribe();
          this.renderer.removeClass(document.body, 'notification');
        })
      );

      this.subscriptions.push(combine);
    }
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  async openSettingModal(template) {
    try {
      this.renderer.addClass(document.body, 'setting');
      this.loadingSetting = true;
      this.cacheValues = this.dataPersistenceService.get();
      await this.getEmailAlerts();
      // FIXME NEED TO REMOVE SETTING LOCAL STORAGE
      this.settingBots = this.mapper.serializeBots(this.bots, false, true);
      this.settingCachevalues = this.settingBots.find(item => item.botId === this.cacheValues.botId);
      if (this.cacheValues.masterProcessId) {
        this.settingCachevalues = this.settingCachevalues.botProcess.find(
          record => record.masterProcessId === this.cacheValues.masterProcessId);
      }
      this.dataPersistenceService.set(this.settingCachevalues, SETTING_BOT);
      this.selectedSettingLabel = this.settingCachevalues.labelName;
      this.settingFilters = await this.service.getSettingsData(
        this.dataPersistenceService.getByKey(SETTING_BOT), this.cacheValues,
      );
      this.checkAtleastOneFilterIsSelected();
      this.selectedDefaultFilter = this.findDefaultFilterName();
      const defaultIndexFilter = findIndex(this.settingFilters.filter, item => item.isDefault);
      if (defaultIndexFilter > -1) {
        this.settingFilters.filter[defaultIndexFilter].isActive = true;
      }
      this.settingFilters.kpi = this.settingFilters.kpi || 0;
      this.settingFiltersSnapshot = cloneDeep(this.settingFilters);
      const configData = {...this.modalConfig, id: 1, class: 'setting-modal modal-dialog-centered'};
      this.modalRef = this.modalService.show(template, configData);
    } catch (err) {
      this.loadingSetting = false;
    } finally {
      this.loadingSetting = false;
    }
  }

  get emailAlert() {
    return this.form.controls['emailAlerts'] as FormArray;
  }

  findDefaultFilterName() {
    const defaultFilter = this.getDefaultFilter();
    return (defaultFilter) ? this.setDefaultFilterName(defaultFilter) : 'Select Default Filter';
  }

  async getEmailAlerts() {
    const customer = find(this.customers, (item: ICustomers) => item.id === this.cacheValues.customerId);
    this.emailAlerts = await this.service.getEmailAlertData(customer);
    this.constructForm();
    this.setEmailAlertBots();
  }

  constructForm() {
    this.form = (this.emailAlerts.length) ? this.formBuilder.fromModel(this.emailAlerts) : this.formBuilder.createEmpty();
    this.form.controls['emailAlerts']['controls'].forEach((form: FormGroup) => {
      if (form.get('emailAddress').value.length) {
        form.get('emailAddress').patchValue(form.get('emailAddress').value.split(','));
      }
      this.setValidators(form, form.get('valueType').value);
    });
  }

  createAlert() {
    this.emailAlert.controls.push(this.formBuilder.createEmpty().controls['emailAlerts']['controls'][0]);
    this.setValidators(this.emailAlert.controls[this.emailAlert.length - 1], EmailAlertValueType.Percentage);
    this.selectedEmailAlertLabel[this.emailAlert.length - 1] = 'Select Level';
    this.alertBots[this.emailAlert.length - 1] = this.mapper.serializeBots(this.bots, false, true);
  }

  setEmailAlertBots() {
    const alertBots = this.mapper.serializeBots(this.bots, false, true);
    this.alertBots[0] = alertBots;
    if (this.emailAlerts.length) {
      forEach(this.emailAlerts, (alert: IEmailAlert, i: number) => {
        if (alert.masterProcessId) {
          this.alertBots[i] = alertBots;
          forEach(this.alertBots[i], (bot) => {
            if (bot.botProcess.length) {
              const process = bot.botProcess.find(record => record.masterProcessId === alert.masterProcessId);
              if (process && process.labelName) {
                this.selectedEmailAlertLabel[i] = process.labelName;
              }
            }
          });
        } else {
          this.alertBots[i] = this.mapper.serializeBots(this.bots, true, true);
          const alertLabel = this.alertBots[i].find(item => item.botId === alert.botId);
          this.selectedEmailAlertLabel[i] = alertLabel ? alertLabel.labelName : 'Select Level';
        }
      });
    } else {
      this.selectedEmailAlertLabel[0] = 'Select Level';
    }
  }

  changeEmailAlertBot(item: IBotProcessEvent, i: number) {
    if (item && item.botId) {
      this.emailAlert.controls[i].get('botId').patchValue(item.botId);
      this.emailAlert.controls[i].get('masterProcessId').patchValue(item.masterProcessId || null);
      this.selectedEmailAlertLabel[i] = item.labelName;
    }
  }

  getAlertSchedule(i: number) {
    const status = this.emailAlert.controls[i].get('schedule').value;
    return (status === '') ? 'Select Schedule' : find(this.emailAlertScheduleValue, val => (val.value === status)).label;
  }

  getAlertCondition(i: number) {
    const condition = this.emailAlert.controls[i].get('condition').value;
    return (condition === '') ? 'Select Condition' : find(this.emailAlertCondition, val => (val.value === condition)).label;
  }

  getValueType(i: number) {
    const valueType = this.emailAlert.controls[i].get('valueType').value;
    return find(this.emailAlertValueType, val => (val.value === valueType)).value;
  }

  getAlertStatus(i: number) {
    const status = this.emailAlert.controls[i].get('status').value;
    return (status === '') ? 'Select Status' : find(this.emailAlertStatus, val => (val.value === status)).label;
  }

  setEmailAlertLevel(level: string, i: number) {
    this.alertBots[i] = this.mapper.serializeBots(this.bots, (level === 'Bot'), true);
    this.emailAlert.controls[i].get('emailAlertLevel').patchValue(level);
    this.emailAlert.controls[i].get('botId').patchValue(null);
    this.emailAlert.controls[i].get('masterProcessId').patchValue(null);
    this.selectedEmailAlertLabel[i] = 'Select Level';
  }

  setAlertScheduleValue(scheduleVal: number, i: number) {
    this.emailAlert.controls[i].get('schedule').patchValue(scheduleVal);
  }

  setAlertConditionValue(conditionVal: number, i: number) {
    this.emailAlert.controls[i].get('condition').patchValue(conditionVal);
  }

  setValueType(valueType: number, i: number) {
    this.emailAlert.controls[i].get('valueType').patchValue(valueType);
    this.setValidators(this.emailAlert.controls[i], valueType);
  }

  setAlertStatus(status: number, i: number) {
    this.emailAlert.controls[i].get('status').patchValue(status);
  }

  setValidators(form: AbstractControl, valueType: number) {
    form.get('value').setValidators([
      ...this.formBuilder.defaultNumberValidators,
      (valueType === EmailAlertValueType.Percentage) ? Validators.max(100) : Validators.min(0)
    ]);
    form.get('emailAddress').setValidators([Validators.required, this.formBuilder.setEmailValidators(this.customer)]);
    form.get('value').updateValueAndValidity();
  }

  checkValidation(i: number) {
    // tslint:disable-next-line: max-line-length
    return (this.emailAlert.controls[i].get('emailAlertLevel').errors) && (this.emailAlert.controls[i].get('emailAlertLevel').errors.required) ||
      (this.emailAlert.controls[i].get('botId').errors) && (this.emailAlert.controls[i].get('botId').errors.required) ||
      (this.emailAlert.controls[i].get('schedule').errors) && this.emailAlert.controls[i].get('schedule').errors.required ||
      (this.emailAlert.controls[i].get('condition').errors) && this.emailAlert.controls[i].get('condition').errors.required ||
      (this.emailAlert.controls[i].get('value').errors) && this.emailAlert.controls[i].get('value').errors.required ||
      (this.emailAlert.controls[i].get('valueType').errors) && this.emailAlert.controls[i].get('valueType').errors.required ||
      (this.emailAlert.controls[i].get('status').errors) && this.emailAlert.controls[i].get('status').errors.required ||
      (this.emailAlert.controls[i].get('emailAddress').errors) && this.emailAlert.controls[i].get('emailAddress').errors.required;
  }

  onDeleteConfirmation(model: FormGroup, i: number) {
    this.confirmModalRef = this.modalService.show(ConfirmModalComponent, { ...this.modalConfig, id: 2 });
    this.confirmModalRef.content.data = { title: `Are you sure you want to delete Email Alert ${i + 1}?` };
    this.confirmModalRef.content.onConfirm.subscribe((result: boolean) => {
      if (result) {
        this.deleteEmailAlert(model, i);
        this.confirmModalRef.hide();
      }
    });
  }

  async deleteEmailAlert(model: FormGroup, i: number) {
    try {
      if (model.value.id) {
        await this.service.deleteEmailAlert(this.emailAlerts[i], this.cacheValues);
      }
      const control = <FormArray>this.emailAlert;
      control.removeAt(i);
      this.selectedEmailAlertLabel.splice(i, 1);
      this.alertBots.splice(i, 1);
      this.notifyMsg = {...this.notifyMsg, type: 'danger', msg: `Email alert ${i + 1} is deleted`};
    } catch (err) {
      this.notifyMsg = {...this.notifyMsg, type: 'danger', msg: 'Oops! Something went wrong'};
    }
  }

  addEmailAddress(e: MatChipInputEvent, i: number) {
    const input = e.input;
    const value = e.value;
    const isDuplicate = this.emailAlert.controls[i].get('emailAddress').value.includes(value.trim());

    if ((value || '').trim() && !isDuplicate) {
      const controlValue = this.emailAlert.controls[i].get('emailAddress').value;
      controlValue.push(e.value);
      this.emailAlert.controls[i].get('emailAddress').patchValue(controlValue);
      this.emailAlert.controls[i].get('emailAddress').updateValueAndValidity();
    }

    if (input) {
      input.value = '';
    }
  }

  removeEmailAddress(emailAddress: string, i: number) {
    const index = this.emailAlert.controls[i].get('emailAddress').value.indexOf(emailAddress);
    if (index >= 0) {
      this.emailAlert.controls[i].get('emailAddress').value.splice(index, 1);
      this.emailAlert.controls[i].get('emailAddress').updateValueAndValidity();
    }
  }

  async saveEmailAlert() {
    try {
      this.loadingSetting = true;
      const data = [];
      const customer = find(this.customers, (item: ICustomers) => item.id === this.cacheValues.customerId);
      forEach(this.emailAlert.controls, (alert: FormGroup, i: number) => {
        data.push(this.formBuilder.toModel(alert));
        data[i].emailAddress = data[i].emailAddress.join(',');
      });
      await this.service.saveEmailAlert(customer, data);
      this.decline();
    } catch (err) {
      this.loadingSetting = false;
    } finally {
      this.loadingSetting = false;
    }
  }

  onNotifyMsgClosed() {
    this.notifyMsg = {};
  }

  isDisabled() {
    let invalid = false;
    forEach(this.emailAlert.controls, (form: FormGroup, i: number) => {
      this.alertSelectionErrorMsg[i] = '';
      if (
        form.invalid ||
        form.value.emailAlertLevel === 'Process' && !form.value.masterProcessId ||
        form.value.emailAlertLevel === 'Bot' && form.value.masterProcessId
      ) {
        this.alertSelectionErrorMsg[i] = this.setAlertSelectionValidationMsg(form, i);
        invalid = true;
      }
    });
    return invalid;
  }

  setAlertSelectionValidationMsg(form: FormGroup, i: number) {
    if (form.value.emailAlertLevel === 'Process' && form.value.botId && !form.value.masterProcessId) {
      const botName = find(this.alertBots[i], (alert) => alert.botId === form.value.botId).botName;
      return `Please select a process under the '${botName}'`;
    } else {
      return '';
    }
  }

  isDuplicate(control: FormGroup, index: number) {
    this.isAlertDuplicate = false;
    this.alertDuplicateErrorMsg = '';
    if (this.emailAlert.controls.length > 1) {
      forEach(this.emailAlert.controls, (form: FormGroup, i: number) => {
        if (index !== i && isEqual(omit(form.value, ['id']), omit(control.value, ['id']))) {
          this.alertDuplicateErrorMsg = 'Email Alert must be unique';
          this.isAlertDuplicate = true;
        }
      });
    }
  }

  decline(): void {
    this.renderer.removeClass(document.body, 'setting');
    this.settingFilters = cloneDeep(this.settingFiltersSnapshot);
    this.modalRef.hide();
    this.modalRef = null;
  }

  getDefaultFilter() {
    const defaultFilter = find(this.settingFilters.filter, filter => filter.isDefault);
    this.hasDefaultFilter = !!defaultFilter;
    return defaultFilter;
  }

  selectDefaultFilter(filterItem: IFilter, index: number) {
    if (!(filterItem.displayName === 'Default Filter' && this.settingCachevalues.masterProcessId)) {
      this.settingFilters.filter[index].isActive = true;
      this.checkAtleastOneFilterIsSelected();
      this.selectedDefaultFilter = this.setDefaultFilterName(filterItem);
      forEach(this.settingFilters.filter, (filter: IFilter) => {
        filter.isDefault = (filter.displayName === filterItem.displayName);
      });
    }
  }

  resetSelectedDefaultFilter() {
    this.selectedDefaultFilter = 'Select Default Filter';
    const defaultFilter = this.getDefaultFilter();
    defaultFilter.isDefault = false;
  }

  onOptionChange(value: MatCheckboxChange, filter: IFilter) {
    this.checkAtleastOneFilterIsSelected();
    const defaultFilter = this.getDefaultFilter();
    if (filter.displayName === (defaultFilter && defaultFilter.displayName) && !value.checked) {
      this.selectedDefaultFilter = 'Select Default Filter';
      defaultFilter.isDefault = false;
    }
  }

  async updateSettingFilter(settingFilters: ISetting) {
    if (isEqual(settingFilters, this.settingFiltersSnapshot)) {
      this.decline();
      return;
    }
    try {
      if (this.settingCachevalues && this.settingCachevalues.masterProcessId) {
        settingFilters['manualTimeSpan'] = `00:${settingFilters['manualTimeSpan']}`;
      } else {
        settingFilters['manualTimeSpan'] = null;
        settingFilters['kpi'] = 0;
      }
      this.loadingSetting = true;
      this.settingFilters = await this.service.updateFilterData(
        this.dataPersistenceService.getByKey(SETTING_BOT), settingFilters, this.cacheValues
      );
      this.settingFiltersSnapshot = cloneDeep(this.settingFilters);
      if (this.cacheValues.botId === this.settingCachevalues.botId) {
        this.filters = await this.subHeader.first.getFilterData();
        this.emitFilterChanges();
      }
      this.decline();
    } catch (err) {
      this.loadingSetting = false;
    } finally {
      this.loadingSetting = false;
    }
  }

  setDefaultFilterName(data: IFilter) {
    if (data.displayName === DEFAULT_FILTER) {
      return (this.settingCachevalues.botId && this.settingCachevalues.masterProcessId ? 'Underlying Tasks' : 'Underlying Processes');
    } else {
      return data.displayName;
    }
  }

  async changeSettingBot(item: IBotProcessEvent) {
    if (item && item.botId) {
      this.dataPersistenceService.set(item, SETTING_BOT);
      this.selectedSettingLabel = item.labelName;
      this.settingFilters = await this.service.getSettingsData(item, this.cacheValues);
      this.checkAtleastOneFilterIsSelected();
      this.settingFiltersSnapshot = cloneDeep(this.settingFilters);
      this.settingCachevalues = item;
      this.selectedDefaultFilter = this.findDefaultFilterName();
    }
  }

  checkAtleastOneFilterIsSelected() {
    const activeFilter = this.settingFilters.filter.filter(item => item.isActive);
    this.filterSelectValidationMsg = (activeFilter.length) ? '' : 'Please select at least one filter';
  }

  checkPermission(role: string) {
    return this.permissionService.hasPermission(role);
  }

  checkManualTimeSpan() {
    if (this.settingCachevalues && this.settingCachevalues.masterProcessId) {
      return !this.settingFilters.manualTimeSpan;
    } else {
      return false;
    }
  }

  navigateToSuperAdmin() {
    this.router.navigate([]).then(result => { window.open('/super-admin', '_blank'); });
  }

  navigatToDashboard() {
    this.router.navigate(['home']);
    this.dataPersistenceService.set(DEFAULT_CURRENT_PAGE, LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER);
  }
}
