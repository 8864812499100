import { Injectable } from '@angular/core';
import { IFilter, IFilterValue, IValue } from '../dashboard.model';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { LOCAL_STORAGE_FILTER_KEY } from '@config/constants';
import { map } from 'lodash';

@Injectable()

export class DashboardFilterFactory {

    constructor(private dataPersistenceService: DataPersistenceService) {}

    filterConfiguration(filters: IFilter[]) {
        const filterLocalValues = this.dataPersistenceService.getByKey(LOCAL_STORAGE_FILTER_KEY);
        const selectedFilters = [];

        if (filters.length > 0 && filterLocalValues) {
            const filtervalue = map(filterLocalValues, (val: IFilterValue[]) => {
                return map(val, 'filterValue');
            });
            filters.forEach((model: IFilter, i: number) => {
                const filterArray = model.values.filter(function (ele: IValue) {
                    if (filtervalue[i]) {
                        return filtervalue[i].some(function (item: string) {
                            return ele.filterValue === item;
                        });
                    } else {
                        return null;
                    }
                });
                selectedFilters.push(filterArray);
            });
            return selectedFilters;
        } else {
            return [];
        }
    }
}
