export class StringHelper {
    static toCamelCase(value: string) {
        return value[0].toLowerCase() + value.slice(1);
    }

    static removeStartEndComma(value: string) {
        return value.replace(/^[,\s]+|[,\s]+$/g, '').replace(/,[,\s]*,/g, ',');
    }
}

