import { Observable } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { User } from 'oidc-client';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SessionService } from '@core/services/Session';
import { Router } from '@angular/router';

@Injectable()
export class APIRequestInterceptor implements HttpInterceptor {
  constructor(
    private session: SessionService,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.session.observeSession().pipe(mergeMap((session: User) => {
      let request = req;
      const accessToken = session.access_token;
      if (accessToken) {
        const duplicate = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        request = duplicate;
      }

      const res = next.handle(request).pipe(tap((response: any) => {
        if (response instanceof HttpResponse) {
          this.handleSuccessResponse(response);
        }
      }, (error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.handleError(error);
        }
      }));

      return res;
    }));


  }

  handleSuccessResponse(response: HttpResponse<any>) {
    if (response.status < 400) {
      // To consider
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 403 || error.status === 401) {
      this.router.navigate(['access-denied']);
    }
  }
}
