// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const config: ApplicationConfig = {
  production: false,
  environmentName: window.DE_ENVIRONMENT.environmentName,
  identityBaseUrl: window.DE_ENVIRONMENT.identityBaseUrl,
  apiBaseUrl: window.DE_ENVIRONMENT.apiBaseUrl,
  publicUrl: window.DE_ENVIRONMENT.publicUrl,
  version: window.DE_ENVIRONMENT.version,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
