<div class="row border exception-container">
  <div class="col-12 col-sm exception-box pb-0">
    <h4 class="col-sm-10 text-left heading">Exceptions</h4>
    <div class="table-responsive align-items-center mt-3 all-exception" *ngIf="exceptionData.exceptions.length; else noRecords">
      <table class="table table-striped text-left">
        <thead class="text-head">
          <tr>
            <th>Job Name</th>
            <th>Process Name</th>
            <th>Event Name</th>
            <th>Date</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of exceptionData.exceptions">
            <td title="{{data.jobName}}">{{data.jobName}}</td>
            <td title="{{data.processName}}">{{data.processName}}</td>
            <td title="{{data.eventName}}">{{data.eventName}}</td>
            <td title="{{data.date}}">{{data.date}}</td>
            <td>{{data.description}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="row mt-2 d-flex justify-content-between pagination-section" *ngIf="exceptionData.totalRecords > pagination.pageSize">
  <span>Showing {{getFirstRecordIndexInPage()}} to {{getLastRecordIndexInPage()}} of {{exceptionData.totalRecords}}</span>
  <div class="pagination">
    <sf-pagination [pagination]="pagination" [totalRecords]="exceptionData.totalRecords" (paginationDetails)="paginate($event)"></sf-pagination>
  </div>
</div>

<ng-template #noRecords>
  <h5 class="text-danger text-center font-weight-bold mt-5 pb-5">
    No Records Found
  </h5>
</ng-template>