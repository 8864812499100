import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ICustomers, IBot } from '@modules/Dashboard/dashboard.model';
import { SuperAdminService } from '@modules/Super-admin/Services/SuperAdminService';
import { IModalData } from '@modules/Super-admin/super-admin.model';

@Component({
  selector: 'sf-token-modal',
  templateUrl: './token-modal.component.html',
  styleUrls: ['./token-modal.component.scss']
})
export class TokenModalComponent {

  public bots: IBot[];
  public data: IModalData;
  public loadingFlag = false;

  constructor(public modalRef: BsModalRef, private service: SuperAdminService ) { }

  async deleteBot() {
    try {
        this.loadingFlag = true;
        await this.service.deleteBot(this.data.botDetail);
        await this.getAttachedFilterWithBot(this.data.customerDetail);
        this.hide();
      } catch (err) {

      } finally {
        this.loadingFlag = false;
      }
  }

  async deleteFilter() {
    try {
        this.loadingFlag = true;
        await this.service.deleteFilter(this.data);
        await this.getAttachedFilterWithBot(this.data.customerDetail);
        this.hide();
      } catch (err) {

      } finally {
        this.loadingFlag = false;
      }
  }

  async getAttachedFilterWithBot(customer: ICustomers) {
    this.bots = await this.service.getAttachedFilterWithBot(customer);
    this.service.botChange.emit(this.bots);
  }

  hide() {
    this.modalRef.hide();
  }
}
