import { Component, ViewChild, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import * as echarts from 'echarts';
import { IDoughnutChartLegends } from '@modules/Dashboard/dashboard.model';
import { compact } from 'lodash';

@Component({
  selector: 'sf-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnChanges {

  public colors = ['#8c54ff', '#c2dcff', '#ffaeae', '#dbdbdb', '#a4ff54', '#47baff', '#7cc98f', '#53dde2', '#4285f4', '#dea99d', '#2159ff'];
  public myChart: any;

  @Input() doughnutChartLegends: IDoughnutChartLegends[];
  @Input() chartLegends: any;

  @ViewChild('doughnut', { static: true }) doughnut: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {

    if (this.doughnutChartLegends && this.doughnutChartLegends.length) {

      this.myChart.resize();
      this.setDoughtChartData();

    }
  }

  constructor() { }

  ngOnChanges() {
    this.setDoughtChartData();
  }

  setDoughtChartData() {
    const formatter = [];
    const maxStringLength = 14;
    const chartData = this.doughnutChartLegends.map((value: IDoughnutChartLegends, i: number) => {
      // format toolpit labels
      if (this.doughnutChartLegends[i].resultCount && this.doughnutChartLegends[i].total) {
        formatter[i] = `${this.doughnutChartLegends[i].resultCount} of ${this.doughnutChartLegends[i].total} Inquiries`;
      } else {
        formatter[i] = '';
      }

      return {
        value: this.doughnutChartLegends[i].data,
        name: this.doughnutChartLegends[i].title,
      };
    });

    const legend = this.doughnutChartLegends.map((value: IDoughnutChartLegends, i: number) => {
      return (value.data === 0) ? '' : this.doughnutChartLegends[i].title;
    });

    this.myChart = echarts.init(this.doughnut.nativeElement as HTMLDivElement, {}, { height : 490 });
    this.myChart.setOption({
      color: this.colors,
      legend: {
        y: 'bottom',
        selectedMode: true,
        data: compact(legend),
        icon: 'circle',
        formatter: function (name: string) {
          if (compact(legend).length > 5 ) {
            return (name.length > maxStringLength) ? `${name.substr(0, maxStringLength)}...` : name;
          } else {
            return name;
          }
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['48%', '69%'],
          avoidLabelOverlap: false,
          legendHoverLink: false,
          label: {
            show: false,
            position: 'center',
            formatter: function(params: any) {
              if (params.data.value) {
                return [
                  `{b| ${(params.data.name.length > 20) ?  `${params.data.name.substr(0, 20)}...` : params.data.name}}`,
                  `{d| ${params.data.value} %}`,
                  `{b| ${formatter[params.dataIndex]}}`,
                ].join('\n');
              }
            },
            emphasis: {
              show: true,
            },
            color: '#000',
            rich: {
              b: {
                color: '#000',
                fontSize: 12,
                fontFamily: 'Lato',
              },
              d: {
                color: '#000',
                fontSize: 36,
                fontWeight: 'normal',
                fontFamily: 'Lato',
              },
            },
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: chartData
        }
      ]
    }, true);

    this.setHighlightLegend(legend);

    this.myChart.on('mouseout', () => {
      this.restoreDispatchAction();
      this.setHighlightLegend(legend);
    });

    this.myChart.on('mouseover', (params: any) => {
      this.restoreDispatchAction();
      this.setHighlightLegend(legend, params);
    });

    this.myChart.on('legendselectchanged', (params: any) => {
      this.selectLegend(params);
      this.setHighlightLegend(legend, params);
    });
  }

  setHighlightLegend(legend: string[], params?: any) {
    const dataIndexVal = legend.map((val: string, i: number) => val ? i : -1).filter(val => val > -1)[0];
    const configOptions = {
      type: 'highlight',
      seriesIndex: 0,
    };
    this.myChart.dispatchAction( (params) ? { ...configOptions, name: params.name } : { ...configOptions, dataIndex: dataIndexVal } );
  }

  selectLegend(params: any) {
    this.myChart.dispatchAction({
      type: 'legendSelect',
      name: params.name,
    });
  }

  restoreDispatchAction() {
    this.myChart.dispatchAction({
      type: 'restore'
    });
  }
}
