<div class="row mt-3 mx-auto">
    <div class="col-md-8 pl-0 d-flex justify-content-start align-items-center">
        <sf-date-filter (datesChange)="onDateChange($event)" [selectedDate]="selectedDate"></sf-date-filter>
        <span class="all-employee-text">
            <a href="javascript:void(0)" (click)="openModal()" [class.disabled]="!bots && !filters">All Modules</a>
        </span>
    </div>
    <div class="col-md-4 mt-2 selectedEmployees" *ngIf="cacheValues">
        <div *ngIf="breadCrumb(); else singleWord">
            <span *ngFor="let value of breadCrumbValue; let i = index" [tooltip]="breadCrumbValue[i]">{{breadCrumbValue[i] | truncateInside: 15}} <span *ngIf="breadCrumbValue[i+1]"> > </span></span>
        </div>
        <ng-template #singleWord>
            <span>{{breadCrumbValue}}</span>
        </ng-template>
    </div>
</div>