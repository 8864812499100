<div class="modal-header" *ngIf="data">
    <h6 class="modal-title">{{ data.title }}</h6>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col text-right m-2">
            <button type="button" class="btn save-btn mr-2" (click)="confirm()">Yes</button>
            <button type="button " class="btn cancel-btn" (click)="decline()">No</button>
        </div>
    </div>
</div>
