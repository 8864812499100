import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

 constructor() { }

  createHeaders(id: string) {
    const headers = new HttpHeaders();
    return headers.append('Id', id);
  }

}
