<sf-loading [loadingFlag]="loadingFlag"></sf-loading>
<div class="container-fluid table-action">
    <div class="row ml-2 mb-2">
        <div class="col pull-left">
            <button class="link-btn btn mb-2" (click)="openModal()">Add Bot</button>
        </div>
        <button class="pull-right home-btn btn mb-2" [routerLink]="['/super-admin/add-database']">Go to home</button>
    </div>
    <label class="customer-label ml-4" *ngIf="customerDetail">Customer:{{customerDetail.customerName}}</label>
    <div class="container-fluid">
        <div class="admin-table table-responsive px-lg-2 mb-4">
            <table class="table text-left mb-4">

                <thead>
                    <tr>
                        <th class="text-left">Bot Name</th>
                        <th class="text-left">Filters</th>
                        <th class="text-center">Add filter</th>
                    </tr>
                </thead>

                <tbody *ngIf="bots && bots.length">
                    <tr *ngFor="let data of bots; let i = index ">
                        <td class="text-left">
                            <sf-tokens [tokens]="data.botName" (tokenClick)="openModal(data,true)" (tokenRemove)="removeModal(data,false)"></sf-tokens>
                        </td>
                        <td class="text-left">
                            <sf-tokens [tokens]="data.filters" (tokenClick)="tokenClick($event,data,true)" (tokenRemove)="removeModal($event,true)"></sf-tokens>
                        </td>
                        <td class="text-center"><img src="/assets/images/Edit.png" class="img-fluid edit-img" (click)="tokenClick(data,false)"></td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
</div>