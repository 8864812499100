<div class="row d-flex align-items-sm-center align-items-xl-baseline px-3 inquiry-breakdown-section">
    <h6 class="col-8 col-md-8 text-left inquiry-heading pl-0">{{ (!isFiltersAvailable && (localValues && localValues.botType)) ? 'Employee Utilization' : 'Inquiry Breakdown'}}</h6>
    <div class="col-4 col-md-4 text-right btn-group w-75 inquiry-filter" dropdown *ngIf="hasFilterSelected()">
        <button id="select-de" dropdownToggle type="button" class="col-sm btn dropdown-toggle text-center" aria-controls="select-de" [title]="selectedFilter">
            {{selectedFilter | truncateInside: 15 }} <i class="dropdown-arrow down"></i>
        </button>
        <ul id="select-de" *dropdownMenu class="dropdown-menu option" role="menu" aria-labelledby="select-de">
            <li role="menuitem" *ngFor="let data of filters; let i = index">
                <a *ngIf="showFilterOption(data)" class="dropdown-item" href="javascript:void(0)" (click)="selectFilter(i)" [title]="getDisplayName(data)">
                    {{ getDisplayName(data) | truncateInside: 15 }}
                </a>
            </li>
        </ul>
    </div>
</div>

<sf-doughnut-chart [doughnutChartLegends]="doughnutChartLegends" *ngIf="doughnutChartLegends && doughnutChartLegends.length; else noRecords"></sf-doughnut-chart>
<ng-template #noRecords>
    <h6 class="text-left heading no-record-text text-center text-danger" *ngIf="hasFilterSelected(); else noFilterSelected">
        {{(botId) ? getNoFilterSelectedLabel() : 'Please select a process to view performance'}}
    </h6>
    <ng-template #noFilterSelected>
        <h6 class="text-left heading no-record-text text-center text-danger">
            {{(botId) ? getFilterMessage() : 'Please select a process to view performance'}}
        </h6>
    </ng-template>
</ng-template>

<sf-loading [loadingFlag]="loadingFlag"></sf-loading>