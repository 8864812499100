import { Injectable } from '@angular/core';
import { UserManager } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';
import { from } from 'rxjs';
import { ISessionService, ISession } from '@core/core.interfaces';
import { Constants } from '@config/constants';
import { DataService } from '@core/services/DataService';
import { config } from '@environments/environment';

@Injectable()
export class SessionService implements ISessionService {

  public isLoggedIn = new BehaviorSubject(false);
  public identity = new BehaviorSubject(null);
  public manager: UserManager;

  constructor(private dataService: DataService) {
    this.manager = new UserManager({
      authority: config.identityBaseUrl,
      client_id: 'js.sf.de',
      redirect_uri: `${config.publicUrl}/callback.html`,
      response_type: 'token id_token',
      scope: 'openid profile sf-de-api',
      post_logout_redirect_uri: `${config.publicUrl}/signout-callback-oidc`,
    });
  }

  startSession() {
    const session = this.getSession();
    if (session) {
      this.isLoggedIn.next(true);
    }
  }

  getCurrentUserDetails() {
    return this.manager.getUser();
  }

  async hasSession() {
    const session = await this.getSession();
    return !!session && !!session.access_token;
  }

  async getSession() {
    const session = await this.manager.getUser();
    return session;
  }

  setSession(session: ISession) {
    if (session) {
      window.localStorage.setItem(Constants.accessTokenAlias, session.accessToken);
      this.isLoggedIn.next(true);
    }
  }

  observeSession() {
    return from(this.getSession());
  }

  resetSession() {
    window.localStorage.removeItem(Constants.accessTokenAlias);
    this.isLoggedIn.next(false);
    this.requireLogin();
  }

  requireLogin() {
    this.manager.signinRedirect();
  }

  loginCallback() {
    return this.manager.signinRedirectCallback();
  }

  logout() {
    window.localStorage.removeItem(Constants.accessTokenAlias);
    this.manager.signoutRedirect();
  }
}
