import { Component, OnInit } from '@angular/core';
import { SessionService } from '@core/services/Session';
import { User } from 'oidc-client';
import { DataPersistenceService } from '@core/services/DataPersistenceService';

@Component({
  selector: 'sf-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
  currentUser: User;
  constructor(private session: SessionService, private dataPersistenceService: DataPersistenceService) { }

  async ngOnInit() {
    this.currentUser = await this.session.getCurrentUserDetails();
  }
  logout() {
    this.session.logout();
    this.dataPersistenceService.clear();
  }

}
