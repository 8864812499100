import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateInside',
})

export class TruncateInsidePipe implements PipeTransform {
  transform(value: string, maxLength: number): string {
    if (value) {
      value = value.trim();
      if (maxLength && value.length > maxLength) {
        return `${value.slice(0, maxLength)}…`;
      }
      return value;
    }
  }
}
