<div class="basic-container btn btn-lg col-sm w-100" *ngIf="data && data.length" cdkScrollable>
    <div *ngFor="let item of newData">
        <!-- Handle branch node buttons here -->
        <div class="dropdown-label" *ngIf="item.children && item.children.length > 0" mat-raised-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu.childMenu">
            <button color="primary" class="btn-border" [title]="item.displayName">
                {{item.displayName | truncateInside: 50}}
            </button>
            <i class="fa fa-caret-down material-icons" aria-hidden="true"></i>
            <sf-app-menu-item #menu [items]="item" (click)="onClick($event)"></sf-app-menu-item>
        </div>
    </div>
</div>