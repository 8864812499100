import { Component, OnInit, Renderer2 } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  IBotProcessEvent,
  IBotProcess,
  IBotEvent,
  IRequestModel,
  IEmployeeModal,
  IFilter,
  IDefaultFilterValue,
  IFilterEmitValue,
} from '@modules/Dashboard/dashboard.model';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { Subject } from 'rxjs';
import { isEqual, cloneDeep, map } from 'lodash';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { LOCAL_STORAGE_DEFAULT_FILTER_KEY, LOCAL_STORAGE_FILTER_KEY } from '@config/constants';
import { DashboardFilterFactory } from '@modules/Dashboard/Services/DashboardFilterFactory';

const DEFAULT_PROCESS_NAME = 'Select Process';
const DEFAULT_EVENT_NAME = 'Select Event';
const DEFAULT_BOT_NAME = 'All Modules';

@Component({
  selector: 'sf-employee-selection-modal',
  templateUrl: './employee-selection-modal.component.html',
  styleUrls: ['./employee-selection-modal.component.scss']
})

export class EmployeeSelectionModalComponent implements OnInit {

  public data: IEmployeeModal;
  public processList: IBotProcess[] = [];
  public eventList: IBotEvent[] = [];
  public employee: IRequestModel;
  public cacheValues: IRequestModel;
  public botName: string;
  public processName: string;
  public eventName: string;
  public filters: IFilter[];
  public selectedDefaultValue = [];
  public selectedFilter = [];
  public defaultFilterValue = [];
  public filterValue = [];
  public loadingFilter = false;
  public onConfirm = new Subject<boolean>();

  constructor(
    public modalRef: BsModalRef,
    private dataPersistenceService: DataPersistenceService,
    private dashboardService: DashboardService,
    private filterFactory: DashboardFilterFactory,
    private renderer: Renderer2,
  ) { }

  async ngOnInit() {
    this.renderer.addClass(document.body, 'employee');
    this.cacheValues = await this.dataPersistenceService.get();
    this.defaultFilterValue = this.dataPersistenceService.getByKey(LOCAL_STORAGE_DEFAULT_FILTER_KEY) || [];
    this.filterValue = this.dataPersistenceService.getByKey(LOCAL_STORAGE_FILTER_KEY) || [];
    this.employee = cloneDeep(this.cacheValues);
    this.botName = this.findBot().displayName || DEFAULT_BOT_NAME;
    this.processName = (this.employee.botId) ? this.setProcessName() : DEFAULT_PROCESS_NAME;
    this.eventName = (this.employee.botId) ? this.setEventName() : DEFAULT_EVENT_NAME;

    if (this.employee.botId) {
      this.getFilterData();
    }
  }

  findBot() {
    return this.data.botList.find((bot: IBotProcessEvent) => bot.botId === this.employee.botId);
  }

  setProcessName() {
    this.processList =  this.findProcessList();
    if (this.processList.length) {
      const process = this.processList.find((model: IBotProcess) => model.masterProcessId === this.employee.masterProcessId);
      return (process) ? process.displayName : DEFAULT_PROCESS_NAME;
    } else {
      return DEFAULT_PROCESS_NAME;
    }
  }

  setEventName() {
    const processRecords = this.findProcessList();
    if (processRecords.length) {
      const process = processRecords.find((model: IBotProcess) => {
        return model.masterProcessId === this.employee.masterProcessId;
      });
      this.eventList = (process) ? process.botEvent : [];
      if (this.eventList.length) {
        const event = this.eventList.find((model: IBotEvent) => model.masterEventId === this.employee.masterEventId);
        return (event) ? event.displayName : DEFAULT_EVENT_NAME;
      } else {
        return DEFAULT_EVENT_NAME;
      }
    } else {
      return DEFAULT_EVENT_NAME;
    }
  }

  findProcessList() {
    return this.data.botList.find((bot: IBotProcessEvent) => bot.botId === this.employee.botId).botProcess;
  }

  hide() {
    this.employee = this.cacheValues;
    this.modalRef.hide();
    this.renderer.removeClass(document.body, 'employee');
  }

  selectBot(bot: IBotProcessEvent) {
    if (!isEqual(bot.displayName, this.botName)) {
      this.botName = bot.displayName;
      this.employee.botType = bot.botType;
      this.employee.botId = bot.botId || null;
      this.processList = bot.botProcess || [];
      this.resetProcess();
      this.clearFilterLocalValues();
    }
  }

  selectProcess(process: IBotProcess) {
    if (!isEqual(process.displayName, this.processName)) {
      this.processName = process.displayName;
      this.employee.masterProcessId = process.masterProcessId || null;
      this.eventList = process.botEvent || [];
      this.resetEvent();
      this.clearFilterLocalValues();
    }
  }

  clearFilterLocalValues() {
    this.defaultFilterValue = this.filterValue = [];
    this.employee.filterValue = this.employee['filterValue1'] = this.employee['filterValue2'] = this.employee['filterValue3'] = '';
    this.dataPersistenceService.clear(LOCAL_STORAGE_DEFAULT_FILTER_KEY);
  }

  resetProcess() {
    this.employee.masterProcessId = null;
    this.eventList = [];
    this.processName = DEFAULT_PROCESS_NAME;
    this.clearFilterLocalValues();
    this.resetEvent();
  }

  selectEvent(event: IBotEvent) {
    if (!isEqual(event.displayName, this.eventName)) {
      this.eventName = event.displayName;
      this.employee.masterEventId = event.masterEventId || null;
      this.clearFilterLocalValues();
    }
  }

  resetEvent() {
    this.employee.masterEventId = null;
    this.eventName = DEFAULT_EVENT_NAME;
    if (this.employee.botId) {
      this.getFilterData();
    }
  }

  async getFilterData() {
    try {
      this.loadingFilter = true;
      if (this.employee.botId && !this.employee.masterEventId) {
        const currentBot = this.findBot();
        this.filters = await this.dashboardService.getFilterData(currentBot, this.employee);
        this.selectedFilter = this.filterValue = await this.filterFactory.filterConfiguration(this.filters);
        this.updateFilterValInLocalStorage();
      } else {
        this.filters = [];
      }
    } catch (error) { }
    finally {
      this.loadingFilter = false;
    }
  }

  getSubFilter(model: IDefaultFilterValue) {
    this.defaultFilterValue = model.selectedFilter;
    this.employee.filterValue = model.filterValue;
  }

  getFilter(model: IFilterEmitValue) {
    this.filterValue = model.selectedFilter;
    this.employee[`FilterName${model.filterIndex}`] = (model.filterValue) ? model.filter.clientPropertyName : '';
    this.setLocalStorageValue(model.filterValue, model.filterIndex);
  }

  apply() {
    this.employee.labelName = this.getLabelName();
    if (!isEqual(this.cacheValues, this.employee)) {
      this.dataPersistenceService.set(this.defaultFilterValue, LOCAL_STORAGE_DEFAULT_FILTER_KEY);
      this.updateFilterValInLocalStorage();
      this.dataPersistenceService.set(this.filterValue, LOCAL_STORAGE_FILTER_KEY);
      this.cacheValues = { ...this.cacheValues, ...this.employee };
      this.dataPersistenceService.set(this.cacheValues);
      this.onConfirm.next(true);
    }
    this.hide();
  }

  getLabelName() {
    if (this.processName !== DEFAULT_PROCESS_NAME) {
      return `${this.botName} > ${this.processName} ${(this.eventName !== DEFAULT_EVENT_NAME) ? `> ${this.eventName}` : ``}`;
    } else {
      return this.botName;
    }
  }

  updateFilterValInLocalStorage() {
    this.filterValue.forEach((ele, index: number) => {
      if (ele) {
        const filterVal = map(ele, 'filterValue').join(',');
        this.setLocalStorageValue(filterVal, index + 1);
      }
    });
  }

  setLocalStorageValue(filterString: string, i: number) {
    this.employee[`filterValue${i}`] = (filterString) || '';
  }
}
