import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'sf-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})

export class LoadingComponent implements OnInit {

    @Input() loadingFlag: boolean;
    @Input() loadingFilter: boolean;

    constructor() { }

    ngOnInit() {
    }

}
