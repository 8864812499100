import { Component, OnInit, ChangeDetectorRef, ViewChildren, ElementRef, AfterViewInit, ViewRef, HostListener, ViewChild, Renderer2 } from '@angular/core';
import {
  IDateFilter,
  IDoughnutChartResponse,
  IRequestModel,
  ITimeResponse,
  IFilter,
  IException,
  IFilterValue,
  IUtilization,
  ICustomers,
  PermissionEnum,
  IBotProcessEvent,
  ExceptionType,
  IAllExceptionResponse,
  IChartResponse,
} from '@modules/Dashboard/dashboard.model';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { config } from '@environments/environment';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { IApiInfo, IClientInfo } from '@core/model/AppStatus';
import { StaticEndpoints } from '@config/endpoints';
import { DataService } from '@core/services/DataService';
import { LOCAL_STORAGE_FILTER_KEY, LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER } from '@config/constants';
import { SessionService } from '@core/services/Session';
import { DashboardStructureService } from '@modules/Dashboard/Services/DashboardStructureService';
import { PermissionService } from '@core/services/PermissionService';
import { Router, ActivatedRoute } from '@angular/router';
import { IPagination } from '@core/core.interfaces';

export const DEFAULT_CURRENT_PAGE = 1;

@Component({
  selector: 'sf-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})

export class DashboardPageComponent implements OnInit, AfterViewInit {

  @ViewChildren('subHeader') subHeader: ElementRef;

  public dateRangeValue: IDateFilter;
  public inquiryStatusData: IDoughnutChartResponse[];
  public inquiryBreakdownData: IDoughnutChartResponse[];
  public timeSavedData: ITimeResponse;
  public exceptionData: IException;
  public inquiryStatusStackChartData: IChartResponse;
  public filters: IFilter[];
  public apiInfo: IApiInfo;
  public clientInfo: IClientInfo;

  public selectedFilters: Array<null> | IFilterValue[][];
  public loading = false;
  public exportLoading = false;
  public utilization: IUtilization;
  public customers: ICustomers[];
  public selectedCustomer: ICustomers;
  public cacheValues: IRequestModel;
  public selectedDate: Date[];
  public permission = PermissionEnum;
  public bots: IBotProcessEvent[];
  public allExceptionData: IAllExceptionResponse;
  public hideAllException = true;
  public exceptionType: ExceptionType;
  public pagination: IPagination = {
    pageSize: 10,
  };
  public exportFileExtensions = [
    { extensionName: '.xlsx', isExportExcel: true },
    { extensionName: '.csv', isExportExcel: false },
  ];

  @ViewChild('kpiSection', { static: false }) kpiSection: ElementRef<HTMLElement>;
  @ViewChild('kpi', { static: false }) kpi: ElementRef<HTMLElement>;

  constructor(
    private service: DashboardService,
    private structureService: DashboardStructureService,
    private dataService: DataService,
    private dataPersistenceService: DataPersistenceService,
    private sessionService: SessionService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
   }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const kpi_section = this.kpiSection.nativeElement;
    const element = this.kpi.nativeElement.children[0];
    if (window.pageYOffset > kpi_section.clientHeight) {
      this.renderer.addClass(element.children[0], 'py-2');
      this.renderer.removeClass(element.children[0], 'py-4');
    } else {
      this.renderer.addClass(element.children[0], 'py-4');
      this.renderer.removeClass(element.children[0], 'py-2');
    }
  }

  get getExceptionType() {
    if (this.exceptionType === ExceptionType.HandOver) {
      return 'Handover';
    } else if (this.exceptionType === ExceptionType.Exception) {
      return 'Exception';
    } else {
      return 'System Failure';
    }
  }

  get hasMasterEventId() {
    return this.cacheValues && this.cacheValues.masterEventId;
  }

  async ngOnInit() {
    if (this.router.url.indexOf('/exceptions') > -1) {
      this.hideAllException = false;
      this.pagination.currentPage = this.dataPersistenceService.getByKey(LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER) || DEFAULT_CURRENT_PAGE;
      this.exceptionType = +this.route.snapshot.params.type;
      this.loadAllException();
    }
    await this.loadMetaData();
    await this.getAppStatus();
  }

  ngAfterViewInit() {
    if (this.changeDef && !(this.changeDef as ViewRef).destroyed) {
      this.changeDef.detectChanges();
    }
  }

  getCustomerName() {
    return this.customers?.find((item: ICustomers) => item.id === this.cacheValues?.customerId)?.customerName || '';
  }

  async loadMetaData() {
    try {
      this.loading = true;
      this.customers = await this.service.getCustomers();
      const currentLoggedInId = (await this.sessionService.getCurrentUserDetails()).profile.customerId;
      this.cacheValues = await this.setCacheValues(currentLoggedInId);
      this.selectedDate = [new Date(this.cacheValues.fromDate), new Date(this.cacheValues.toDate)];
      const customerId = this.cacheValues.customerId ? this.cacheValues.customerId : currentLoggedInId;
      this.selectedCustomer = this.customers.find((item: ICustomers) => item.id === customerId);
      this.cacheValues.customerId = this.selectedCustomer.id;
      this.cacheValues.customerType = this.selectedCustomer.customerType;
      this.dataPersistenceService.set(this.cacheValues);
      this.bots = await this.service.getBots(this.selectedCustomer);
      this.changeDef.detectChanges();
    } catch (error) {
      this.loading = false;
    }
  }

  async setCacheValues(loggedInCustomerId: string) {
    const cacheValues = await this.dataPersistenceService.get();
    if (cacheValues) {
      return (cacheValues.customerId === loggedInCustomerId ||
        this.checkPermission(this.permission.SuperAdminView)) ? cacheValues : this.structureService.setInitialValues();
    } else {
      return this.structureService.setInitialValues();
    }
  }

  checkPermission(role: string) {
    return this.permissionService.hasPermission(role);
  }

  async getAllData(filters?: IFilter[]) {
    this.filters = filters;
    this.selectedFilters = this.dataPersistenceService.getByKey(LOCAL_STORAGE_FILTER_KEY);
    this.cacheValues = this.dataPersistenceService.get();
    if (!this.cacheValues) {
      return false;
    }

    try {
      this.loading = true;
      const [
        utilization,
        inquiryStatusData,
        timeSavedResult,
        exceptionData,
        inquiryStatusStackChartData,
      ] = await this.fetch(this.cacheValues);
      this.utilization = utilization;
      this.inquiryStatusData = inquiryStatusData.result;
      this.timeSavedData = timeSavedResult.result;
      this.exceptionData = exceptionData.result;
      this.inquiryStatusStackChartData = inquiryStatusStackChartData.result;
    } catch (e) {
      this.loading = false;
    } finally {
      this.loading = false;
    }

  }

  fetch(queryParams: IRequestModel) {
    return Promise.all([
      this.service.getEmployeeUtilizationData(queryParams),
      this.service.getInquiryStatusData(queryParams),
      this.service.getTimeSavedData(queryParams),
      this.service.getExceptionData(queryParams),
      this.service.getInquiryStatusStackChartData(queryParams),
    ]);
  }

  setCurrentPageNumber(pageNumber: number) {
    this.pagination.currentPage = pageNumber;
    this.loadAllException();
  }

  async loadAllException() {
    let queryParams = this.dataPersistenceService.get();
    queryParams = {...queryParams, ...this.pagination, status: this.exceptionType };
    try {
      this.loading = true;
      this.allExceptionData = await this.service.getAllExceptionData(queryParams);
    } catch (error) {
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  async getAppStatus() {
    this.clientInfo = {
      environment: config.environmentName,
      version: config.version,
    };
    this.apiInfo = await this.dataService.get<IApiInfo>(StaticEndpoints.ApiInfo);
  }

  async export(isExportExcel: boolean) {
    try {
      this.exportLoading = true;
      this.cacheValues = await this.dataPersistenceService.get();
      const result = await this.service.exportStructure(this.cacheValues, isExportExcel);
      (result.fileUrl === null) ? alert('No data found') : (window.location.href = result.fileUrl);
    } catch (err) {
      this.exportLoading = false;
    } finally {
      this.exportLoading = false;
    }
  }

  navigatToDashboard() {
    this.router.navigate(['home']);
    this.dataPersistenceService.set(DEFAULT_CURRENT_PAGE, LOCAL_STORAGE_EXCEPTION_PAGE_NUMBER);
  }
}
