import { ICustomers, IBot, IFilter } from '@modules/Dashboard/dashboard.model';

export interface IModalData {
    botList?: IBot[];
    botName?: string;
    filters?: IFilter[];
    isModify: boolean;
    title: string;
    botDetail: IBot;
    customerDetail: ICustomers;
    attachCustomers: ICustomers[];
}

export enum SolutionType {
    RPA = 0,
    NonRPA = 1,
    RPAOrNonRPA = 2,
}
