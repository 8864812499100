import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { IValue, IFilterValue } from '@modules/Dashboard/dashboard.model';
import { MULTI_SELECT_DROPDOWN_SETTING } from '@config/constants';

@Component({
  selector: 'sf-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnChanges {

  @Input() values: IValue[];

  @Input() selectedValue = [];
  @Input() placeholder: string;
  @Input() title = '';
  public dropdownSettings = MULTI_SELECT_DROPDOWN_SETTING;
  @Output() selectChange = new EventEmitter<IFilterValue[]>();

  constructor() { }

  ngOnChanges() {
    const dropdownSettingObj = {
      text: `Select ${ this.placeholder }`,
      primaryKey: 'filterValue',
      labelKey: 'filterValue',
      enableSearchFilter:  (this.values.length) ? true : false,
      disabled: false,
    };

    this.dropdownSettings = Object.assign(this.dropdownSettings, dropdownSettingObj);
  }

  onFilterValueDeSelect(selectedValue: IFilterValue[]) {
    this.selectChange.emit(selectedValue);
  }
}
