import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IBot, ICustomers } from '@modules/Dashboard/dashboard.model';
import { SuperAdminService } from '@modules/Super-admin/Services/SuperAdminService';
import { IModalData } from '@modules/Super-admin/super-admin.model';
import { isEqual, cloneDeep } from 'lodash';
import { validationRegEx } from '@config/constants';
import { ConfirmModalComponent } from 'src/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'sf-add-modal',
  templateUrl: './add-modal.component.html',
  styleUrls: ['./add-modal.component.scss']
})
export class AddModalComponent implements OnInit {
  public bots: IBot[];
  public data: IModalData;
  public loadingFlag: boolean;
  public botSnapshot: IBot;
  public botValues: IBot;
  public modalConfig = {
    class: 'modal-dialog-centered modal-sm',
    keyboard: false,
    ignoreBackdropClick: true,
  };
  public confirmModalRef: BsModalRef;
  public manualTimeSpanRegEx = validationRegEx.timeValidationRegEx;
  constructor(public modalRef: BsModalRef, private modalService: BsModalService, private service: SuperAdminService) { }

  ngOnInit() {
    this.botValues = this.setBotValue(this.data.botDetail);
    this.botSnapshot = cloneDeep(this.botValues);
  }

  onConfirmation() {
    this.confirmModalRef = this.modalService.show(ConfirmModalComponent, { ...this.modalConfig, id: 2 });
    this.confirmModalRef.content.data = { title: 'Are you sure you want to save this?' };
    this.confirmModalRef.content.onConfirm.subscribe((result: boolean) => {
      if (result) {
        this.confirmModalRef.hide();
        this.addEditBot();
      }
    });
  }

  async addEditBot() {
    try {
      this.loadingFlag = true;
      this.botValues['manualTimeSpan'] = `00:${this.botValues.manualTimeSpan}`;
      (this.data.isModify) ?
        await this.service.updateBot(this.botValues, this.data.botDetail) :
        await this.service.createBot(this.botValues, this.data.customerDetail);
      await this.getAttachedFilterWithBot(this.data.customerDetail);
      this.botValues['manualTimeSpan'] = this.botValues.manualTimeSpan.slice(3);
      this.hide();
    } catch (err) {
    } finally {
      this.loadingFlag = false;
    }
  }

  isNameEmpty() {
    return this.botValues.botName && this.botValues.clientJobName && !(this.botValues.botRunhrs === null);
  }

  isDetailsChanged() {
    return isEqual(this.botValues, this.botSnapshot) ||
      this.isDuplicate() ||
      !this.isNameEmpty();
  }

  isDuplicate() {
    return this.data.botList.find((bot: IBot) => {
      if (!(this.botSnapshot.botName.toLowerCase() === bot.botName.toLowerCase())) {
        return bot.botName.toLowerCase() === this.botValues.botName.toLowerCase();
      }
    });
  }

  setBotValue(data: IBot) {
    return {
      botName: data && data.botName ? data.botName : '',
      clientJobName: data && data.clientJobName ? data.clientJobName : '',
      manualTimeSpan: data && data.manualTimeSpan ? data.manualTimeSpan : '00:00',
      botType: data ? data.botType : true,
      botRunhrs: data && data.botRunhrs ? data.botRunhrs : 0,
    };
  }

  async getAttachedFilterWithBot(customer: ICustomers) {
    this.bots = await this.service.getAttachedFilterWithBot(customer);
    this.service.botChange.emit(this.bots);
  }

  hide() {
    this.modalRef.hide();
  }
}
