import { Component, OnInit, Input } from '@angular/core';
import { AddModalComponent } from '../add-modal/add-modal.component';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { ActivatedRoute } from '@angular/router';
import { IBot, ICustomers, IFilter } from '@modules/Dashboard/dashboard.model';
import { SuperAdminService } from '@modules/Super-admin/Services/SuperAdminService';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { Subject } from 'rxjs';
import { TokenModalComponent } from '../token-modal/token-modal.component';
import { find } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sf-view-bot',
  templateUrl: './view-bot.component.html',
  styleUrls: ['./view-bot.component.scss']
})
export class ViewBotComponent implements OnInit {
  public modalRef: BsModalRef;
  public customers: ICustomers[];
  public customerDetail: ICustomers;
  public botDetail: IBot;
  public bots: IBot[];
  public loadingFlag = false;
  @Input() botsChange: Subject<IBot[]>;

  constructor(
    private modalService: BsModalService,
    private dashboardService: DashboardService,
    private service: SuperAdminService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    try {
      this.loadingFlag = true;
      await this.getCustomers();
      const id = this.customerId;
      this.customerDetail = find(this.customers, { id });
      this.bots = await this.service.getAttachedFilterWithBot(this.customerDetail);
      this.service.botChange.subscribe(value => {
        this.bots = value;
      });
    } catch (err) { }
    finally {
      this.loadingFlag = false;
    }
  }

  get customerId() {
    return this.route.snapshot.params.id;
  }

  async getCustomers() {
    this.customers = await this.dashboardService.getCustomers();
  }

  openModal(data?: IBot, isModify?: boolean) {
    this.modalRef = this.modalService.show(AddModalComponent, { initialState: {
      data: {
        botList: this.bots,
        botDetail: data,
        customerDetail: this.customerDetail,
        isModify: isModify,
        title: isModify ? 'Modify Bot' : ' Add Bot',
      }},
      id: 1, class: 'my-modal modal-dialog-centered modal-md',
      keyboard: false, ignoreBackdropClick: true
    });
  }

  tokenClick(data?: IFilter, modifyData?: IBot, isModify?: boolean) {
    this.modalRef = this.modalService.show(FilterModalComponent, { initialState:
      data, id: 1, class: 'my-modal modal-dialog-centered modal-md',
      keyboard: false, ignoreBackdropClick: true
    });
    this.modalRef.content.data = {
      botDetail: data,
      botName: modifyData.botName,
      filters: modifyData.filters,
      customerDetail: this.customerDetail,
      isModify: isModify,
      title: isModify ? 'Modify filter' : 'Add filter',
    };
  }

  removeModal(data?: IBot | IFilter, isBot?: boolean) {
    this.modalRef = this.modalService.show(TokenModalComponent, { initialState:
      data, class: 'my-modal modal-dialog-centered modal-md',
      keyboard: false, ignoreBackdropClick: true
    });
    this.modalRef.content.data = {
      botDetail: data,
      customerDetail: this.customerDetail,
      isBot: isBot,
      title: `Are you sure you want to delete this ${(isBot) ? `filter` : `bot`}`,
    };
  }
}
