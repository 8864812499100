import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { IDateFilter, DateRangeEnum } from '@modules/Dashboard/dashboard.model';
import { DateHelper } from '@core/utils/DateHelper';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { forEach } from 'lodash';

const CUSTOM_PERIOD = -1;

@Component({
  selector: 'sf-date-filter',
  templateUrl: 'date-filter.component.html',
  styleUrls: ['date-filter.component.scss'],
})

export class DateFilterComponent implements OnChanges {
  public maxDate: Date;
  public periodsValues: number[];
  public datePickerConfig: Partial<BsDatepickerConfig>;
  @Input() selectedDate: Date[];
  @Input() periods: IPeriods[];
  @Input() showSecondaryDateFilter = false;
  public quarterDate: number;
  public yearDate: number;
  public selectedPeriod = 'Select Date Range';
  public secondaryDateRange: IPeriods[];

  @Output() datesChange = new EventEmitter<IDateFilter>();

  constructor( ) {
    this.datePickerConfig = Object.assign({}, {
      adaptivePosition: true,
      showWeekNumbers: false,
      rangeInputFormat: 'MMM D, YYYY',
      maxDate: new Date(),
      isAnimated: true,
    });
  }

  ngOnChanges() {
    this.setPeriods();
    this.setSecondaryPeriods();
    this.periodsValues = this.periods.map(i => i.value);
    // Apply initial dates only when no dates provided
    if (this.selectedDate && this.selectedDate.length) {
      this.setCurrentPeriod(this.selectedDate);
    } else {
      this.setDaysInPeriod(7);
    }
  }

  setPeriods() {
    const quarter = Math.floor(((new Date()).getMonth() / 3));
    const firstQuarterDate = new Date((new Date()).getFullYear(), quarter * 3, 1);
    this.quarterDate = DateHelper.daysBetween(new Date(), firstQuarterDate);
    this.yearDate = DateHelper.daysBetween(new Date(), new Date(new Date().getFullYear(), 0, 1));
    this.periods = [
      { label: 'Select Date Range', value: DateRangeEnum.SelectDateRange },
      { label: 'Last Day', value: DateRangeEnum.LastDay },
      { label: 'Last 7 Days', value: DateRangeEnum.Last7Days },
      { label: 'Last 30 Days', value: DateRangeEnum.Last30Days },
      { label: 'Quarter To Date', value: this.quarterDate },
      { label: 'Year To Date', value: this.yearDate },
    ];
  }

  setSecondaryPeriods() {
    const lastMonthDate = DateHelper.daysBetween(new Date(), new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const lastYearDate = DateHelper.daysBetween(new Date(), new Date(new Date().setFullYear(new Date().getFullYear() - 1))) - 1;
    const lastTwoYearDate = DateHelper.daysBetween(new Date(), new Date(new Date().setFullYear(new Date().getFullYear() - 2))) - 1;
    this.secondaryDateRange = [
      { label: '1D', value: 1, isActive: false },
      { label: '5D', value: 5, isActive: false },
      { label: '1M', value: lastMonthDate, isActive: false },
      { label: '1Y', value: lastYearDate, isActive: false },
      { label: '2Y', value: lastTwoYearDate, isActive: false },
    ];
  }

  onPeriodChange(dateRange: IPeriods) {
    if (dateRange.value !== DateRangeEnum.SelectDateRange) {
      if (dateRange.hasOwnProperty('isActive')) {
        forEach(this.secondaryDateRange, (date: IPeriods, i: number) => {
          this.secondaryDateRange[i].isActive = (date === dateRange);
        });
      }
      this.setDaysInPeriod(dateRange.value, dateRange.label);
      this.notify();
    }
  }

  onDateChange(e: Date[]) {
    const diff = DateHelper.daysBetween(e[1], e[0]);
    if (diff > (1825 + 1)) {
      alert('You can select date within 5 years');
    } else { }
    if (e) {
      this.setCurrentPeriod(e);
      this.notify(true);
    }
  }

  setDaysInPeriod(period: number, label?: string) {
    const dates = DateHelper.daysInPeriod(new Date(), period);
    this.selectedDate = [dates.from, dates.to];
    if (!this.showSecondaryDateFilter) {
      this.setCurrentPeriod(this.selectedDate, label);
    }
  }

  setCurrentPeriod(e: Date[], label?: string) {
    const diff = DateHelper.daysBetween(e[1], e[0]);
    if (!DateHelper.isToday(e[1])) {
      this.selectedPeriod = 'Select Date Range';
      return;
    }

    if (this.periodsValues.indexOf(diff) >= 0) {
      this.selectedPeriod = label || this.periods.find(period => {
        return period.value === diff;
      }).label;
    } else {
      this.selectedPeriod = 'Select Date Range';
    }
  }

  notify(isDateCalenderOpen?: boolean) {
    this.datesChange.emit({
      startDate: this.selectedDate[0],
      endDate: this.selectedDate[1],
      isDateCalenderOpen,
    });
  }
}


export interface IPeriods {
  label: string;
  value: number;
  hidden?: boolean;
  isActive?: boolean;
}


