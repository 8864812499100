import { Injectable } from '@angular/core';
import { IChartResponseModel } from '@modules/Dashboard/dashboard.model';

@Injectable({
    providedIn: 'root'
})

export abstract class AbstractDashboardChartComponent {

    public data = [];
    public startDate = new Date();
    public endDate = new Date();
    public colors = ['#8757FE', '#86BAFF', '#FFAEAE', '#A2A2A2', '#3a2a73', '#8b5d8e', '#7cc98f', '#53dde2', '#4285f4', '#dea99d'];

    getInquiryStatusArray(data: IChartResponseModel[]) {
        if (data && data.length) {
            const keys = [];
            const obj = data[0].statusList;

            for (const k in obj) {
                if (k !== '') {
                    keys.push({ title: obj[k].status, data: [], color: this.colors[k] });
                }
            }

            for (let i = 0; i < data.length; i++) {
                const statusList = data[i].statusList;

                for (const j in statusList) {
                    if (j !== '') {
                        keys.map(records => {
                            if (records.title === statusList[j].status) {
                                records.data.push({
                                    percentage: statusList[j].percentage,
                                    result: statusList[j].result,
                                    resultCount: statusList[j].resultCount,
                                });
                            }
                        });
                    }
                } // j for ends

            } // for loop ends

            return keys;
        }

    }

}



