import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ApplicationLayoutComponent } from './layouts/application-layout/application-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { RouterConfigurationModule } from '../config/routing';
import { ApplicationPagesModule } from './pages/pages.module';
import { CoreModule } from '../core/core.module';
import { LogoutCallbackPageComponent } from './pages/logout-callback/logout-callback.page';
import { ComponentsModule } from 'src/components/components.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogoutLayoutComponent } from './layouts/logout-layout/logout-layout.component';
import { APIRequestInterceptor } from '../core/services/APIRequestInterceptor';
import { AccessDeniedPageComponent } from './pages/access-denied/access-denied.page';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    ApplicationLayoutComponent,
    LoginLayoutComponent,
    LogoutCallbackPageComponent,
    LogoutLayoutComponent,
    AccessDeniedPageComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    ApplicationPagesModule,
    RouterConfigurationModule,
    ComponentsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: APIRequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
