<div class="modal-body text-center mt-3 py-3 px-4 border-0">
    <!-- Bot Selection  -->
    <div class="btn-group border w-100 dropdown-section" dropdown>
        <button id="bot" dropdownToggle type="button" class="col-sm btn btn-md dropdown-toggle text-left custom-input" aria-controls="date-range" [title]="botName">
            {{botName | truncateInside: 45}} <i class="mt-2 float-right dropdown-arrow down"></i>
        </button>
        <ul id="bot" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="bot">
            <li role="menuitem" *ngFor="let bot of data.botList">
                <a class="dropdown-item" href="javascript:void(0)" (click)="selectBot(bot)" [title]="bot.botName">
                    {{bot.botName | truncateInside: 45}}
                </a>
            </li>
        </ul>
    </div>

    <!-- Process Selection -->
    <div class="d-flex align-items-end">
        <div class="btn-group border w-100 mt-3 dropdown-section" dropdown [isDisabled]="!processList.length">
            <button id="process" dropdownToggle type="button" class="col-sm btn btn-md dropdown-toggle text-left custom-input" aria-controls="date-range" [title]="processName">
                {{processName | truncateInside: 45}} <i class="mt-2 float-right dropdown-arrow down"></i>
            </button>
            <ul id="process" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="process">
                <li role="menuitem" *ngFor="let process of processList">
                    <a class="dropdown-item" href="javascript:void(0)" (click)="selectProcess(process)">
                        {{process.processName | truncateInside: 45}}
                    </a>
                </li>
            </ul>
        </div>
        <span class="ml-2 reset-icon" (click)="resetProcess()" *ngIf="employee && employee.masterProcessId" title="Reset Process"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
    </div>

    <!-- Event Selection -->
    <div class="d-flex align-items-end">
        <div class="btn-group border w-100 mt-3 dropdown-section" dropdown [isDisabled]="!eventList.length">
            <button id="event" dropdownToggle type="button" class="col-sm btn btn-md dropdown-toggle text-left custom-input" aria-controls="date-range" [title]="eventName">
                {{eventName | truncateInside: 45}} <i class="mt-2 float-right dropdown-arrow down"></i>
            </button>
            <ul id="event" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="event">
                <li role="menuitem" *ngFor="let event of eventList">
                    <a class="dropdown-item" href="javascript:void(0)" (click)="selectEvent(event)">
                        {{event.displayName | truncateInside: 45}}
                    </a>
                </li>
            </ul>
        </div>
        <span class="ml-2 reset-icon" (click)="resetEvent()" *ngIf="employee && employee.masterEventId" title="Reset Event"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
    </div>

    <div class="filter-section" [ngStyle]="{'min-height': (loadingFilter) ? '5em' : '0em'}">
        <sf-filters-section
            *ngIf="filters && !loadingFilter; else loader"
            [filters]="filters"
            [employee]="employee"
            [bots]="data.botList"
            [selectedDefaultValue]="selectedDefaultValue"
            [selectedFilter]="selectedFilter"
            (subDefaultFilterChanges)="getSubFilter($event)"
            (subFilterChanges)="getFilter($event)"
        ></sf-filters-section>
        <ng-template #loader>
            <sf-loading [loadingFilter]="loadingFilter"></sf-loading>
        </ng-template>
    </div>

</div>
<div class="modal-footer text-right p-3 border-0">
    <button class="btn btn-md font-weight-bold cancel-btn" (click)="hide()">Cancel</button>
    <button class="btn btn-md save-btn" (click)="apply()">Apply</button>
</div>