<div class="d-flex" *ngIf="!showSecondaryDateFilter">
    <div class="date-picker border dropdown-section">
        <input type="text" #drp="bsDaterangepicker" class="col-sm btn btn-md dropdown-toggle" bsDaterangepicker [(ngModel)]="selectedDate" [bsConfig]="datePickerConfig" [readonly]="true" (ngModelChange)="onDateChange($event)">
        <i class="calender dropdown-arrow down"></i>
    </div>
    <div class="btn-group border ml-2 dropdown-section" dropdown>
        <button id="date-range" dropdownToggle type="button" class="col-sm btn btn-md dropdown-toggle custom-input" aria-controls="date-range" [title]="selectedPeriod">
            {{selectedPeriod}} <i class="dropdown-arrow down"></i>
        </button>
        <ul id="date-range" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="date-range">
            <li role="menuitem" *ngFor="let period of periods">
                <a class="dropdown-item" href="javascript:void(0)" (click)="onPeriodChange(period)" *ngIf="period.value !== 0">
                    {{period.label}}
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="secondary-date-range d-flex justify-content-end" *ngIf="showSecondaryDateFilter">
    <button *ngFor="let dateRange of secondaryDateRange" (click)="onPeriodChange(dateRange)" type="button" class="btn btn-sm" [ngClass]="{ 'active': dateRange.isActive }">{{dateRange.label}}</button>
</div>