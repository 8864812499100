import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class DateHelper {

  constructor(private datePipe: DatePipe) { }

  static clone(date: Date) {
    return new Date(date.valueOf());
  }

  static daysBefore(date: Date, days: number) {
    const result = new Date();
    result.setDate(date.getDate() - days);
    return result;
  }

  static daysAfter(date: Date, days: number) {
    const result = new Date();
    result.setDate(date.getDate() + days);
    return result;
  }

  static daysBetween(date1: Date, date2: Date) {
    date1 = this.clone(date1);
    date2 = this.clone(date2);
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    return Math.round((date1.valueOf() - date2.valueOf()) / (1000 * 60 * 60 * 24));
  }


  static shortFormat(date: Date) {
    date = new Date(date);
    return date.toISOString().split('T')[0];
  }

  static isToday(date: Date) {
    const today = this.shortFormat(new Date());
    return this.shortFormat(date) === today;
  }

  static daysInPeriod(to: Date, period: number) {
    return {
      from: DateHelper.daysBefore(to, period),
      to,
    };
  }

  generateDateFilter(period: number) {
    const defaultDates = DateHelper.daysInPeriod(new Date(), period);
    return {
      startDate: defaultDates.from,
      endDate: defaultDates.to,
    };
  }

  toServer(date: any, format: string = 'yyyy-MM-dd'): string {
    return this.datePipe.transform(new Date(date), format);
  }

}

