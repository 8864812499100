import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sf-super-admin',
  templateUrl: './super-admin.page.html',
  styleUrls: ['./super-admin.page.scss']
})
export class SuperAdminPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
