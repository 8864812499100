<sf-loading [loadingFlag]="loading" [ngClass]="(hideAllException) ? 'main-page-loder' : 'all-exception-loader'"></sf-loading>

<sf-header [subHeader]="subHeader" [cacheValues]="cacheValues" [hideAllException]="hideAllException" [bots]="bots" [customers]="customers" (filtersChange)='getAllData($event)'></sf-header>
<div class="container-fluid" [ngClass]="(hideAllException) ? 'p-0-5' : 'p-0-3'">
    <p class="customer-name mt-4 mb-0">{{getCustomerName()}}</p>
    <div class="row py-2 border-bottom d-flex justify-content-between align-items-center mx-auto">
        <div>
            <h6 class="font-weight-bold section-head"><span><a href="javascript:void(0)" (click)="navigatToDashboard()" [ngClass]="{'only-text' : hideAllException}">Dashboard</a></span> <span *ngIf="!hideAllException"> > {{ getExceptionType }}</span></h6>
        </div>
        <div class="border dropdown-section" *ngIf="hideAllException">
            <div class="btn-group w-100" dropdown>
                <button id="date-range" dropdownToggle type="button" class="col-sm btn btn-md dropdown-toggle export-btn" aria-controls="date-range" [disabled]="exportLoading">
                    <span *ngIf="!exportLoading; else exportLoader">Export <i class="dropdown-arrow down"></i></span>
                    <ng-template #exportLoader>
                        <img class="export-loader-img" src="assets/images/35.gif"/>
                    </ng-template>
                </button>
                <ul id="date-range" *dropdownMenu class="dropdown-menu dropdown-option" role="menu" aria-labelledby="date-range">
                    <li role="menuitem" *ngFor="let extension of exportFileExtensions">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="export(extension.isExportExcel)">{{extension.extensionName}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div #kpiSection class="kpi-section" *ngIf="hideAllException" (scroll)="onWindowScroll($event);">
        <sf-sub-header #subHeader [bots]="bots" [selectedDate]="selectedDate" [filters]="filters" (filtersChange)="getAllData($event)" (emitLoadingState)="loading = $event"></sf-sub-header>
        <div class="row border mt-4 d-flex align-items-center mx-auto">
            <div class="col-12" #kpi>
                <sf-key-performance-indicator [filters]="filters" [utilization]="utilization" [timeSavedData]="timeSavedData" [inquiryStatusData]="inquiryStatusData"></sf-key-performance-indicator>
            </div>
        </div>
    </div>
    
    <div *ngIf="hideAllException">
        <div class="row mt-4">
            <div class="col-md-4">
                <div class="col-md-12 border chart-box">
                    <sf-inquiry-status [inquiryStatusData]="inquiryStatusData"></sf-inquiry-status>
                </div>
            </div>
            <div class="col-md-8 mt-3 mt-sm-0">
               <div class="col-md-12 border chart-box">
                    <sf-inquiry-status-trends [inquiryStatusTrendData]="inquiryStatusStackChartData"></sf-inquiry-status-trends>
               </div>
            </div>
        </div>
    
        <div class="row mt-4" *ngIf="!loading">
            <div [ngClass]="(hasMasterEventId) ? 'col-md-12' : 'col-md-8'">
                <div class="col-md-12 border chart-box">
                    <sf-inquiry-status-overview [inquiryStatusOverviewData]="inquiryStatusStackChartData"></sf-inquiry-status-overview>
                </div>
             </div>
             <div class="col-md-4 mt-3 mt-sm-0" *ngIf="!hasMasterEventId">
                <div class="col-md-12 border chart-box">
                    <sf-inquiry-breakdown [filters]="filters" [headerFilters]="selectedFilters"></sf-inquiry-breakdown>
                </div>
             </div>
        </div>
    
        <div class="row mt-4" *ngIf="exceptionData && !loading">
            <div class="col-12 col-md-4 mt-3 mt-sm-0">
                <sf-hand-over [exceptionData]="exceptionData.businessException"></sf-hand-over>
            </div>
            <div class="col-12 col-md-4 mt-3 mt-sm-0">
                <sf-exception [exceptionData]="exceptionData.exception"></sf-exception>
            </div>
            <div class="col-12 col-md-4 mt-3 mt-sm-0">
                <sf-system-failure [exceptionData]="exceptionData.applicationException"></sf-system-failure>
            </div>
        </div>
    </div>
    <div class="row mt-5 mx-auto" *ngIf="!hideAllException && allExceptionData">
        <div class="col-12 col-md-12">
            <sf-all-exceptions [exceptionData]="allExceptionData" [pagination]="pagination" (paginationChange)="setCurrentPageNumber($event)"></sf-all-exceptions>
        </div>
    </div>
</div>

<footer class="footer col-12">
    <div class="container-fluid">
        <div class="col-md-12 col-sm-12 text-center row" *ngIf="apiInfo">
            <div class="col-md-6">
                <span class="badge">API: <strong>Admin API</strong></span>
                <span class="badge ml-1">Version: <strong>{{ apiInfo.applivationVersion }}</strong></span>
                <span class="badge ml-1">Environment: <strong>{{ apiInfo.aspEnvironment }}</strong></span>
                <span class="badge ml-1">Status: <strong>{{ apiInfo.status }}</strong></span>
                <hr class="d-block d-sm-none">
            </div>
            <div class="col-md-6">
                <span class="badge">Client application: <strong>Admin</strong></span>
                <span class="badge ml-1">Version: <strong>{{ clientInfo.version }}</strong></span>
                <span class="badge ml-1">Environment: <strong>{{ clientInfo.environment }}</strong></span>
            </div>
        </div>
    </div>
</footer>
