<sf-loading [loadingFlag]="loadingFlag"></sf-loading>
<div class="modal-header align-items-center" *ngIf="data">
    <h6 class="modal-title pull-left">{{data.title}}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body text-center pt-1" *ngIf="data">
    <form class="p-1" #customerForm="ngForm">
        <div class="form-row" *ngIf="!data.isModify">
            <div class="form-group col mb-2">
                <div class="row">
                    <div class="col-sm-5 text-left">Customer Name</div>
                    <div class="col-sm-7 pl-0 text-right btn-group w-100 inquiry-filter" dropdown>
                        <button id="select-de" dropdownToggle type="button" class="btn dropdown-toggle text-center" aria-controls="select-de" title="{{(selectedCustomer === DEFAULT_CUSTOMER_NAME) ? '' :  selectedCustomer }}">
                            {{selectedCustomer}}
                        <span class="caret"></span>
                        </button>
                        <ul id="select-de" *dropdownMenu class="dropdown-menu option" role="menu" aria-labelledby="select-de">
                            <li role="menuitem" *ngFor="let attachedCustomer of attachedCustomer; let index = i">
                                <a class="dropdown-item" href="javascript:void(0)" (click)="selectCustomer(attachedCustomer, i)">{{attachedCustomer.customerName}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row" *ngIf="data.isModify">
            <div class="form-group col mb-2">
                <div class="row align-items-center">
                    <div class="col-sm-5 text-left">Customer Name</div>
                    <div class="col pl-0">
                        <input type="text" name="customerName" class="form-control" [disabled]="!isModify" [(ngModel)]="customerValue.customerName" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row row">
            <div class="form-group col mb-2">
                <div class="row align-items-center">
                    <div class="col-sm-5 text-left">Database Name</div>
                    <div class="col pl-0">
                        <input type="text" name="databaseName" class="form-control" [disabled]="data.isModify" [(ngModel)]="customerValue.databaseName" autocomplete="off" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row row" *ngIf="data.isModify && hasRpaCustomer">
            <div class="form-group col mb-2">
                <div class="row align-items-center">
                    <div class="col-sm-5 text-left">RPA Database Name</div>
                    <div class="col pl-0">
                        <input type="text" name="rpaDatabaseName" class="form-control" [disabled]="data.isModify" [(ngModel)]="customerValue.rpaDatabaseName" autocomplete="off" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col mb-2">
                <div class="row align-items-center">
                    <div class="col-sm-5 text-left">Solution Type</div>
                    <div class="col-sm-7 text-left pl-0">
                        <mat-radio-group name="customerType" color="primary" labelPositon="after" [(ngModel)]="customerValue.customerType">
                            <mat-radio-button *ngFor="let option of solutionTypes" [value]="option.value" class="d-block">{{option.label}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row row" *ngIf="hasRpaCustomer">
            <div class="form-group col mb-2">
                <div class="row align-items-center">
                    <div class="col-sm-5 text-left">RPA Allocated Hours</div>
                    <div class="row col-sm-7 pl-0">
                        <div class="col-sm-6">
                            <input #rpaAllocatedHrsModel="ngModel" type="number" name="rpaAllocatedHrs" [pattern]="numberPattern" class="form-control" min="1" [(ngModel)]="customerValue.rpaAllocatedHours" autocomplete="off" />
                        </div>
                        <div class="col mt-1">
                            <span class="hour-text">Hours/Day</span>
                        </div>
                        <span *ngIf="rpaAllocatedHrsModel.invalid" class="text-danger ml-3"><small>Number must be greater than 0</small></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col mb-2">
                <div class="row align-items-center">
                    <div class="col-sm-5 text-left process-time-label">Manual Inquiry Processing Time</div>
                    <div class="col-sm-7 text-left d-flex align-items-center pl-0">
                        <mat-radio-group name="processLevel" color="primary" labelPositon="after" [(ngModel)]="customerValue.isManualInquiryAtJobLevel">
                            <mat-radio-button [value]="true">Job Level</mat-radio-button>
                            <mat-radio-button [value]="false" class="ml-2">Process Level</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <div class="row text-left align-items-center timezone">
                    <div class="col-sm-5">Customer Timezone</div>
                    <div class="col-sm-7 pl-0">
                        <input #timezone 
                            [(ngModel)]="customerValue.timezoneDisplayName"
                            [typeahead]="timeZones"
                            [typeaheadMinLength]="0"
                            typeaheadOptionField="displayName"
                            [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="5"
                            [typeaheadOptionsLimit]="timeZones.length"
                            (typeaheadOnSelect)="onTimezoneSelect($event)"
                            placeholder="Select Timezone"
                            name="timezone"
                            class="form-control"
                        >
                        <span *ngIf="customerValue.timezoneDisplayName" class="rest-timezone" tooltip="Clear" (click)="onTimezoneReset()">x</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-left m-0 pt-2">
            <mat-checkbox id="isActive" [(ngModel)]="!customerValue.isActive" (change)="onOptionChange($event)" name="isActive" color="primary">
                Inactive this customer
            </mat-checkbox>
        </div>
        <div class="row pull-right m-1">
            <button type="button" class="btn save-btn mr-1" [disabled]="isDetailsChanged() || !isValidTimeZone() || !customerForm.valid" (click)="onConfirmation()">{{ data.isModify ? 'Save': 'Create'}}</button>
            <button type="button" class="btn disable-btn" (click)="hide()">Cancel</button>
        </div>
    </form>
    <div class="text-left text-danger">
        <span *ngIf="!isNameEmpty();">All fields required</span>
        <span class="d-block" *ngIf="!isValidTimeZone();">Invalid timezone</span>
    </div>
</div>