<sf-loading [loadingFlag]="loadingFlag"></sf-loading>
<div class="modal-header align-items-center" *ngIf="data">
    <h6 class="modal-title pull-left">{{data.title}}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body text-center" *ngIf="data">
    <form class="p-1">
        <div class="form-row">
            <div class="form-group col">
                <div class="row">
                    <div class="col-sm-5 text-left">Bot Name</div>
                    <div class="col">
                        <input type="text" name="botName" class="form-control" [(ngModel)]="botValues.botName" autocomplete="off" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <div class="row" [class.disabled]="data.isModify">
                    <div class="col-sm-5 text-left">Job Name</div>
                    <div class="col">
                        <input type="text" name="jobName" class="form-control timeInput" [disabled]="data.isModify" [(ngModel)]="botValues.clientJobName" autocomplete="off" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <div class="row">
                    <div class="col-sm-5 text-left">Bot type</div>
                    <div class="col-sm-5 text-left">
                        <input type="radio" [value]="true" name="botType" id="rpa" [(ngModel)]="botValues.botType"> RPA
                        <input type="radio" [value]="false" name="botType" class="ml-2" id="non-rpa" [(ngModel)]="botValues.botType"> NON-RPA
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col text-right m-1" *ngIf="data">
            <button type="button" class="btn save-btn mr-1" (click)="onConfirmation()" [disabled]="isDetailsChanged()">{{ data.isModify ? 'Save': 'Add'}}</button>
            <button type="button " class="btn disable-btn" (click)="hide()">Cancel</button>
        </div>
    </div>
    <div class="text-left text-danger">
        <span *ngIf="!isNameEmpty(); else duplicate">All fields required</span>
        <ng-template #duplicate>
            <span *ngIf="isDuplicate()">Bot Name is already exist</span>
        </ng-template>
    </div>
</div>