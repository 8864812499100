import { Component, OnInit } from '@angular/core';
import { IModalData } from '@modules/Super-admin/super-admin.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'sf-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  public data: IModalData;
  public onConfirm = new Subject<boolean>();

  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {}

  confirm() {
    this.onConfirm.next(true);
    this.hide();
  }

  decline() {
    this.onConfirm.next(false);
    this.hide();
  }

  hide() {
    this.modalRef.hide();
  }

}
