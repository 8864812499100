<div class="d-flex align-items-center">
    <h6 class="text-left heading">Inquiry Status</h6>
</div>

<sf-doughnut-chart [doughnutChartLegends]="doughnutChartLegends" *ngIf="doughnutChartLegends && doughnutChartLegends.length ; else showNoRecords"></sf-doughnut-chart>
<ng-template #showNoRecords>
    <h6 class="heading no-record-text text-center text-danger">
        {{(botId) ? 'No Records Found \n Please try a different combination':' Please select a process to view performance'}}
    </h6>
</ng-template>
