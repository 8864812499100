import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { DashboardService } from './Services/DashboardService';
import { ComponentsModule } from 'src/components/components.module';
import { HeaderComponent } from './component/header/header.component';
import { InquiryStatusComponent } from './component/inquiry-status/inquiry-status.component';
import { HandOverComponent } from './component/exception/hand-over/hand-over.component';
import { SystemFailureComponent } from './component/exception/system-failure/system-failure.component';
import { ExceptionComponent } from './component/exception/exception/exception.component';
import { SubHeaderComponent } from './component/sub-header/sub-header.component';
import { DashboardMapper } from './Services/DashboardMapper';
import { InquiryBreakdownComponent } from './component/inquiry-breakdown/inquiry-breakdown.component';
import { AcknowledgeModalComponent } from '@modules/Dashboard/component/acknowledge-modal/acknowledge-modal.component';
import { DashboardFilterFactory } from './Services/DashboardFilterFactory';
import { KeyPerformanceIndicatorComponent } from './component/key-performance-indicator/key-performance-indicator.component';
import { DashboardStructureService } from './Services/DashboardStructureService';
import { EmployeeSelectionModalComponent } from './component/employee-selection-modal/employee-selection-modal.component';
import { InquiryStatusTrendsComponent } from './component/inquiry-status-trends/inquiry-status-trends.component';
import { InquiryStatusOverviewComponent } from './component/inquiry-status-overview/inquiry-status-overview.component';
import { FiltersSectionComponent } from './component/employee-selection-modal/filters-section/filters-section.component';
import { AllExceptionsComponent } from './component/exception/all-exceptions/all-exceptions.component';
import { NotificationModalComponent } from './component/header/notification-modal/notification-modal.component';
import { DashboardFormBuilder } from './Services/DashboardFormBuilder';

@NgModule({
  declarations: [
    HeaderComponent,
    SubHeaderComponent,
    InquiryStatusComponent,
    InquiryBreakdownComponent,
    KeyPerformanceIndicatorComponent,
    HandOverComponent,
    SystemFailureComponent,
    ExceptionComponent,
    AcknowledgeModalComponent,
    EmployeeSelectionModalComponent,
    InquiryStatusTrendsComponent,
    InquiryStatusOverviewComponent,
    FiltersSectionComponent,
    AllExceptionsComponent,
    NotificationModalComponent,
  ],
  imports: [
    CoreModule,
    ComponentsModule,
  ],
  exports: [
    HeaderComponent,
    SubHeaderComponent,
    InquiryStatusComponent,
    InquiryBreakdownComponent,
    KeyPerformanceIndicatorComponent,
    HandOverComponent,
    SystemFailureComponent,
    ExceptionComponent,
    InquiryStatusTrendsComponent,
    InquiryStatusOverviewComponent,
    FiltersSectionComponent,
    AllExceptionsComponent,
  ],
  providers: [
    DashboardService,
    DashboardMapper,
    DashboardFilterFactory,
    DashboardStructureService,
    DashboardFormBuilder,
  ],
  entryComponents: [AcknowledgeModalComponent, EmployeeSelectionModalComponent, NotificationModalComponent],
})
export class DashboardModule { }
