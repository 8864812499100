export enum Endpoints {
  Self = 'self',
  Profile = 'user',
  GetPermissions = 'permission_getinitpermission',
  // customer
  GetAttachedCustomers = 'customer_getattachedcustomertoproduct',
  GetCustomers = 'customer_getcustomers',
  GetBotsProcessEvent = 'dashboard_getbotsprocessevent',
  GetAttachedFilterWithBot = 'bot_getattachedfilterwithbot',
  UpdateCustomer = 'customer_saveCustomer',
  DeleteCustomer = 'customer_deleteCustomer',
  GetDeletedCustomersList = 'customer_getdeletecustomerlist',
  RestoreCustomer = 'customer_restore',
// bots
  GetSingleBot = 'bot_getsinglebot',
  UpdateBot = 'bot_update',
  DeleteBot = 'bot_delete',
  CreateBot = 'bot_create',
  GetBotDetails = 'dashboard_getbotdetails',
// filters and setting
  GetFilters = 'filter_getfilters',
  SaveFilter = 'filter_create',
  UpdateFilter = 'filter_update',
  DeleteFilter = 'filter_delete',
  GetSettings = 'setting_getsettings',
  UpdateSettings = 'setting_savesetting',

  GetInquiryStatus = 'dashboard_getperformance',
  GetInquiryStatusStackChart = 'dashboard_getstackchart',
  GetSavedTime = 'dashboard_gettimesaved',
  GetBotSource = 'dashboard_getbotsource',
  GetBotAverageProcessingTime = 'dashboard_getbotaverageprocessingtime',
  GetExceptions = 'dashboard_getexceptionfailure',
  GetInquiries = 'dashboard_getinquiries',
  GetProcessedInquiries = 'dashboard_getprocessinquiries',
  GetPerformanceChart = 'dashboard_getperformancechart',
  GetEmployeeUtilization = 'dashboard_getutilization',
  GetInquiryBreakdownChart = 'dashboard_getinquirybreakdown',
  GetSourceChart = 'dashboard_getsourcechart',
  GetTimeSavedChart = 'dashboard_getbottimesavedchart',
  GetAvgProcessingChart = 'dashboard_getbotaverageprocessingchart',
  GetInquiriesChart = 'dashboard_getinquirieschart',
  GetProcessInquiriesChart = 'dashboard_getprocessinquirieschart',
  GetVessel = 'dashboard_getvessel',
  GetReport = 'report_getreport',
  GetAllExceptions = 'dashboard_getallexception',

  // notification
  GetNotification = 'emailnotification_getemailnotification',
  UpdateNotificationStatus = 'emailnotification_updatereadnotification',

  // email alert
  GetEmailAlerts = 'emailalert_getemailalert',
  CreateEmailAlert = 'emailalert_saveemailalert',
  DeleteEmailAlert = 'emailalert_delete',
}

export enum StaticEndpoints {
  Root = '/',
  ApiInfo = '/info',
  Login = '/security/token',
  Logout = '/security/logout',
}


