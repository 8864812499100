import { NgModule } from '@angular/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { TokensComponent } from './tokens/tokens.component';
import { NestedDropdownComponent } from './nested-dropdown/nested-dropdown.component';
import { TruncateInsidePipe } from '@core/pipes/truncate-inside-pipe';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  declarations: [
    BarChartComponent,
    LineChartComponent,
    DoughnutChartComponent,
    ModalFooterComponent,
    LoadingComponent,
    TokensComponent,
    NestedDropdownComponent,
    TruncateInsidePipe,
    DateFilterComponent,
    DropdownMenuComponent,
    MenuItemComponent,
    FilterListComponent,
    ConfirmModalComponent,
    PaginationComponent,
  ],
  imports: [
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatChipsModule,
    MatFormFieldModule,
  ],
  exports: [
    BsDropdownModule,
    BsDatepickerModule,
    TooltipModule,
    ModalModule,
    TabsModule,
    TypeaheadModule,
    AngularMultiSelectModule,
    BarChartComponent,
    LineChartComponent,
    DoughnutChartComponent,
    ModalFooterComponent,
    LoadingComponent,
    TokensComponent,
    NestedDropdownComponent,
    TruncateInsidePipe,
    DateFilterComponent,
    DropdownMenuComponent,
    MatMenuModule,
    MatIconModule,
    MenuItemComponent,
    MatDialogModule,
    FilterListComponent,
    MatCheckboxModule,
    MatRadioModule,
    ConfirmModalComponent,
    PaginationModule,
    PaginationComponent,
    AlertModule,
    MatChipsModule,
    MatFormFieldModule,
  ],
  entryComponents: [ConfirmModalComponent],
})
export class ComponentsModule {}
