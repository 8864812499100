import { Component, ViewChild, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import * as echarts from 'echarts';
import { IChartResponseModel, IChartModel, IChartData, IChartResultCount, IExceptionTable } from '@modules/Dashboard/dashboard.model';
import { AbstractDashboardChartComponent } from '../abstract-dashboard-chart/abstract-dashboard-chart.component';
import { map, forEach, compact, findLastIndex, findIndex, cloneDeep, find } from 'lodash';
import { EChartOption, ECharts } from 'echarts';

@Component({
  selector: 'sf-bar-chart',
  templateUrl: 'bar-chart.component.html',
  styleUrls: ['bar-chart.component.scss'],
})

export class BarChartComponent extends AbstractDashboardChartComponent implements OnChanges {

  public myChart: ECharts;
  public chartOption: EChartOption;
  @Input() chartData: IChartModel;
  @Input() showKpiLine = true;
  @Input() isParetoChart = false;
  @Input() chartHeight = 390;
  public seriesData = [];

  @ViewChild('stackedBar', { static: true }) stackedBar: ElementRef;
  public formatter = [];
  public legend: string[] | string;
  public kpi = [];
  public xPointLables: string[];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.myChart.resize();
  }

  ngOnChanges() {
    if (this.chartData && this.chartData['chart'].length || this.chartData['chart']) {
      this.setLineChartData(this.chartData['chart']);
    }
  }

  async setLineChartData(chartModel: IChartResponseModel[] | IExceptionTable[]) {

    this.data = chartModel;
    const isParetoChart = this.isParetoChart;

    if (!this.data.length) {
      return;
    }

    const chartLegendsData = (isParetoChart) ? this.data : await this.getInquiryStatusArray(this.data);

    if (!isParetoChart) {
      this.legend = chartLegendsData.map((value: IChartData, index: number) => {
        let hasFalsyValues = true;
        for (let i = 0; i < value.data.length; i++) {
          if (value.data[i].percentage) {
            hasFalsyValues = false;
            break;
          }
        }
        return (!hasFalsyValues) ? chartLegendsData[index].title : '';
      });
      this.legend.push('Target Success');
    }

    this.initializeChart(chartLegendsData);

    this.myChart = echarts.init(this.stackedBar.nativeElement as HTMLDivElement, {}, { height : this.chartHeight });
    this.myChart.setOption(this.chartOption, true);

    // on legend click
    const chartLegendsDataSnapshot = cloneDeep(chartLegendsData);
    this.myChart.on('legendselectchanged', (params: any) => {
      if (params.name !== 'Target Success') {
        chartLegendsDataSnapshot[findIndex(chartLegendsData, item => item.title === params.name)].data =
          params.selected[params.name] ? find(chartLegendsData, item => item.title === params.name).data : [];

        this.initializeChart(chartLegendsDataSnapshot);

        this.myChart.setOption(this.chartOption, true);
        for (const key in params.selected) {
          if (params.selected.hasOwnProperty(key)) {
            this.myChart.dispatchAction({
              type: (params.selected[key]) ? 'legendSelect' : 'legendUnSelect',
              name: key,
            });
          }
        }
      }
    });
  }

  initializeChart(chartLegendDataModel: IChartData[]) {
    this.generateChartData(chartLegendDataModel);
    this.generateChartOption();
    if (!this.isParetoChart) {
      this.setBarBorderRadius(this.chartOption);
    }
  }

  generateChartData(chartLegendsData: IChartData[]) {
    if (this.showKpiLine) {
      for (let point = 0; point < this.data.length; point++) {
        (this.isParetoChart) ? this.kpi.push(this.data[point].cummulativePercentage) : this.kpi.push(this.chartData['kpi']);
      }
    }

    if (this.isParetoChart) {
      const chartResult = map(chartLegendsData, (model: IExceptionTable) => model.errorMessageCount);
      this.formatter[0] = map(chartLegendsData, (model: IExceptionTable) => model.percentage);
      this.seriesData = [{
        name: 'Exception',
        type: 'bar',
        stack: 'data',
        animationDuration: 2500,
        data: chartResult,
        barWidth: 10,
        itemStyle: {
          color: '#8C54FF',
          barBorderRadius: 20,
        },
      }];
    } else {
      this.seriesData = chartLegendsData.map((value: IChartData, index: number) => {
        if (value) {
          const chartResult = map(value.data, (model: IChartResultCount) => model.percentage);
          const formatterData = map(value.data, (model: IChartResultCount) => model.resultCount);
          if (compact(formatterData).length) {
            this.formatter[index] = formatterData;
          }
          if (this.legend[index]) {
            return {
              name: chartLegendsData[index].title,
              type: 'bar',
              stack: 'data',
              animationDuration: 2500,
              data: chartResult,
              barWidth: 10,
              itemStyle: {
                color: chartLegendsData[index].color,
              },
            };
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
    }

    this.seriesData[this.seriesData.length] = {
      name: 'Target Success',
      type: 'line',
      data: this.kpi,
      color: 'red',
      yAxisIndex: (this.isParetoChart) ? 1 : 0,
      lineStyle: {
        normal: {
          width: 2,
          type: (!this.isParetoChart) ? 'solid' : 'dashed',
          color: 'red'
        }
      },
    };
  }

  generateChartOption() {
    const formatter = this.formatter;
    const isParetoChart = this.isParetoChart;

    if (isParetoChart) {
      this.xPointLables = this.data.map((value: IExceptionTable) => value.errorMessageText);
    } else {
      this.xPointLables = this.data.map((value: IChartResponseModel) => value.xPointLabel);
    }

    if (this.showKpiLine) {
      for (let point = 0; point < this.data.length; point++) {
        (isParetoChart) ? this.kpi.push(this.data[point].cummulativePercentage) : this.kpi.push(this.chartData['kpi']);
      }
    }

    this.chartOption = {
      tooltip: {
        trigger: (this.isParetoChart) ? 'item' : 'axis',
        axisPointer: {
          type: (this.isParetoChart) ? 'cross' : 'shadow',
          label: {
            backgroundColor: '#6a7985',
          },
          crossStyle: {
            type: 'dotted',
            opacity: 1,
          },
        },
        formatter: function (params: any) {
          const tooltipLabels = [];
          const tooltipData = compact(formatter);
          if (isParetoChart) {
            const isTargetSuccess = (params.seriesName === 'Target Success');
            if (params.seriesName) {
              tooltipLabels[0] = `${params.marker}
                ${(isTargetSuccess) ? `${params.value.toFixed(2)}%` : `${tooltipData[0][params.dataIndex].toFixed(2) || 0}%`}${(isTargetSuccess) ? `` : `, ${params.value}`}
              `;
            }
          } else {
            forEach(params, (model, i: number) => {
              if (model.seriesName && params[i].value !== 0) {
                const br = (i !== 0 && tooltipLabels.length) ? '</br>' : '';
                tooltipLabels[i] = `${br} ${params[i].marker}
                  ${params[i].value}%${(model.seriesName === 'Target Success') ? `` :
                  `, ${(tooltipData.length) ? tooltipData[params[i].seriesIndex][params[i].dataIndex] || 0 : 0}`}
                `;
              }
            });
          }
          return tooltipLabels.join('');
        },
      },
      legend: {
        show: !this.isParetoChart,
        data: (this.isParetoChart) ? ['Exception'] : compact(this.legend),
        icon: 'circle',
        right: 23,
        padding: [16, 0, 0, 0],
        selectedMode: true,
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: {
        type: 'category',
        data: this.xPointLables,
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(90, 104, 114, 0.15)',
            type: 'solid',
            width: 1,
          },
        },
        axisLabel: {
          rotate: (this.isParetoChart) ? 45 : 0,
          color: '#212529',
          padding: [10, 0, 0, 0],
          margin: 12,
          formatter: function (value: string) {
            return (value.length > 10 ? `${value.slice(0, 10)}...` : value );
          },
        },
        axisLine: {
          lineStyle: {
            color: '#fff',
          }
        },
      },
      yAxis: [{
        type: 'value',
        axisPointer: {
          show: false,
        },
        splitNumber: 5,
        name: (this.isParetoChart) ? 'Inquiry Count' : '%',
        nameGap: 20,
        max: (this.isParetoChart) ? null : 100,
        nameTextStyle: {
          color: '#272b2f',
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#272b2f',
        },
        axisLine: {
          lineStyle: {
            color: '#edeef0',
          }
        },
      },
      {
        type: 'value',
        show: this.isParetoChart,
        axisPointer: {
          show: false,
        },
        min: 0,
        max: 100,
        splitNumber: 10,
        name: 'Cumulative %',
        nameGap: 20,
        nameTextStyle: {
          color: '#272b2f',
          padding: [0, 0, 0, 15],
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#272b2f',
        },
        axisLine: {
          lineStyle: {
            color: '#edeef0',
          }
        },
      }],
      series: this.seriesData,
    };
  }

  setBarBorderRadius(chartOption) {
    const ChartSeriesDataLength = 12;
    for (let i = 0; i <= ChartSeriesDataLength - 1; i++) {
      const data = [];
      for (let j = 0; j < chartOption.series.length; j++) {
        if (chartOption.series[j] && chartOption.series[j].name !== 'Target Success') {
          data.push(chartOption.series[j].data[i]);
        } else {
          data.push(null);
        }
      }
      const count = data.filter(item => item > 0);

      if (count.length) {
        if (count.length === 1) {
          const index = data.findIndex(item => item && item > 0);
          chartOption.series[index].data[i] = {
            value: data[index],
            itemStyle: { barBorderRadius: [10, 10, 10, 10] },
          };
        } else {
          const index = data.findIndex(item => item && item > 0);
          chartOption.series[index].data[i] = {
            value: data[index],
            itemStyle: { barBorderRadius: [0, 0, 10, 10] },
          };
          const lastIndex = findLastIndex(data, item => item && item > 0);
          chartOption.series[lastIndex].data[i] = {
            value: data[lastIndex],
            itemStyle: { barBorderRadius: [10, 10, 0, 0] },
          };
        }
      }
    }
  }
}
