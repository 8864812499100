<div class="application-container">

    <div class="box ignored-table m-0">
        <div class="d-flex justify-content-between align-items-baseline mx-3">
            <h4 class="heading">Exception</h4>
            <span *ngIf="exceptionData && exceptionData.length" class="pareto-icon" (click)="openParetoChartModal(template)" tooltip="Pareto Analysis"><i class="fa fa-signal" aria-hidden="true"></i></span>
        </div>
        <div class="table-responsive pt-3 align-items-center" *ngIf="exceptionData && exceptionData.length">
            <table class="table table-exception text-left">

                <tbody>
                    <tr *ngFor="let data of exceptionData; let i = index ">
                        <td title="{{data.errorMessageText}}">{{data.errorMessageText}}</td>
                        <td class="text-center align-middle font-weight-bold">{{data.errorMessageCount}}</td>
                    </tr>
                </tbody>

            </table>

            <div class="col-sm-12 p-3">
                <a href="javascript:void(0)" class="text-capitalize show-all-btn" (click)="navigateToAllException(exceptionType.Exception)">Show All</a>
            </div>

        </div>
        <!-- table responsive div ends -->

        <div class="row d-flex align-items-center justify-content-center table-no-data h-75" *ngIf="exceptionData && !exceptionData.length">
            <h6 class="heading text-danger text-right">
                No Records Found
            </h6>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header p-0 border-0">
        <h6 class="font-weight-bold ml-3">Exception</h6>
        <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center exception-box p-0">
        <ng-container *ngIf="exceptionData; else noRecords">
            <sf-bar-chart *ngIf="chartData" [chartData]="chartData" [chartHeight]="paretoChartHeight" [isParetoChart]="true" [showKpiLine]="true"></sf-bar-chart>
        </ng-container>
        <ng-template #noRecords>
            <h6 class="heading text-danger h-100 d-flex align-items-center justify-content-center">No Data To Show</h6>
        </ng-template>
    </div>
</ng-template>