import { Injectable, EventEmitter } from '@angular/core';
import { ILink, IServerResponse } from '@core/core.interfaces';
import { DataService } from '@core/services/DataService';
import { Endpoints } from '@config/endpoints';
import { IModalData } from '@modules/Super-admin/super-admin.model';
import { HeaderService } from '@core/services/HeaderService';
import { IBot, ICustomers } from '@modules/Dashboard/dashboard.model';



@Injectable()
export class SuperAdminService {

  public endpoints: ILink[];
  public botChange = new EventEmitter();
  constructor(private dataService: DataService, private headerService: HeaderService) { }

  async getAttachedFilterWithBot(customer: ICustomers) {
      const data = await this.dataService.request<IServerResponse<IBot[]>>(Endpoints.GetAttachedFilterWithBot, {
        data: customer.id,
        links: customer.links,
        headers: this.headerService.createHeaders(customer.id),
      });
      return data.result;
  }

  async getAttachedCustomers() {
      const data = await this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.GetAttachedCustomers);
      return data.result;
  }

  async getDeletedCustomers() {
    const data = await this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.GetDeletedCustomersList);
    return data.result;
  }

  async restoreCustomer(model: ICustomers) {
    return this.dataService.request<void>(Endpoints.RestoreCustomer, {
      links: model.links,
      query: { id: model.id},
    });
  }

  async deleteCustomer(model: ICustomers) {
    return this.dataService.request<void>(Endpoints.DeleteCustomer, {
      query: { id: model.id},
    });
  }

  async update(customer: ICustomers) {
      return this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.UpdateCustomer, {
        data: customer
      });
  }

  async updateBot(bot: IBot, botDetail: IBot ) {
    return this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.UpdateBot, {
        data: bot,
        links: botDetail.links,
        headers: this.headerService.createHeaders(botDetail.customerId),
      });
  }

  async deleteBot(data: IBot) {
    return this.dataService.request<void>(Endpoints.DeleteBot, {
        links: data.links,
        headers: this.headerService.createHeaders(data.customerId),
      });
  }

  async createBot(bot: IBot, customerDetail: ICustomers ) {
    bot['customerId'] = customerDetail.id;
      return this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.CreateBot, {
        data: bot,
        links: customerDetail.links,
        headers: this.headerService.createHeaders(customerDetail.id),
      });
  }

  async createFilter(botDetail: IModalData, displayName: string, clientPropertyName: string ) {
    return this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.SaveFilter, {
    data: {
      displayName: displayName,
      clientPropertyName: clientPropertyName,
      botId: botDetail.botDetail.botId
    },
      links: botDetail.botDetail.links,
      headers: this.headerService.createHeaders(botDetail.customerDetail.id),
  });
}

  async updateFilter(botDetail: IModalData, displayName: string) {
    return this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.UpdateFilter, {
    data: {
      displayName: displayName,
      botId: botDetail.botDetail.botId
    },
      links: botDetail.botDetail.links,
      headers: this.headerService.createHeaders(botDetail.customerDetail.id),
  });
}

  async deleteFilter(data: IModalData ) {
    return this.dataService.request<void>(Endpoints.DeleteFilter, {
      links: data.botDetail.links,
      headers: this.headerService.createHeaders(data.customerDetail.id),
    });
}

}
