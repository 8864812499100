import { OnChanges, Input, Renderer2, Directive } from '@angular/core';
import { IExceptionTable, IChartModel, ExceptionType } from '@modules/Dashboard/dashboard.model';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Directive()
export abstract class AbstractDashboardExceptionDirective implements OnChanges {

    @Input() exceptionData: IExceptionTable[];
    public modalRef: BsModalRef;
    public chartData: IChartModel;
    public paretoChartHeight = 450;

    constructor(private router: Router, private modalService: BsModalService, private renderer: Renderer2) { }

    ngOnChanges() {
        if (this.exceptionData && this.exceptionData.length) {
            this.setChartValue();
        }
    }

    setChartValue() {
        this.chartData = {
            chart: this.exceptionData.slice(0, 10) as IExceptionTable[],
        };
    }

    openParetoChartModal(template) {
        this.modalRef = this.modalService.show(template, {
        class: 'pareto-modal modal-dialog-centered',
        keyboard: false,
        ignoreBackdropClick: true,
        });
        this.renderer.addClass(document.body, 'pareto');
    }

    decline() {
        this.modalRef.hide();
        this.renderer.removeClass(document.body, 'pareto');
    }

    navigateToAllException(exceptionType: ExceptionType) {
        this.router.navigate(['/home/exceptions', exceptionType]);
    }
}
