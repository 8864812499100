import { Component, OnInit } from '@angular/core';
import { ModifyModalComponent } from '../modify-modal/modify-modal.component';
import { RemoveModalComponent } from '../remove-modal/remove-modal.component';
import { SuperAdminService } from '@modules/Super-admin/Services/SuperAdminService';
import { Router } from '@angular/router';
import { IBot, ICustomers } from '@modules/Dashboard/dashboard.model';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sf-add-database',
  templateUrl: './add-database.component.html',
  styleUrls: ['./add-database.component.scss']
})
export class AddDatabaseComponent implements OnInit {
  public modalRef: BsModalRef;
  public loadingFlag: boolean;
  public customers: ICustomers[];
  public deletedCustomers: ICustomers[];
  public attachCustomers: ICustomers[];
  public bots: IBot[];

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private dashboardService: DashboardService,
    private service: SuperAdminService,
  ) { }


  async ngOnInit() {
    this.getCustomers();
    this.getDeletedCustomers();
    this.getAttachCustomers();
  }

  get hasCustomers() {
    return !!(this.attachCustomers && this.attachCustomers.length);
  }

  async getCustomers() {
    this.customers = await this.dashboardService.getCustomers();
  }

  async getDeletedCustomers() {
    this.deletedCustomers = await this.service.getDeletedCustomers();
  }

  async getAttachCustomers() {
    this.attachCustomers = await this.service.getAttachedCustomers();
  }

  addModal(data?: ICustomers, isModify?: boolean) {
    this.modalRef = this.modalService.show(ModifyModalComponent, { initialState: {
      data: {
        attachCustomers: this.attachCustomers,
        customerDetail: data,
        isModify: isModify,
        title: isModify ? 'Modify Customer Database' : 'Add Customer Database'
      }},
      id: 1, class: 'modify-modal modal-dialog-centered modal-md',
      keyboard: false, ignoreBackdropClick: true
    });
    this.modalRef.content.onClose.subscribe((result: boolean) => {
        this.getCustomers();
    });
  }

  removeModal(data?: ICustomers) {
    this.modalRef = this.modalService.show(RemoveModalComponent, { initialState:
      data, id: 1, class: 'my-modal modal-dialog-centered modal-md',
      keyboard: false, ignoreBackdropClick: true });
      this.modalRef.content.data = {customerDetail: data };
        this.modalRef.content.onClose.subscribe((result: boolean) => {
          this.getCustomers();
          this.getDeletedCustomers();
      });
  }

  viewBot(data: ICustomers) {
    this.router.navigate(['/super-admin/view-bot', data.id]);
  }

  async restoreCustomer(deletedCustomer: ICustomers) {
    try {
      this.loadingFlag = true;
      await this.service.restoreCustomer(deletedCustomer);
      this.getCustomers();
      this.getDeletedCustomers();
    } catch (err) {

    } finally {
      this.loadingFlag = false;
    }
  }
}
