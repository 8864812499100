import { Component, ViewChild, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import * as echarts from 'echarts';
import { IChartResponseModel, IChartModel, IChartData, IChartResultCount } from '@modules/Dashboard/dashboard.model';
import { AbstractDashboardChartComponent } from '../abstract-dashboard-chart/abstract-dashboard-chart.component';
import { map, forEach, compact } from 'lodash';

@Component({
  selector: 'sf-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent extends AbstractDashboardChartComponent implements OnChanges {

  public myChart: any;
  @Input() chartData: IChartModel;
  @ViewChild('line', { static: true }) line: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.myChart.resize();
  }

  ngOnChanges() {
    if (this.chartData && this.chartData['chart'].length || this.chartData['chart']) {
      this.setLineChartData(this.chartData['chart'] as IChartResponseModel[]);
    }
  }

  async setLineChartData(chartModel: IChartResponseModel[]) {
    const formatter = [];
    this.data = chartModel;

    if (!this.data.length) {
      return;
    }

    const chartLegends = await this.getInquiryStatusArray(this.data);

    const legend = chartLegends.map((value: IChartData, index: number) => {
      let hasFalsyValues = true;
      for (let i = 0; i < value.data.length; i++) {
        if (value.data[i].result) {
          hasFalsyValues = false;
          break;
        }
      }
      return (!hasFalsyValues) ? chartLegends[index].title : '';
    });

    const chartData = chartLegends.map((value: IChartData, index: number) => {
      const chartResult = map(value.data, (model: IChartResultCount) => model.result);
      const formatterData = map(value.data, (model: IChartResultCount) => model.resultCount);
      if (compact(formatterData).length) {
        formatter[index] = formatterData;
      }
      if (legend[index]) {
        return {
          name: chartLegends[index].title,
          type: 'line',
          animationDuration: 2000,
          data: chartResult,
          itemStyle: {
            normal: {
              color: chartLegends[index].color,
            },
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: chartLegends[index].color,
            }, {
                offset: 1,
                color: '#ffe'
            }])
          },
        };
      }
    });

    const xPointLables = chartModel.map((value: IChartResponseModel) => value.xPointLabel);


    this.myChart = echarts.init(this.line.nativeElement as HTMLDivElement, {}, { height : 430 });

    this.myChart.setOption({
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          const tooltipLabels = [];
          const tooltipData = compact(formatter);
          forEach(params, (model, i: number) => {
            if (model.seriesName && params[i].value !== 0) {
              const br = (i !== 0 && tooltipLabels.length) ? '</br>' : '';
              tooltipLabels[i] = `${br} ${params[i].marker} ${params[i].value}%,
                ${(tooltipData.length) ? tooltipData[params[i].seriesIndex][params[i].dataIndex] || 0 : 0}`;
            }
          });
          return tooltipLabels.join('');
        },
      },
      legend: {
        icon: 'line',
        selectedMode: true,
        right: 23,
        data: compact(legend),
      },
      grid: {
        left: '1%',
        right: '4%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: xPointLables,
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(90, 104, 114, 0.15)',
            type: 'dashed',
            width: 1,
          },
        },
        axisLabel: {
          color: '#212529',
          padding: [10, 0, 0, 0],
        },
        axisLine: {
          lineStyle: {
            color: '#edeef0',
          }
        },
      },
      yAxis: {
        type: 'value',
        name: '%',
        nameTextStyle: {
          color: '#000000',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(90, 104, 114, 0.15)',
            type: 'dashed',
            width: 1,
          },
        },
        axisLabel: {
          color: '#272b2f',
        },
        axisLine: {
          lineStyle: {
            color: '#edeef0',
          }
        },
      },
      series: chartData,
    }, true);
  }
}
