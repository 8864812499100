import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IBotProcess, IBotEvent, IBotProcessEvent, IBotRequestModel } from '@modules/Dashboard/dashboard.model';
import { find } from 'lodash';
import { DataPersistenceService } from '@core/services/DataPersistenceService';

@Component({
  selector: 'sf-nested-dropdown',
  templateUrl: './nested-dropdown.component.html',
  styleUrls: ['./nested-dropdown.component.scss']
})
export class NestedDropdownComponent implements OnInit {
  @Input() bots: IBotProcessEvent[];
  @Input() hideOptions: boolean;
  @Input() hideLoader: boolean;
  @Input() currentBot: IBotProcessEvent;
  @Output() botClick = new EventEmitter<IBotRequestModel>();
  @Output() processClick = new EventEmitter<IBotRequestModel>();
  @Output() eventClick = new EventEmitter<IBotRequestModel>();

  constructor(private dataPersistenceService: DataPersistenceService) { }

  ngOnInit() {
  }

  selectBot(bot?: IBotProcessEvent) {
    const data = this.serialize(bot, null, null);
    this.botClick.emit(data);
  }

  selectProcess(process: IBotProcess) {
    const result = find(this.bots, { botProcess: [ { masterProcessId: process.masterProcessId } ]});
    const data = this.serialize(result, process, null);
    this.processClick.emit(data);
  }

  selectEvent(event: IBotEvent) {
    const bot = this.bots.find(item => item.botId === event.botId);
    const parentProcess = bot.botProcess.find(item => item.masterProcessId === event.masterProcessId);
    const data = this.serialize(bot, parentProcess, event);
    this.eventClick.emit(data);
  }

  getdropdownMenu() {
    const localStorageData = this.dataPersistenceService.get();
    if (localStorageData) {
      const bot = this.bots.find(item => item.botId === localStorageData.botId);
      if (localStorageData.masterProcessId && localStorageData.masterEventId) {
        const botProcess = bot.botProcess.find(item => item.masterProcessId === localStorageData.masterProcessId);
        let botEvent: IBotEvent;
        if (botProcess) {
          botEvent =  botProcess.botEvent.find(item => item.masterEventId === localStorageData.masterEventId);
        }
        return (this.hideOptions) ?
          botProcess ? `${bot.botName} > ${botProcess.processName}` : '' :
          `${bot.botName} > ${botProcess.processName} > ${botEvent.eventName}`;
      } else if (localStorageData.masterProcessId && !localStorageData.masterEventId) {
        const botProcess = bot.botProcess.find(item => item.masterProcessId === localStorageData.masterProcessId);
        if (botProcess) {
          return  botProcess.processName ? `${bot.botName} > ${botProcess.processName}` :
            bot.botName ? bot.botName : '';
        }
      } else {
        return this.currentBot.botName;
      }
    } else {
      return this.currentBot.botName;
    }
  }

  serialize(model: IBotProcessEvent, process: IBotProcess | null, event: IBotEvent | null, botType?: boolean) {
    return {
      botId: (model) ? model.botId : null,
      masterProcessId: (process) ? process.masterProcessId : null,
      masterEventId: (event) ? event.masterEventId : null,
      botType: (model && model.botType !== null ) ? model.botType : false,
    };
  }
}
