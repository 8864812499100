import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { IFilter,
  IBotProcessEvent,
  IFilterValue,
  IBotProcess,
  IBotEvent,
  IRequestModel,
  IDefaultFilterValue,
  IFilterEmitValue,
} from '@modules/Dashboard/dashboard.model';
import { MULTI_SELECT_DROPDOWN_SETTING, LOCAL_STORAGE_DEFAULT_FILTER_KEY, LOCAL_STORAGE_FILTER_KEY } from '@config/constants';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { ArrayHelper } from '@core/utils/ArrayHelper';
import { find, map, isEqual } from 'lodash';

@Component({
  selector: 'sf-filters-section',
  templateUrl: './filters-section.component.html',
  styleUrls: ['./filters-section.component.scss']
})

export class FiltersSectionComponent implements OnChanges {

  @Input() bots: IBotProcessEvent[];
  @Input() filters: IFilter[];
  @Input() employee: IRequestModel;
  @Input() selectedDefaultValue = [];
  @Input() selectedFilter = [];

  @Output() subDefaultFilterChanges = new EventEmitter<IDefaultFilterValue>();
  @Output() subFilterChanges = new EventEmitter<IFilterEmitValue>();

  public defaultDropdownSettings = MULTI_SELECT_DROPDOWN_SETTING;
  public values: IBotEvent[] | IBotProcess[];
  public model: IFilterValue[];
  public filterTokenTitle = '';
  public filterValues = '';

  constructor(private dataPersistenceService: DataPersistenceService) { }

  ngOnChanges() {
    const dropdownSettingObj = {
      primaryKey: 'filterValue',
      labelKey: 'displayName',
      enableSearchFilter: true,
      disabled: false,
    };
    this.defaultDropdownSettings = Object.assign(this.defaultDropdownSettings, dropdownSettingObj);
    this.selectedDefaultValue = this.dataPersistenceService.getByKey(LOCAL_STORAGE_DEFAULT_FILTER_KEY) || [];
    if (this.selectedDefaultValue && this.selectedDefaultValue.length) {
      this.defaultDropdownSettings['primary key']  = Object.keys(this.selectedDefaultValue[0])[0];
      this.mapDefaultFilterValue(this.selectedDefaultValue);
    }
    this.dataPersistenceService.set(this.selectedDefaultValue, LOCAL_STORAGE_DEFAULT_FILTER_KEY);
    if (this.bots) {
        this.getDefaultFilterValues();
    }
  }

  onDefaultFilterValueDeSelect(items: IFilterValue[]) {
    this.mapDefaultFilterValue(items);
    this.setFilterValues();
  }

  setFilterValues() {
    this.selectedDefaultValue = this.model || [];
    this.employee.filterValue = this.filterValues || '';
    this.subDefaultFilterChanges.emit({selectedFilter: this.selectedDefaultValue, filterValue: this.employee.filterValue});
  }

  mapDefaultFilterValue(items: IFilterValue[]) {
    if (items) {
      if (this.employee) {
        if (this.employee.botId && !this.employee.masterProcessId) {
          this.model = map((items as IBotProcess[]), (ele: IBotProcess) => {
            return {
              masterProcessId: ele.masterProcessId,
              displayName: ele.displayName,
            };
          });
          this.filterValues = ArrayHelper.convertArrayToString(items, 'masterProcessId');
        } else if (this.employee.botId && this.employee.masterProcessId && !this.employee.masterEventId) {
          this.model = map((items as IBotEvent[]), (ele: IBotEvent) => {
            return {
              masterEventId: ele.masterEventId,
              displayName: ele.displayName,
            };
          });
          this.filterValues = ArrayHelper.convertArrayToString(items, 'masterEventId');
        }
      }
    } else {
      this.model = [];
      this.filterValues = '';
    }
  }

  getDefaultFilterValues() {
    if (this.employee) {
        if (this.employee.botId && !this.employee.masterProcessId) {
          this.values = find(this.bots, {botId : this.employee.botId}).botProcess;
          this.defaultDropdownSettings =
            Object.assign(this.defaultDropdownSettings, { primaryKey : 'masterProcessId', text: 'Select Underlying Processes' });
        } else if (this.employee.botId && this.employee.masterProcessId && !this.employee.masterEventId ) {
          const bots = find(this.bots, { botId : this.employee.botId });
          this.values = (bots.botProcess.length) ?
            find(bots.botProcess, { masterProcessId : this.employee.masterProcessId }).botEvent : [];
          this.defaultDropdownSettings =
            Object.assign(this.defaultDropdownSettings, { primaryKey : 'masterEventId', text: 'Select Underlying Tasks'} );
        } else {
          this.values = [];
          this.defaultDropdownSettings['disabled'] = 'No Selection';
          this.defaultDropdownSettings['disabled'] = true;
        }
    }
  }

  getFilterTitle(e: Event) {
    const value = e.target as HTMLInputElement;
    this.filterTokenTitle = (value.tagName === 'svg' || value.tagName === 'path') ? '' : value.innerText;
  }

  onSelectChange(items: IFilterValue[], filter: IFilter, i: number) {
    const filterValues = ArrayHelper.convertArrayToString(items, 'filterValue');
    this.selectedFilter[i] = items;
    this.subFilterChanges.emit({selectedFilter: this.selectedFilter, filterValue: filterValues, filter, filterIndex: i + 1});
  }
}
