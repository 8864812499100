import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IDropdownData } from '../dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'sf-app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Input() items: IDropdownData;
  @ViewChild('childMenu', { static: true }) childMenu: any;
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

  @Output() click = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onClick(item: IDropdownData) {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
    this.click.emit(item);
  }

}

