import { Component, Input, EventEmitter, Output, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { IPagination } from '@core/core.interfaces';
import { MAX_PAGINATION_SIZE } from '@config/constants';

@Component({
  selector: 'sf-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements AfterViewInit {
  @Input() totalRecords: number;
  @Input() pagination: IPagination;
  public maxSize = MAX_PAGINATION_SIZE;
  @Output() paginationDetails = new EventEmitter<IPagination>();

  constructor(private changeDef: ChangeDetectorRef) { }

  pageChanged(event: PageChangedEvent): void {
    this.pagination.currentPage = event.page;
    this.paginationDetails.emit({ currentPage: this.pagination.currentPage  });
  }

  ngAfterViewInit() {
    if (this.changeDef && !(this.changeDef as ViewRef).destroyed) {
      this.changeDef.detectChanges();
    }
  }
}

