import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IBot, IFilter } from '@modules/Dashboard/dashboard.model';
import { DEFAULT_FILTER } from '@config/constants';

@Component({
  selector: 'sf-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss'],
})
export class TokensComponent<T> {
  @Output() tokenClick = new EventEmitter<IFilter | {botName: string}>();
  @Output() tokenRemove = new EventEmitter<IFilter[] | string>();
  @Input() tokens: IFilter[] | string;
  constructor() { }

  isString(tokens: string | IFilter[]) {
    return typeof(tokens) === 'string';
  }

  onTokenClick(token: IFilter | {botName: string}) {
    this.tokenClick.emit(token);
  }

  onTokenRemove(token: IFilter[] | string) {
    this.tokenRemove.emit(token);
  }

  isDefaultFilter(token: IFilter) {
    return token.displayName === DEFAULT_FILTER;
  }
 }
