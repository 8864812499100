import { Component, OnChanges, Input } from '@angular/core';
import { IDoughnutChartinquiryResponse, IFilter, IUtilization, IRequestModel, IFilterValue } from '@modules/Dashboard/dashboard.model';
import { DashboardService } from '@modules/Dashboard/Services/DashboardService';
import { DataPersistenceService } from '@core/services/DataPersistenceService';
import { isEqual } from 'lodash';
import { DEFAULT_FILTER } from '@config/constants';
import { ArrayHelper } from '@core/utils/ArrayHelper';

@Component({
  selector: 'sf-inquiry-breakdown',
  templateUrl: './inquiry-breakdown.component.html',
  styleUrls: ['./inquiry-breakdown.component.scss']
})
export class InquiryBreakdownComponent implements OnChanges {


  public inquiryBreakdownData: IDoughnutChartinquiryResponse[];
  public employeeUtilizationData: IUtilization;

  public loadingFlag = false;
  public doughnutChartLegends = [];
  public isInquiryBreakdown = true;
  public isEmployeeUtilize: boolean;
  public botId: string;
  @Input() headerFilters: Array<null> | IFilterValue[][];

  public localValues: IRequestModel;
  @Input() filters: IFilter[];
  public selectedFilter: string;
  public isFilterSelected = false;

  constructor(
    private service: DashboardService,
    private dataPersistenceService: DataPersistenceService,
    ) { }

    get isFiltersAvailable() {
      return !!(this.filters && this.filters.length);
    }

    ngOnChanges() {
      this.localValues = this.dataPersistenceService.get();
      this.botId = this.localValues ? this.localValues.botId : null;
      this.selectedFilter = 'Select Filter';
      this.inquiryBreakdownData = [];
      this.doughnutChartLegends = [];
      this.isFilterSelected = false;
      this.isEmployeeUtilize = false;
      if (this.filters && !this.filters.length && this.localValues.botType) {
        this.getUtilization();
      } else {
        const index = this.filters.findIndex(item => item.isDefault);
        if (index > -1) {
          this.selectFilter(index);
        }
      }
   }

   async selectFilter(i: number) {
    try {
      this.loadingFlag = true;
      if (!isEqual(this.filters[i].displayName, this.selectedFilter)) {
          this.selectedFilter = (this.filters[i].displayName === DEFAULT_FILTER) ? 'Underlying Processes' : this.filters[i].displayName;
          this.localValues = this.dataPersistenceService.get();
          const selectedFilterList = (this.filters[i].displayName === DEFAULT_FILTER) ?
                                      this.localValues.filterValue :
                                      ArrayHelper.convertArrayToString(this.headerFilters[i], 'filterValue');
          this.inquiryBreakdownData = await this.service.getInquiryBreakdownData(
            this.localValues,
            this.selectedFilter,
            selectedFilterList,
            );
          if (this.inquiryBreakdownData && this.inquiryBreakdownData.length) {
            this.doughnutChartLegends = this.inquiryBreakdownData.map(record => {
              return {
                title: record.filterValue,
                data: record.percentage,
                total: record.totalCount,
                resultCount: record.filterCount
              };
            });
          } else {
            this.inquiryBreakdownData = [];
            this.isFilterSelected = true;
          }
      }
    } catch (err) { }
    finally {
      this.loadingFlag = false;
    }
  }

  async getUtilization() {
    this.employeeUtilizationData = await this.service.getEmployeeUtilizationData(this.localValues);
    if (this.employeeUtilizationData) {
      this.doughnutChartLegends = [
        {
          title: this.employeeUtilizationData.status,
          data: this.employeeUtilizationData.result,
          resultCount: this.employeeUtilizationData.resultCount
        },
        {
          title: 'Unused',
          data: 100 - this.employeeUtilizationData.result,
          resultCount: this.employeeUtilizationData.resultCount
        },
      ];
    }
    this.isEmployeeUtilize = true;
  }

  showFilterOption(data: IFilter) {
    return (data.isActive && (!this.localValues.masterProcessId || data.displayName !== DEFAULT_FILTER));
  }

  getDisplayName(data: IFilter) {
    return (data.displayName === DEFAULT_FILTER && !this.localValues.masterProcessId) ? 'Underlying Processes' : data.displayName;
  }

  hasFilterSelected() {
    return (this.isFiltersAvailable && !this.isDefaultProcessFilter());
  }

  isDefaultProcessFilter() {
    return (this.filters.length === 1 && this.filters[0].displayName === DEFAULT_FILTER && this.localValues.masterProcessId);
  }

  getNoFilterSelectedLabel() {
    if (this.isFiltersAvailable && !this.isFilterSelected) {
      return 'Please select any filter';
    } else {
      return 'No Records found \n Please try a different combination';
    }
  }

  getFilterMessage() {
    if (this.isFiltersAvailable && this.isDefaultProcessFilter()) {
      return 'Please Select any other filter';
    } else {
      return 'No Filter Selected';
    }
  }
}
