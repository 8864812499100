import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IAcknowledgeModal } from '@modules/Dashboard/dashboard.model';

@Component({
  selector: 'sf-acknowledge-modal',
  templateUrl: './acknowledge-modal.component.html',
  styleUrls: ['./acknowledge-modal.component.scss']
})
export class AcknowledgeModalComponent {

  public data: IAcknowledgeModal;

  constructor(public modalRef: BsModalRef) { }

  hide() {
    this.modalRef.hide();
  }
}
