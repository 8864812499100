import { Component } from '@angular/core';
import { ExceptionType } from '@modules/Dashboard/dashboard.model';
import { AbstractDashboardExceptionDirective } from '../abstract-dashboard-exception/abstract-dashboard-exception.directive';

@Component({
  selector: 'sf-hand-over',
  templateUrl: './hand-over.component.html',
  styleUrls: ['./hand-over.component.scss']
})

export class HandOverComponent extends AbstractDashboardExceptionDirective {

  public exceptionType = ExceptionType;

}
