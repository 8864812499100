import { Injectable } from '@angular/core';
import { StaticEndpoints } from '@config/endpoints';
import { IAuthService, ISession } from '@core/core.interfaces';
import { SessionService } from '@core/services/Session';
import { DataService } from '@core/services/DataService';

@Injectable()
export class AuthService implements IAuthService {

  constructor(private dataService: DataService, private session: SessionService) {}

  async login(login: string, password: string): Promise<void> {
    try {
      const result = await this.dataService.post<ISession>(StaticEndpoints.Login, {
        login,
        password,
      });
      this.session.setSession(result);
    } catch (err) {
      this.session.resetSession();
    }
  }

  async logout(): Promise<void> {
    this.session.resetSession();
    this.dataService.post(StaticEndpoints.Logout);
  }

}
