import { ILink, IPagination } from '@core/core.interfaces';
import { SolutionType } from '@modules/Super-admin/super-admin.model';

export interface ICustomers {
  id: string;
  customerName: string;
  databaseName: string;
  customerType: SolutionType;
  rpaAllocatedHours: number;
  rpaConnectionString: string;
  rpaDatabaseName: string;
  isManualInquiryAtJobLevel: boolean;
  isActive: boolean;
  timezoneId: string;
  timezoneDisplayName: string;
  links?: ILink[];
}

export interface IBot {
  botId?: string;
  botName: string;
  botType?: boolean;
  customerId?: string;
  manualTimeSpan: string;
  clientJobName?: string;
  botRunhrs?: number;
  filters?: IFilter[];
  links?: ILink[];
}

export interface IBotProcessEvent extends IBot {
  botType: boolean;
  masterJobId: string;
  botProcess: IBotProcess[];
  masterProcessId: string;
  masterEventId: string;
  displayName?: string;
  labelName?: string;
  children?: IBotProcess[];
}

export interface IBotProcess {
  botId: string;
  processName: string;
  masterProcessId: string;
  botEvent: IBotEvent[];
  displayName?: string;
  labelName?: string;
  children?: IBotEvent[];
  links: ILink[];
}

export interface IBotEvent {
  botId: string;
  masterProcessId: string;
  eventName: string;
  masterEventId: string;
  displayName?: string;
  labelName?: string;
}

export interface IDateRangeCustomValue {
  title: string;
  value: number;
}

export interface IDateFilter {
  startDate: Date;
  endDate: Date;
  isDateCalenderOpen?: boolean;
}

export interface IBotModel {
  botId: string;
  botName: string;
  botType: boolean;
  customerId: string;
  kpiValue: string;
  manualTimeSpan: string;
}

export interface IDoughnutChartResponse {
  percentage: number;
  status: string;
  result: number;
  total: number;
  resultCount: number;
}

export interface IDoughnutChartinquiryResponse {
  filterValue: string;
  filterCount: number;
  totalCount: number;
  percentage: number;
}

export interface IDoughnutChartLegends {
  title: string;
  data: number;
  total: number;
  resultCount: number;
}

export interface IUtilization {
  status: string;
  result: number;
  total: number;
  resultCount: number;
}

export interface IRequestModel {
  fromDate: string;
  toDate: string;
  timeSpan: number;
  botId: string;
  customerId: string;
  customerType: SolutionType;
  masterProcessId: string;
  masterEventId: string;
  pageNumber?: number;
  rowNumber?: number;
  displayName?: string;
  botType: boolean;
  selectfilter?: string;
  filterValue1?: string;
  filterValue2?: string;
  filterValue3?: string;
  FilterName1?: string;
  FilterName2?: string;
  FilterName3?: string;
  labelName?: string;
  filterValue?: string;
}

export interface ITimeResponse {
  time: string;
  format: string;
}

export interface IExceptionTable {
  cummulativePercentage: number;
  percentage: number;
  totalCount: number;
  errorMessageText: string;
  errorMessageCount: number;
}

export interface IException {
  businessException: IExceptionTable[];
  applicationException: IExceptionTable[];
  exception: IExceptionTable[];
}

export interface IAllExceptionResponse extends IPagination {
  exceptions: IAllException[];
}

export interface IAllException {
  jobName: string;
  processName: string;
  eventName: string;
  date: string;
  description: string;
}

export interface IChartResponse {
  chartShowDate: string;
  kpi: string;
  data: IChartResponseModel[];
}

export interface IChartResponseModel {
  endInterval: string;
  startInterval: string;
  statusList: IDoughnutChartResponse[];
  xPointLabel: string;
}

export interface IPopupChartResponse {
  data: IChartResponse;
  previousData: IChartResponse;
}

export interface IChartModel {
  chart: IChartResponseModel[] | IExceptionTable[];
  kpi?: string;
}

export interface IChartResultCount {
  percentage: number;
  result: number;
  resultCount: number;
}

export interface IChartData {
  title: string;
  data: IChartResultCount[];
  color: string;
}

export interface IExportResponse {
  fileName: string;
  filePath: string;
  fileUrl: string;
}

export interface IFilter {
  clientPropertyName: string;
  id: string;
  botId: string;
  displayName: string;
  isActive: boolean;
  isDefault: boolean;
  values: IValue[];
  links: ILink[];
}

export interface ISetting {
  manualTimeSpan: string;
  processedInquiries: number;
  kpi: number;
  filter: IFilter[];
}

export interface IBotRequestModel {
  botId: string;
  masterProcessId: string;
  masterEventId: string;
  botType: boolean;
}

export interface IAcknowledgeModal {
  hasSuperAdminRole: boolean;
}

export interface IValue {
  filterId: string;
  filterValue: string;
}

export interface IFilterValue {
  filterValue?: string;
  masterProcessId?: string;
  masterEventId?: string;
  displayName?: string;
}

export interface IEmployeeModal {
  botList: IBotProcessEvent[];
  currentBot: IBotProcessEvent;
  filters: IFilter;
}

export interface IDefaultFilterValue {
  selectedFilter: IFilterValue[];
  filterValue: string;
}

export interface IFilterEmitValue extends IDefaultFilterValue {
  filterIndex: number;
  filter: IFilter;
}

export interface IEmailAlert {
  id: string;
  emailAlertLevel: string;
  botId: string;
  masterProcessId: string;
  schedule: EmailAlertSchedule;
  valueType: EmailAlertValueType;
  value: number;
  condition: EmailAlertCondition;
  status: EmailAlertStatus;
  emailAddress: string;
  links?: ILink[];
}

export enum EmailAlertSchedule {
  Daily = 0,
  Weekly = 1,
  Biweekly = 2,
  Monthly = 3,
}

export enum EmailAlertValueType {
  Percentage = 1,
  Count = 2,
}

export enum EmailAlertCondition {
  GreaterThan = 1,
  LessThan = 0,
}

export enum EmailAlertStatus {
  Success = 0,
  HandOver = 1,
  SystemFailure = 2,
  Exceptions = 3,
}

export interface INotificationModalData {
  notificationList: INotification;
  selectedCustomer: ICustomers;
}

export interface INotification {
  notification: INotificationModel[];
  totalUnreadCount: number;
  totalPages: number;
}

export interface INotificationModel {
  id: string;
  emailBody: string;
  createdDate: string;
  isRead: boolean;
}

export enum PermissionEnum {
  DashboardView = 'dashboardView',
  AdminView = 'adminView',
  SuperAdminView = 'superAdminView',
}

export enum DateRangeEnum {
  SelectDateRange = 0,
  LastDay = 1,
  Last7Days = 7,
  Last30Days = 30,
  Quartertodate = 90,
  Yeartodate = 365,
}

export enum ExceptionType {
  HandOver = 1,
  SystemFailure = 2,
  Exception = 3,
}
