<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <div>
    <span *ngFor="let child of items.children">
      <!-- Handle branch node menu items -->
      <span *ngIf="child.children && child.children.length > 0">
        <button class="btn-border" mat-menu-item color="primary" [title]="child.displayName" [matMenuTriggerFor]="menu.childMenu" (click)="onClick(child)">
          <span>{{child.displayName}}</span>
        </button>
        <sf-app-menu-item #menu [items]="child" (click)="onClick($event)"></sf-app-menu-item>
      </span>
      <!-- Handle leaf node menu items -->
      <span *ngIf="!child.children || child.children.length === 0">
        <button class="btn-border" mat-menu-item (click)="onClick(child)" [title]="child.displayName">
          <span>{{child.displayName}}</span>
        </button>
      </span>
    </span>
  </div>
</mat-menu>
