import { Injectable } from '@angular/core';
import { DataService } from '@core/services/DataService';
import { ILink, IServerResponse } from '@core/core.interfaces';
import { Endpoints } from '@config/endpoints';
import {
  IDoughnutChartResponse, IRequestModel, ITimeResponse,
  IChartResponse, IPopupChartResponse, IExportResponse, IFilter, IDoughnutChartinquiryResponse,
  IUtilization, ISetting, ICustomers, IBotProcessEvent, IException, IAllExceptionResponse, INotification, IEmailAlert,
} from '@modules/Dashboard/dashboard.model';
import { DashboardMapper } from './DashboardMapper';
import { HeaderService } from '@core/services/HeaderService';


@Injectable()
export class DashboardService {
  public endpoints: ILink[];

  constructor(
    private dataService: DataService,
    private dashboardMapper: DashboardMapper,
    private headerService: HeaderService,
    private mapper: DashboardMapper,
  ) { }

  async getBots(customer: ICustomers) {
    const data = await this.dataService.request<IServerResponse<IBotProcessEvent[]>>(Endpoints.GetBotsProcessEvent, {
      links: customer.links,
      headers: this.headerService.createHeaders(customer.id),
    });
    return this.mapper.serializeBots(data.result);
  }

  async getCustomers() {
    const data = await this.dataService.request<IServerResponse<ICustomers[]>>(Endpoints.GetCustomers);
    return data.result;
  }

  async getInquiryStatusData(params: IRequestModel) {
    return await this.dataService.request<IServerResponse<IDoughnutChartResponse[]>>(Endpoints.GetInquiryStatus, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });
  }

  async getInquiryStatusStackChartData(params: IRequestModel) {
    return await this.dataService.request<IServerResponse<IChartResponse>>(Endpoints.GetInquiryStatusStackChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });
  }

  async getEmployeeUtilizationData(params: IRequestModel) {
    const queryParams = this.dashboardMapper.serializeUtilizationParams(params);
    const data = await this.dataService.request<IServerResponse<IUtilization>>(Endpoints.GetEmployeeUtilization, {
      query: queryParams,
      headers: this.headerService.createHeaders(params.customerId),
    });
    return data.result;
  }

  async getInquiryBreakdownData(params: IRequestModel, filterValue: string, selectedFilter: string) {
    params.displayName = filterValue;
    params.selectfilter = selectedFilter ? selectedFilter : '';
    const data = await this.dataService.request<IServerResponse<IDoughnutChartinquiryResponse[]>>(Endpoints.GetInquiryBreakdownChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data.result;
  }

  async getSourceData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IDoughnutChartResponse[]>>(Endpoints.GetBotSource, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getTimeSavedData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<ITimeResponse>>(Endpoints.GetSavedTime, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getAvgProcessingTimeData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<ITimeResponse[]>>(Endpoints.GetBotAverageProcessingTime, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getInquiriesData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IChartResponse[]>>(Endpoints.GetInquiries, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getProcessInquiriesData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IChartResponse[]>>(Endpoints.GetProcessedInquiries, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getEmployeePerformancePopupData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IPopupChartResponse>>(Endpoints.GetPerformanceChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getSourcePopupData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IPopupChartResponse>>(Endpoints.GetSourceChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getTimeSavedPopupData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IPopupChartResponse>>(Endpoints.GetTimeSavedChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getAvgProcessingPopupData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IPopupChartResponse>>(Endpoints.GetAvgProcessingChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getInquiriesPopupData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IPopupChartResponse>>(Endpoints.GetInquiriesChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getProcessInquiriesPopupData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IPopupChartResponse>>(Endpoints.GetProcessInquiriesChart, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });

    return data;
  }

  async getFilterData(bot: IBotProcessEvent, params: IRequestModel) {
    const queryParams = this.mapper.serializeData(params);
    const data = await this.dataService.request<IServerResponse<IFilter[]>>(Endpoints.GetFilters, {
      query: queryParams,
      links: bot.links,
      headers: this.headerService.createHeaders(bot.customerId),
    });
    return data.result;
  }

  async getSettingsData(bot: IBotProcessEvent, cacheValues: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<ISetting>>(Endpoints.GetSettings, {
      query:  (bot.masterProcessId) ? { masterProcessId: bot.masterProcessId} : null,
      links: bot.links,
      headers: this.headerService.createHeaders(cacheValues.customerId),
    });
    return data.result;
  }

  async updateFilterData(bot: IBotProcessEvent, model: ISetting, cacheValues: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<ISetting>>(Endpoints.UpdateSettings, {
      query:  (bot.masterProcessId) ? { masterProcessId: bot.masterProcessId} : null,
      links: bot.links,
      data: model,
      headers: this.headerService.createHeaders(cacheValues.customerId),
    });
    return data.result;
  }

  async exportStructure(params: IRequestModel, isExportExcel: boolean) {
    const queryParams = this.mapper.serializeData(params);
    const data = await this.dataService.request<IServerResponse<IExportResponse>>(Endpoints.GetReport, {
      query: { ...queryParams, isExportExcel },
      headers: this.headerService.createHeaders(params.customerId),
    });
    return data.result;
  }

  async getExceptionData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IException>>(Endpoints.GetExceptions, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });
    return data;
  }

  async getAllExceptionData(params: IRequestModel) {
    const data = await this.dataService.request<IServerResponse<IAllExceptionResponse>>(Endpoints.GetAllExceptions, {
      query: params,
      headers: this.headerService.createHeaders(params.customerId),
    });
    return data.result;
  }

  async getNotifications(customer: ICustomers, pageNumber: number) {
    const data = await this.dataService.request<IServerResponse<INotification>>(Endpoints.GetNotification, {
      links: customer.links,
      query: { pageNumber },
      headers: this.headerService.createHeaders(customer.id),
    });
    return data.result;
  }

  async setNotificationStatus(customer: ICustomers, notificationId: string | null) {
    await this.dataService.request<void>(Endpoints.UpdateNotificationStatus, {
      query: { id: notificationId },
      headers: this.headerService.createHeaders(customer.id),
    });
  }

  async getEmailAlertData(customer: ICustomers): Promise<IEmailAlert[]> {
    const data = await this.dataService.request<IServerResponse<IEmailAlert[]>>(Endpoints.GetEmailAlerts, {
      links: customer.links,
      headers: this.headerService.createHeaders(customer.id),
    });
    return data.result;
  }

  async saveEmailAlert(customer: ICustomers, alert: IEmailAlert[]) {
    const data = await this.dataService.request<IServerResponse<IEmailAlert[]>>(Endpoints.CreateEmailAlert, {
      links: customer.links,
      data: alert,
      headers: this.headerService.createHeaders(customer.id),
    });
  }

  async deleteEmailAlert(alert: IEmailAlert, params: IRequestModel) {
    const data = await this.dataService.request<void>(Endpoints.DeleteEmailAlert, {
      links: alert.links,
      data: { id: alert.id },
      headers: this.headerService.createHeaders(params.customerId),
    });
  }
}
