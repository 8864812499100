<sf-loading [loadingFlag]="loadingFlag"></sf-loading>
<div *ngIf="data">
    <div class="modal-header align-items-center">
        <h6 class="modal-title pull-left">{{data.title}}</h6>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <h6 class="bot-title font-weight-bold">{{ data.isModify ? data.botName : data.botDetail.botName}}</h6>
    <div class="modal-body text-center">
        <div class="form-row">
            <div class="form-group col">
                <div class="row">
                    <div class="col-sm-5 text-left">Display Name</div>
                    <div class="col">
                        <input type="text" name="displayName" class="form-control" [(ngModel)]="displayName" autocomplete="off" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row row">
            <div class="form-group col">
                <div class="row" [class.disabled]="data.isModify">
                    <div class="col-sm-5 text-left">Property</div>
                    <div class="col">
                        <input type="text" name="Property" class="form-control" [disabled]="data.isModify" [(ngModel)]="clientPropertyName" autocomplete="off" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-right m-1">
                <button type="button" class="btn save-btn mr-1" (click)="onConfirmation()">{{ data.isModify ? 'Save': 'Add Filter'}}</button>
                <button type="button " class="btn disable-btn" (click)="hide()">Cancel</button>
            </div>
        </div>
        <div class="row text-left">
            <span class="text-left text-danger" *ngIf="showValidationMessage">Both field required</span>
            <span class="text-left text-danger" *ngIf="isDuplicate">Display Name already exist</span>
        </div>
    </div>
</div>