import { Component, Input, OnChanges } from '@angular/core';
import { IDoughnutChartResponse, IRequestModel } from '@modules/Dashboard/dashboard.model';
import { DataPersistenceService } from '@core/services/DataPersistenceService';

@Component({
  selector: 'sf-inquiry-status',
  templateUrl: './inquiry-status.component.html',
  styleUrls: ['./inquiry-status.component.scss']
})
export class InquiryStatusComponent implements OnChanges {


  @Input() inquiryStatusData: IDoughnutChartResponse[];
  public doughnutChartLegends = [];
  public botId: string;
  public localValues: IRequestModel;

  constructor(private dataPersistenceService: DataPersistenceService) { }

  ngOnChanges() {

    this.botId = this.dataPersistenceService.get() ? this.dataPersistenceService.get().botId : null;

    if (this.inquiryStatusData) {

      this.doughnutChartLegends = this.inquiryStatusData.map(record => {
        return {
          title: record.status,
          data: record.result,
          total: record.total,
          resultCount: record.resultCount
        };
      });
    }

  }
}
