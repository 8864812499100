<div class="modal-header py-2 px-3 d-flex align-items-center">
    <div class="pull-left">
        <h6 class="modal-title d-inline">Notification</h6>
        <a class="ml-2 mark-all-read" href="javascript:void(0)" (click)="markAllAsRead()" *ngIf="hasAllUnread">Mark all as read</a>
    </div>
    <button type="button" class="close pull-right text-danger p-0 m-0" aria-label="Close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-0" *ngIf="data">
    <ng-container *ngIf="data.notificationList.notification.length; else noNotification">
        <div
            class="d-flex justify-content-start align-items-center notification-list"
            *ngFor="let item of data.notificationList.notification; let i = index"
            [ngClass]="{ 'unread': !item.isRead }"
            (click)="markAsRead(item, i)"
        >
            <span><i class="fa fa-envelope-o fa-1x icon" aria-hidden="true"></i></span>
            <div class="pl-4">
                <div class="d-block">{{item.emailBody}}</div>
                <div class="notification-time">{{item.createdDate}}</div>
            </div>
        </div>
        <div class="text-center my-2" *ngIf="pageNumber < this.data.notificationList.totalPages">
            <a href="javascript:void(0)" class="load-more" (click)="loadMore()">View More</a>
        </div>
    </ng-container>
    <ng-template #noNotification>
        <div class="text-center text-danger mt-4">
            <h6>No data to show</h6>
        </div>
    </ng-template>
</div>