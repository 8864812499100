<sf-loading [loadingFlag]="!(bots && bots.length) && !hideLoader"></sf-loading>
<div class="row" *ngIf="bots && bots.length">
    <ul id="drop-nav" class="btn-group drop-nav" [ngStyle]="{ 'width': (!hideOptions) ? '100%' : 'auto'}" *ngIf="bots && bots.length > 0">
        <li class="drop-menu w-100 col-sm btn-lg" title="{{getdropdownMenu()}}">{{ getdropdownMenu() | truncateInside: 50 }} &#9662;
            <ul>
                <div class="drop-submenu w-50">
                    <li *ngIf="bots.length > 1">
                        <a (click)="selectBot()" *ngIf="!hideOptions">All Modules</a>
                    </li>
                    <li *ngFor="let bot of bots">
                        <a href="javascript:void(0)" (click)="selectBot(bot)" title="{{bot.botName}}">{{bot.botName}} {{bot.botProcess.length ? '&#9656;' : ''}}</a>
                        <ul *ngIf="bot.botProcess.length">
                            <div class="drop-submenu">
                                <li *ngFor="let process of bot.botProcess">
                                    <a href="javascript:void(0)" (click)="selectProcess(process)" title="{{process.processName}}">{{process.processName}} {{(!hideOptions && process.botEvent.length) ? '&#9656;' : ''}}</a>
                                    <ul class="events" *ngIf="!hideOptions && process.botEvent.length">
                                        <div class="drop-submenu">
                                            <li *ngFor="let event of process.botEvent">
                                                <a href="javascript:void(0)" (click)="selectEvent(event)" title="{{event.eventName}}">{{event.eventName}}</a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                            </div>
                        </ul>
                    </li>
                </div>
            </ul>
        </li>
    </ul>
</div>