import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'logout-callback.page.html',
  styleUrls: ['logout-callback.page.css'],
})
export class LogoutCallbackPageComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit() {
    this.router.navigate(['']);
  }

}
