<sf-loading [loadingFlag]="loading"></sf-loading>
<div class="header">
    <div class="container-fluid">
        <div class="row bot-header px-3 py-2">
            <div class="col-4 col-md-2 text-center text-lg-left p-2 d-flex align-items-center">
                <a (click)="navigatToDashboard()">
                    <img src="/assets/images/logo.png" class="w-75 img-fluid" title="Simplifai Dashboard">
                </a>
            </div>
            <div class="col-8 col-md-10 pr-0 d-flex justify-content-end align-items-center">
                <div class="col-6 col-md-2 pr-0 d-flex justify-content-end align-items-center">
                    <div class="btn-group align-items-center mr-3" *ngIf="cacheValues && cacheValues.botId && hideAllException && (checkPermission(permission.AdminView) || checkPermission(permission.SuperAdminView))">
                        <ng-container *ngIf="loadingSetting && !modalRef; else settingIcon">
                            <img class="setting-loader-img" src="assets/images/35.gif"/>
                        </ng-container>
                        <ng-template #settingIcon>
                            <i class="fa fa-cog header-icon setting-icon" tooltip="Settings" (click)="openSettingModal(template)"></i>
                        </ng-template>
                    </div>
                    <div class="btn-group align-items-center mr-3">
                        <i class="fa fa-bell header-icon notification-icon" tooltip="Notification" (click)="openNotificationModal()"></i>
                        <span class="badge badge-pill badge-info notification-count" *ngIf="unreadNotificationCount">{{unreadNotificationCount}}</span>
                    </div>
                    <div class="btn-group mr-3">
                        <div class="customer" [autoClose]="profileDropdownAutoClose" tabindex="-1" dropdown (focusout)="profileDropdownAutoClose = true" (click)="profileDropdownAutoClose = false">
                            <a id="user" dropdownToggle aria-controls="select-de dropdown-nested" class="dropdown-toggle">
                                <i class="fa fa-user-circle-o header-icon" aria-hidden="true"></i> <i class="dropdown-arrow down"></i>
                            </a>
                            <ul id="user dropdown-nested mr-1" *dropdownMenu class="dropdown-menu customer-dropdown" placement="right" role="menu" aria-labelledby="user">
                                <li role="menuitem" dropdown triggers="mouseover" placement="left" container="body" *ngIf="checkPermission(permission.SuperAdminView)">
                                    <a dropdownToggle class="dropdown-item dropdown-toggle">Customer  <span class="caret"></span></a>
                                    <ul id="customer dropdown-nested" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                                        <li role="menuitem" [ngStyle]="{ 'pointer-events': (isCustomerSelected(customer) ? 'none': 'unset')}" *ngFor="let customer of customers">
                                            <a class="dropdown-item" [ngClass]="{ 'customer-text-color': isCustomerSelected(customer) }" (click)="changeCustomer(customer)">
                                                {{customer.customerName}}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li role="menuitem" *ngIf="checkPermission(permission.SuperAdminView)">
                                    <a class="dropdown-item" (click)="navigateToSuperAdmin()" target="_blank">
                                        Super Admin
                                    </a>
                                </li>
                                <li role="menuitem" (click)="logout()">
                                    <a class="dropdown-item">
                                        <i class="fa fa-sign-out"></i> Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <sf-loading class="setting-loader" [loadingFlag]="loadingSetting"></sf-loading>
    <tabset [justified]="true">
        <tab heading="Settings">
            <form #myForm="ngForm">
                <div class="modal-body text-left box-2 p-3">
                    <div class="row p-1">
                        <div class="col-md-12 setting-dropdown header" dropdown>
                            <sf-dropdown [data]="settingBots" [label]="selectedSettingLabel" (click)="changeSettingBot($event)"></sf-dropdown>
                        </div>
                    </div>
                    <div class="container-fluid pt-2">
                        <div class="row d-flex justify-content-start">
                            <ng-container *ngIf="hasProcessLevel">
                                <div class="col-8">
                                    <span class="font-weight-bold setting-tag mt-1">Number of inquiries processed per day:</span>
                                </div>
                                <div class="col-4 row">
                                    <input type="number" name="numberOfInquiries" min="0" class="ml-4 form-control w-75" [(ngModel)]="settingFilters.processedInquiries" autocomplete="off">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="settingCachevalues && settingCachevalues.masterProcessId">
                                <div class="col-8 mt-2">
                                    <span class="font-weight-bold setting-tag mt-1">
                                        <span class="info-icon pr-1" title="Target success rate is used as KPI in the inquiry status overview chart">
                                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </span>
                                        Target Success Rate:
                                    </span>
                                </div>
                                <div class="col-4 mt-2">
                                    <input type="number" min="0" max="100" name="kpi" class="ml-2 form-control w-75 position-relative" [(ngModel)]="settingFilters.kpi" oninput="validity.valid || (value='');" autocomplete="off">
                                    <span class="kpi-percentage"> % </span>
                                    <span *ngIf="settingFilters.kpi === null" class="text-danger ml-2"><small>Field is required</small></span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="filter-data">
                            <div class="col-12 pl-0">
                                <h6 class="font-weight-bold pt-3 pb-3">Filter by:</h6>
                                <div class="filter-list">
                                    <div class="custom-control custom-checkbox ml-5 p-1" *ngFor="let data of settingFilters.filter; let i = index">
                                        <mat-checkbox
                                            id="checkDisplayName+{{i}}"
                                            class="setting-checkbox"
                                            [(ngModel)]="data.isActive"
                                            name="displayName+{{i}}"
                                            color="primary"
                                            (change)="onOptionChange($event, data)"
                                        >
                                            {{setDefaultFilterName(data)}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="filterSelectValidationMsg">
                                    <p class="mb-0">{{filterSelectValidationMsg}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="default-filter">
                            <h6 class="font-weight-bold pt-3 pb-3">Select Default Filter:</h6>
                            <div class="btn-group border w-50 dropdown-section" dropdown>
                                <button id="filter" dropdownToggle type="button" class="col-sm btn btn-md dropdown-toggle text-left custom-input" aria-controls="filter" [title]="selectedDefaultFilter">
                                    {{selectedDefaultFilter}} <i class="mt-2 float-right dropdown-arrow down"></i>
                                </button>
                                <ul id="filter" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="filter">
                                    <li role="menuitem" *ngFor="let data of settingFilters.filter; let i = index">
                                        <a class="dropdown-item" href="javascript:void(0)" (click)="selectDefaultFilter(data, i)">
                                            {{setDefaultFilterName(data)}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <span class="ml-2 reset-icon" (click)="resetSelectedDefaultFilter()" *ngIf="getDefaultFilter()" title="Reset Default Filter"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
                            <div class="text-danger mt-2" *ngIf="!hasDefaultFilter">
                                <p>Please select default filter</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer pb-3 px-0 border-0">
                    <button class="btn btn-md font-weight-bold cancel-btn" (click)="decline()">Cancel</button>
                    <button class="btn btn-md save-btn" (click)="updateSettingFilter(settingFilters)" [disabled]="!myForm.valid || checkManualTimeSpan() || filterSelectValidationMsg || !hasDefaultFilter">Save</button>
                </div>
            </form>
        </tab>
        <tab heading="Email Alert">
            <ng-container *ngIf="notifyMsg.type">
                <alert [type]="notifyMsg.type" dismissOnTimeout="3000" (onClosed)="onNotifyMsgClosed()">{{notifyMsg.msg}}</alert>
            </ng-container>
            <form *ngIf="form" [formGroup]="form">
                <div class="modal-body text-left box-2 p-3">
                    <ng-container *ngIf="emailAlert.controls.length; else noAlert">
                        <div class="row alert-section" *ngFor="let value of emailAlert.controls; let i = index">
                            <div class="col-12 d-flex justify-content-between align-items-center alert-heading">
                                <h6 class="m-0 font-weight-bold">Alert {{i + 1}}</h6>
                                <span class="delete-icon" (click)="onDeleteConfirmation(value, i)"><i class="fa fa-trash-o fa-1x" aria-hidden="true"></i></span>
                            </div>
                            <div class="col-12 mt-2 px-0 d-flex justify-content-between align-items-center">
                                <div class="btn-group bg-white text-dark w-25" dropdown>
                                    <button id="alertLevel{{i}}" dropdownToggle type="button" class="col-sm btn btn-md text-left dropdown-toggle alert-dropdown-btn" aria-controls="alertLevel">
                                        {{value.get('emailAlertLevel').value || 'Select Alert Level'}} <i class="dropdown-arrow down email-alert"></i>
                                    </button>
                                    <ul id="alertLevel{{i}}" *dropdownMenu class="dropdown-menu dropdown-option" role="menu" aria-labelledby="alertLevel">
                                        <li role="menuitem" *ngFor="let alertLevel of emailAlertLevel"><a class="dropdown-item" href="javascript:void(0)" (click)="setEmailAlertLevel(alertLevel.label, i)">{{alertLevel.label}}</a></li>
                                    </ul>
                                </div>
                                <div class="email-setting-dropdown btn-group bg-white text-dark ml-3" dropdown>
                                    <sf-dropdown [data]="alertBots[i]" [label]="selectedEmailAlertLabel[i]" (click)="changeEmailAlertBot($event, i)"></sf-dropdown>
                                </div>
                                <div class="btn-group bg-white text-dark ml-3 w-25" dropdown>
                                    <button id="alertSchedule{{i}}" dropdownToggle type="button" class="col-sm btn btn-md text-left dropdown-toggle alert-dropdown-btn" aria-controls="alertSchedule">
                                        {{getAlertSchedule(i)}} <i class="dropdown-arrow down email-alert"></i>
                                    </button>
                                    <ul id="alertSchedule{{i}}" *dropdownMenu class="dropdown-menu dropdown-option" role="menu" aria-labelledby="alertSchedule">
                                        <li role="menuitem" *ngFor="let schedule of emailAlertScheduleValue"><a class="dropdown-item" href="javascript:void(0)" (click)="setAlertScheduleValue(schedule.value, i)">{{schedule.label}}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 mt-3 px-0 d-flex justify-content-between align-items-center">
                                <div class="btn-group bg-white text-dark w-25" dropdown>
                                    <button id="alertStatus{{i}}" dropdownToggle type="button" class="col-sm btn btn-md text-left dropdown-toggle alert-dropdown-btn" aria-controls="alertStatus">
                                        {{getAlertStatus(i)}} <i class="dropdown-arrow down email-alert"></i>
                                    </button>
                                    <ul id="alertStatus{{i}}" *dropdownMenu class="dropdown-menu dropdown-option" role="menu" aria-labelledby="alertStatus">
                                        <li role="menuitem" *ngFor="let status of emailAlertStatus"><a class="dropdown-item" href="javascript:void(0)" (click)="setAlertStatus(status.value, i)">{{status.label}}</a></li>
                                    </ul>
                                </div>
                                <div class="btn-group bg-white text-dark ml-3 w-25" dropdown>
                                    <button id="alertCondition{{i}}" dropdownToggle type="button" class="col-sm btn btn-md text-left dropdown-toggle alert-dropdown-btn" aria-controls="alertCondition">
                                        <span>{{getAlertCondition(i)}}</span> <i class="dropdown-arrow down email-alert"></i>
                                    </button>
                                    <ul id="alertCondition{{i}}" *dropdownMenu class="dropdown-menu dropdown-option" role="menu" aria-labelledby="alertCondition">
                                        <li role="menuitem" *ngFor="let condition of emailAlertCondition"><a class="dropdown-item" href="javascript:void(0)" (click)="setAlertConditionValue(condition.value, i)">{{condition.label}}</a></li>
                                    </ul>
                                </div>
                                <div class="percentage-input ml-3 w-10">
                                    <input type="number" min="0" class="form-control" [formControl]="value.controls['value']" placeholder="Enter Value">
                                </div>
                                <div class="value-type-btn">
                                    <button
                                        class="btn btn-outline-primary w-auto ml-3"
                                        *ngFor="let valueType of emailAlertValueType"
                                        [ngClass]="{'active': getValueType(i) === valueType.value}"
                                        (click)="setValueType(valueType.value, i)"
                                    >
                                        {{valueType.label}}
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 mt-3 px-0">
                                <div class="email-address-input">
                                    <mat-form-field class="email-token w-100" appearance="fill">
                                        <mat-chip-list #emailAddressList aria-label="Email address" [formControl]="value.controls['emailAddress']">
                                            <mat-chip
                                                *ngFor="let address of value.get('emailAddress').value"
                                                [selectable]="true" [removable]="removable"
                                                (removed)="removeEmailAddress(address, i)"
                                            >
                                                {{address}}
                                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                            </mat-chip>
                                            <input placeholder="Add Email"
                                                [matChipInputFor]="emailAddressList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="true"
                                                (matChipInputTokenEnd)="addEmailAddress($event, i)"
                                            >
                                        </mat-chip-list>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="invalid-feedback">
                                <div *ngIf="checkValidation(i)">All fields are mandatory</div>
                                <div *ngIf="value.get('value').errors?.min">Value must be positive</div>
                                <div *ngIf="value.get('value').errors?.max">Value must be between 0 to 100</div>
                                <div *ngIf="value.get('emailAddress').errors?.pattern">Invalid email address</div>
                                <div *ngIf="value.get('value').errors?.pattern && !value.get('value').errors?.min">Value must be a whole number</div>
                                <div>{{alertSelectionErrorMsg[i]}}</div>
                                <div>{{isDuplicate(value, i)}}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noAlert>
                        <h6 class="heading text-danger h-75 d-flex align-items-center justify-content-center">No Alert to show</h6>
                    </ng-template>
                </div>
                <div class="invalid-feedback d-block mt-0 ml-1">
                    <div>{{alertDuplicateErrorMsg}}</div>
                </div>
                <div class="modal-footer pb-3 px-0 border-0 justify-content-between align-items-baseline">
                    <h6 class="text-add ml-2 font-weight-bold" (click)="createAlert()" [class.disabled]="isDisabled() || isAlertDuplicate">{{(emailAlert.controls.length) ? 'Add More' : 'Add'}}</h6>
                    <div class="action-btn">
                        <button class="btn btn-md font-weight-bold cancel-btn" (click)="decline()">Cancel</button>
                        <button class="btn btn-md save-btn" [disabled]="isDisabled() || isAlertDuplicate || !emailAlert.controls.length" (click)="saveEmailAlert()">Save</button>
                    </div>
                </div>
            </form>
        </tab>
    </tabset>
</ng-template>