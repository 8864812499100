import { forEach, set } from 'lodash';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class FormHelper<M extends Object> {
  constructor(protected builder: FormBuilder) {}

  abstract createEmpty(): FormGroup;
  abstract fromModel(model: M): FormGroup;

  toModel(form: FormGroup) {
    return this.map(form, {});
  }

  map(form: FormGroup, model: M | {}): M {
    forEach(form.controls, (control: FormControl, key: string) => {
      set(model, key, control.value);
    });
    return <M>model;
  }
}
