import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ICustomers, IBot } from '@modules/Dashboard/dashboard.model';
import { SuperAdminService } from '@modules/Super-admin/Services/SuperAdminService';
import { IModalData } from '@modules/Super-admin/super-admin.model';
import { find, cloneDeep, isEqual } from 'lodash';
import { ConfirmModalComponent } from 'src/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'sf-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {
  public displayName: string;
  public clientPropertyName: string;
  public bots: IBot[];
  public data: IModalData;
  public showValidationMessage = false;
  public isDuplicate = false;
  public loadingFlag: boolean;
  public displayNameSnapshot: string;
  public modalConfig = {
    class: 'modal-dialog-centered modal-sm',
    keyboard: false,
    ignoreBackdropClick: true,
  };
  public confirmModalRef: BsModalRef;
  constructor( public modalRef: BsModalRef, private modalService: BsModalService, private service: SuperAdminService) { }

  ngOnInit() {
    this.displayNameSnapshot = cloneDeep(this.displayName);
  }

  onConfirmation() {
    this.confirmModalRef = this.modalService.show(ConfirmModalComponent, { ...this.modalConfig, id: 2 });
    this.confirmModalRef.content.data = { title: 'Are you sure you want to save this?' };
    this.confirmModalRef.content.onConfirm.subscribe((result: boolean) => {
      if (result) {
        this.confirmModalRef.hide();
        this.addEditFilter();
      }
    });
  }

  async addEditFilter() {
    try {
      this.loadingFlag = true;
      if (this.displayName && this.clientPropertyName) {
        if (!isEqual(this.displayName, this.displayNameSnapshot)) {
          if (!find((!this.data.isModify) ? this.data.botDetail.filters : this.data.filters,
            {displayName: this.displayName.toLowerCase()})) {
            (!this.data.isModify) ? await this.service.createFilter(this.data, this.displayName, this.clientPropertyName) :
            await this.service.updateFilter(this.data, this.displayName);
            this.getAttachedFilterWithBot(this.data.customerDetail);
            this.hide();
          } else {
            this.isDuplicate = true;
            this.showValidationMessage = false;
          }
        } else {
          this.hide();
        }
      } else {
        this.showValidationMessage = true;
        this.isDuplicate = false;
      }
    } catch (err) {

    } finally {
      this.loadingFlag = false;
  }
}

  async getAttachedFilterWithBot(customer: ICustomers) {
    this.bots = await this.service.getAttachedFilterWithBot(customer);
    this.service.botChange.emit(this.bots);
  }

  hide() {
    this.modalRef.hide();
  }
}
