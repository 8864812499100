<sf-loading [loadingFlag]="loadingFlag"></sf-loading>
<div class="modal-header">
    <h6 class="modal-title">Are you sure you want to remove this database?</h6>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col text-right m-2">
            <button type="button" class="btn save-btn mr-2" (click)="deleteCustomer()">Yes</button>
            <button type="button " class="btn disable-btn" (click)="hide()">No</button>
        </div>
    </div>
</div>